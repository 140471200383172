import { ApolloError } from "@apollo/client"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { logInPath } from "~/common/paths"

export const GraphqlError = ({ error }: { error?: ApolloError }) => {
  const navigate = useNavigate()

  // Redirect to login when user is not authorized. Consider also checking whether the user is logged in
  useEffect(() => {
    if (error?.message === "Not Authorized") {
      navigate(logInPath.pattern, { replace: true })
    }
  }, [error, navigate])

  if (error == null) return null
  if (
    error.graphQLErrors &&
    error.graphQLErrors.length > 0 &&
    error.graphQLErrors.every(
      (err) => err.extensions?.code === "VALIDATION_ERROR"
    )
  ) {
    return null
  }

  return (
    <div className="max-w-full overflow-auto">
      <div className=" flex-shrink-0">
        <div className="border-red-500 border rounded p-3 text-red-600 overflow-auto flex-shrink-0">
          <div>GraphQL Error</div>
          {error.graphQLErrors.map((e, i) => (
            <pre key={i}>{JSON.stringify(e, null, 2)}</pre>
          ))}
          {error.graphQLErrors.length === 0 ? (
            <pre>{JSON.stringify(error, null, 2)}</pre>
          ) : null}
        </div>
      </div>
    </div>
  )
}
