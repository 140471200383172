import { NewsletterSizeBand } from "~/__generated__/graphql"
import { FormikMultiSelectField } from "~/components/fields/formik-fields"
import { formatNewsletterSizeBand } from "../ui/newsletters"

export const NewsletterSizeBandField = ({
  name,
  label,
  tooltip,
}: {
  name: string
  label: string
  tooltip?: string
}) => {
  return (
    <FormikMultiSelectField
      options={[
        NewsletterSizeBand.Band_0_10k,
        NewsletterSizeBand.Band_10k_50k,
        NewsletterSizeBand.Band_50k_250k,
        NewsletterSizeBand.Band_250k_1mm,
        NewsletterSizeBand.Band_1mmPlus,
      ].map((band) => ({
        value: band,
        label: formatNewsletterSizeBand(band),
      }))}
      label={label}
      name={name}
      tooltip={tooltip}
    />
  )
}
