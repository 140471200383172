import { useMutation, useQuery } from "@apollo/client"
import { useId } from "@reach/auto-id"
import { useField, useFormikContext } from "formik"
import { ChevronsUpDown, PlusIcon } from "lucide-react"
import * as React from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { GraphqlError } from "~/components/errors/graph-error"
import { Button } from "~/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover"
import { NewsletterForm, blankNewsletter } from "../forms/newsletter-form"
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog"
import { LabelWithClear } from "../ui/label-with-clear"
import { MultiSelect, OptionType } from "../ui/multi-select"
import { NewsletterSetNewForm } from "../forms/newsletter-set-form"
import { ValidationError } from "./formik-fields"

export type NewsletterInputOption = {
  name: string
  id: string
}

type NewsletterInputProps = {
  value: NewsletterInputOption | null
  onValueChange: (value: NewsletterInputOption | null) => void
}

const newsletterInputQuery = gql(/* GraphQL */ `
  query NewsletterInputQuery($query: String!) {
    newsletters(first: 10, filters: { search: $query }) {
      nodes {
        id
        name
      }
    }
  }
`)

const createNewsletterMutation = gql(/* GraphQL */ `
  mutation CreateNewsletterMutation($input: NewsletterCreateInput!) {
    newsletterCreate(input: $input) {
      newsletter {
        id
        name
      }
    }
  }
`)

export const NewsletterInput = ({
  value,
  onValueChange,
}: NewsletterInputProps) => {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")

  const result = useQuery(newsletterInputQuery, {
    variables: { query: inputValue },
  })

  const [dialogState, setDialogState] = React.useState<
    | {
        state: "open"
        name: string
      }
    | { state: "closed" }
  >({ state: "closed" })

  const closeDialog = () => setDialogState({ state: "closed" })

  const [createNewsletter, createNewsletterResult] = useMutation(
    createNewsletterMutation,
    {}
  )

  const options =
    result.data?.newsletters.nodes ??
    result.previousData?.newsletters.nodes ??
    []

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[400px] max-w-full justify-between"
          >
            {value ? value.name : "Select newsletter…"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] max-w-full p-0">
          <Command shouldFilter={false}>
            <CommandInput
              placeholder="Search newsletters…"
              value={inputValue}
              onValueChange={(value) => setInputValue(value)}
            />
            <CommandEmpty>No newsletter found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.id}
                  value={option.id}
                  onSelect={(currentValue) => {
                    let match = options.find(
                      (o) => o.id.toLowerCase().trim() === currentValue
                    )
                    invariant(match, "match should exist")

                    onValueChange(
                      value != null && currentValue === value.id ? null : match
                    )
                    setOpen(false)
                  }}
                >
                  <div className="flex gap-2">
                    <div className="flex flex-col">{option.name}</div>
                  </div>
                </CommandItem>
              ))}

              {inputValue.trim().length > 0 &&
              options.find(
                (o) => o.name.toLowerCase() === inputValue.trim().toLowerCase()
              ) == null ? (
                <CommandItem
                  onSelect={async () => {
                    setDialogState({
                      state: "open",
                      name: inputValue,
                    })
                    setInputValue("")
                    setOpen(false)
                  }}
                  disabled={createNewsletterResult.loading}
                >
                  {createNewsletterResult.loading ? (
                    <>Creating “{inputValue}”</>
                  ) : (
                    <>Create new newsletter “{inputValue}”</>
                  )}
                </CommandItem>
              ) : null}
            </CommandGroup>
          </Command>
        </PopoverContent>

        <Dialog open={dialogState.state === "open"} onOpenChange={closeDialog}>
          <DialogContent className="max-w-5xl">
            <div className="flex justify-between">
              <h2 className="text-xl">Create newsletter</h2>
            </div>
            <NewsletterForm
              isLoading={createNewsletterResult.loading}
              newsletter={{
                ...blankNewsletter(),
                name: dialogState.state === "open" ? dialogState.name : "",
              }}
              errors={createNewsletterResult.error?.graphQLErrors}
              onSubmit={async (values) => {
                let newsletter = await createNewsletter({
                  variables: {
                    input: { newsletterInput: values },
                  },
                })

                if (newsletter.errors) {
                  return
                }

                invariant(newsletter.data, "sponsor.data should exist")
                onValueChange(newsletter.data.newsletterCreate.newsletter)
                closeDialog()
              }}
            />
          </DialogContent>
        </Dialog>
        {result.error ? <GraphqlError error={result.error} /> : null}
      </Popover>
      {result.error ? <GraphqlError error={result.error} /> : null}
    </>
  )
}

type NewsletterMultiInputProps = {
  value: Array<NewsletterInputOption>
  onValueChange: (value: Array<NewsletterInputOption>) => void
  inputValue?: string
  onInputChange?: (value: string) => void
  options: Array<OptionType>
  shouldFilter?: boolean
}

/** @deprecated use multi select directly? */
export const NewsletterMultiInput = ({
  options,
  inputValue,
  onInputChange,
  onValueChange,
  shouldFilter,
  ...props
}: NewsletterMultiInputProps) => {
  return (
    <MultiSelect
      options={options}
      selected={props.value.map((v) => v.id)}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onValuesChange={(ids) => {
        onValueChange(
          ids.map((id) => {
            let record = options.find((o) => o.value === id)
            invariant(record, "record should exist")
            return {
              id: id,
              name: record.label,
            }
          })
        )
      }}
      shouldFilter={shouldFilter}
    />
  )
}

const newsletterFieldQuery = gql(/* GraphQL */ `
  query NewsletterField($filters: NewsletterSearchInput) {
    newsletters(first: 10000, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export const NewsletterField = ({
  label,
  sponsorId,
  placeholder,
  disabled,
  tooltip,
  ...props
}: {
  name: string
  label?: string
  id?: string
  sponsorId?: string
  placeholder?: string
  disabled?: boolean
  tooltip?: string
}) => {
  const [field] = useField(props)
  const formik = useFormikContext()

  const filterResult = useQuery(newsletterFieldQuery, {
    variables: {
      filters: {
        sponsorIds: sponsorId ? [sponsorId] : null,
        verified: true,
        currentlyTracking: true,
      },
    },
  })

  const filterData = filterResult.data ?? filterResult.previousData

  const newsletterOptions = React.useMemo(() => {
    return (
      filterData?.newsletters.edges
        .map((edge) => edge.node)
        .map((newsletter) => ({
          label: newsletter.name,
          value: newsletter.id,
        })) ?? []
    )
  }, [filterData?.newsletters])

  const autoId = useId()
  const id = props.id ?? autoId

  return (
    <div>
      <LabelWithClear
        id={id}
        label={label}
        clearable={field.value.length > 0}
        onClear={() => formik.setFieldValue(field.name, [])}
        tooltip={tooltip}
      />
      <div>
        <MultiSelect
          id={id}
          name={field.name}
          options={newsletterOptions}
          selected={field.value}
          onValuesChange={(ids) => {
            formik.setFieldValue(field.name, ids)
          }}
          shouldFilter={true}
          placeholder={placeholder}
          disabled={disabled}
          className="max-w-[252px] w-full"
        />
      </div>
    </div>
  )
}

const newsletterFieldWithSetQuery = gql(/* GraphQL */ `
  query NewsletterFieldWithSet($filters: NewsletterSearchInput) {
    newsletters(first: 10000, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
    newsletterSets(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export type NewsletterOrSetOptions = {
  newsletterIds: string[]
  newsletterSetIds: string[]
}

export const NewsletterFieldWithSets = ({
  label,
  sponsorId,
  placeholder,
  disabled,
  tooltip,
  ...props
}: {
  name: string
  label?: string
  id?: string
  sponsorId?: string
  placeholder?: string
  disabled?: boolean
  canCreateNewsletterSets: boolean
  tooltip: string
}) => {
  const [field, meta] = useField<NewsletterOrSetOptions>(props)
  const formik = useFormikContext()

  const filterResult = useQuery(newsletterFieldWithSetQuery, {
    variables: {
      filters: {
        sponsorIds: sponsorId ? [sponsorId] : null,
        verified: true,
        currentlyTracking: true,
      },
    },
  })

  const filterData = filterResult.data ?? filterResult.previousData

  const newsletterOptions = React.useMemo(() => {
    return (
      filterData?.newsletters.edges
        .map((edge) => edge.node)
        .map((newsletter) => ({
          __typename: newsletter.__typename!,
          label: newsletter.name,
          value: newsletter.id,
        })) ?? []
    )
  }, [filterData?.newsletters])

  const newsletterSetOptions = React.useMemo(() => {
    return (
      filterData?.newsletterSets.edges
        .map((edge) => edge.node)
        .map((newsletterSet) => ({
          __typename: newsletterSet.__typename!,
          label: newsletterSet.name,
          value: newsletterSet.id,
        })) ?? []
    )
  }, [filterData?.newsletterSets])

  const combinedOptions = React.useMemo(() => {
    return [...newsletterSetOptions, ...newsletterOptions]
  }, [newsletterOptions, newsletterSetOptions])

  const autoId = useId()
  const id = props.id ?? autoId
  const [isNewsletterSetOpen, setIsNewsletterSetOpen] = React.useState(false)

  const selectedFlat = React.useMemo(() => {
    return [...field.value.newsletterIds, ...field.value.newsletterSetIds]
  }, [field.value.newsletterIds, field.value.newsletterSetIds])

  return (
    <Dialog open={isNewsletterSetOpen} onOpenChange={setIsNewsletterSetOpen}>
      <div>
        <div className="flex gap-x-1 w-full flex-wrap">
          <LabelWithClear
            id={id}
            label={label}
            clearable={selectedFlat.length > 0}
            onClear={() => {
              let value: NewsletterOrSetOptions = {
                newsletterIds: [],
                newsletterSetIds: [],
              }
              formik.setFieldValue(field.name, value)
            }}
            tooltip={tooltip}
            className="pb-0"
          />
          {props.canCreateNewsletterSets ? (
            <DialogTrigger asChild>
              <button
                className="ms-auto flex gap-1 items-center text-sm py-1"
                type="button"
              >
                <PlusIcon size={12} />
                Create newsletter set
              </button>
            </DialogTrigger>
          ) : null}
        </div>
        <div>
          <MultiSelect
            id={id}
            name={field.name}
            options={combinedOptions}
            selected={selectedFlat}
            onValuesChange={(ids) => {
              const newsletterIds = newsletterOptions
                .filter((o) => ids.includes(o.value))
                .map((o) => o.value)

              const newsletterSetIds = newsletterSetOptions
                .filter((o) => ids.includes(o.value))
                .map((o) => o.value)

              let value: NewsletterOrSetOptions = {
                newsletterIds,
                newsletterSetIds,
              }

              formik.setFieldValue(field.name, value)
            }}
            shouldFilter={true}
            placeholder={placeholder}
            disabled={disabled}
            className="max-w-[252px] w-full"
            dividers={
              newsletterSetOptions.length > 0
                ? [newsletterSetOptions.length]
                : undefined
            }
          />

          {meta.touched && meta.error ? (
            <ValidationError>{meta.error}</ValidationError>
          ) : null}
        </div>
      </div>
      <DialogContent>
        <div className="flex justify-between">
          <h2 className="text-xl">Create Newsletter Set</h2>
        </div>
        <NewsletterSetNewForm
          newsletterIds={field.value.newsletterIds}
          onCreated={(ns) => {
            let value: NewsletterOrSetOptions = {
              newsletterIds: [],
              newsletterSetIds: [ns.id],
            }
            formik.setFieldValue(field.name, value)
            filterResult.refetch()
            setIsNewsletterSetOpen(false)
          }}
          onDismiss={() => {
            setIsNewsletterSetOpen(false)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
