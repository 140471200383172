import { format, parseISO } from "date-fns"

export function formatDate(input: string | Date) {
  let date = typeof input === "string" ? parseISO(input) : input
  return format(date, "M/d/yyyy")
}

// includes offset
export function formatDateTime(input: string) {
  return format(parseISO(input), "M/d/yyyy h:mm a")
}
