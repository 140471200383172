import * as React from "react"
import { cn } from "~/lib/utils"

import { Check, X, ChevronsUpDown, Pin } from "lucide-react"
import { Button } from "~/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover"
import { Badge } from "~/components/ui/badge"

export type OptionType = {
  label: string
  value: string
}

export interface MultiSelectWithPinProps {
  options: OptionType[]
  selected: string[]
  onValuesChange: (ids: Array<string>) => void
  pinValue: string
  onSetPinValue: (value: string) => void
  className?: string
  inputValue?: string
  onInputChange?: (value: string) => void
  shouldFilter?: boolean
  id?: string
  name?: string
  onFetchMore?: () => void
}

function MultiSelectWithPin({
  options,
  selected,
  onValuesChange,
  pinValue,
  onSetPinValue,
  className,
  shouldFilter,
  ...props
}: MultiSelectWithPinProps) {
  const [open, setOpen] = React.useState(false)

  const handleRemove = (item: string) => {
    const remaining = selected.filter((i) => i !== item)
    if (item === pinValue) {
      onSetPinValue(remaining[0] || "")
    }
    onValuesChange(remaining)
  }

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-full justify-between h-full text-foreground`}
          onClick={() => setOpen(!open)}
        >
          <div className="flex gap-2 flex-wrap">
            {selected.length === 0 && (
              <Badge className="mr-1 mb-1 opacity-0">&nbsp;</Badge>
            )}
            {selected.map((id) => (
              <PinnableBadge
                key={id}
                value={id}
                pinValue={pinValue}
                handleRemove={() => handleRemove(id)}
                handlePin={() => onSetPinValue(id)}
                label={options.find((o) => o.value === id)?.label}
              />
            ))}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command className={className} shouldFilter={shouldFilter}>
          <CommandInput
            placeholder="Search ..."
            value={props.inputValue}
            onValueChange={props.onInputChange}
          />
          <CommandEmpty>No item found.</CommandEmpty>
          <CommandGroup className="max-h-64 overflow-auto">
            {options.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={() => {
                  if (!pinValue) {
                    onSetPinValue(option.value)
                  }
                  onValuesChange(
                    selected.includes(option.value)
                      ? selected.filter((item) => item !== option.value)
                      : [...selected, option.value]
                  )
                  setOpen(true)
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selected.includes(option.value)
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}

const PinnableBadge = ({
  value,
  pinValue,
  handlePin,
  handleRemove,
  label,
}: {
  value: string
  pinValue: string
  handlePin: () => void
  handleRemove: () => void
  label?: string
}) => {
  const isPinned = value === pinValue

  return (
    <Badge
      variant={isPinned ? "default" : "accent"}
      key={value}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        handlePin()
      }}
    >
      {isPinned ? (
        <Pin className="h-3 w-3 mr-1" fill="white" strokeWidth={1} />
      ) : (
        <div className="h-3 w-3 mr-1" />
      )}
      {label}
      <div
        className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleRemove()
          }
        }}
        onMouseDown={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          handleRemove()
        }}
      >
        <X
          className={cn(
            "h-3 w-3 hover:text-foreground",
            isPinned ? "text-primary-foreground" : "text-muted-foreground"
          )}
        />
      </div>
    </Badge>
  )
}

export { MultiSelectWithPin }
