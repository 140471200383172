import { salesProUrl } from "~/common/paths"
import { LinkButton } from "~/components/ui/link"

export const GetWholePictureBanner = () => {
  return (
    <div className="bg-pink text-pink-foreground mt-4 md:mt-10">
      <div className="flex flex-col gap-6 p-10 max-w-page mx-auto text-center">
        <h2 className="text-3xl font-semibold">Get the whole picture...</h2>
        <p>
          Free users see just 10% of the sponsors available in our database.
          <br />
          See about upgrading to Sales Pro today!
        </p>
        <div>
          <LinkButton variant="default" to={salesProUrl}>
            Learn More
          </LinkButton>
        </div>
      </div>
    </div>
  )
}
