import { useMutation, useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { adminSponsorIndexPath, sponsorDetailPath } from "~/common/paths"
import { GraphqlError } from "~/components/errors/graph-error"
import {
  DeleteSponsorButton,
  SponsorForm,
  sponsorFormFragment,
} from "~/components/forms/sponsor-form"
import { BounceReportsTable } from "~/components/ui/bounce-reports-table"
import { Button } from "~/components/ui/button"
import { FormPage, FormPageTitle } from "~/components/ui/form-page"
import { LinkButton } from "~/components/ui/link"
import { LoadingIndicatorCentered } from "~/components/ui/loading-indicator"

export const adminSponsorEditQuery = gql(/* GraphQL */ `
  query AdminSponsorEditScreenQuery(
    $slug: String!
    $bounceReportsAfter: String
  ) {
    sponsor(slug: $slug) {
      id
      slug
      ...SponsorFormFields
      bounceReports(after: $bounceReportsAfter) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            ...BounceReportTable
          }
        }
      }
    }
  }
`)

export const sponsorEditMutation = gql(/* GraphQL */ `
  mutation UpdateSponsorMutation($input: SponsorUpdateInput!) {
    sponsorUpdate(input: $input) {
      sponsor {
        id
        ...SponsorFormFields
      }
    }
  }
`)

export const AdminSponsorEditScreen = () => {
  const params = useParams()
  invariant(params.slug)

  const result = useQuery(adminSponsorEditQuery, {
    variables: { slug: params.slug, bounceReportsAfter: null },
  })

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [mutate, mutationResult] = useMutation(sponsorEditMutation, {})

  if (result.error) return <GraphqlError error={result.error} />
  if (result.loading) return <LoadingIndicatorCentered />

  invariant(result.data)

  const sponsor = result.data.sponsor
  const sponsorFragData = getFragmentData(
    sponsorFormFragment,
    result.data.sponsor
  )

  return (
    <FormPage>
      <FormPageTitle>
        Edit sponsor
        <div className="ms-auto flex gap-2">
          <LinkButton
            size="sm"
            to={sponsorDetailPath({ sponsorSlug: sponsor.slug })}
            target="_blank"
          >
            View
          </LinkButton>
          <DeleteSponsorButton
            id={sponsor.id}
            onComplete={() => navigate(adminSponsorIndexPath({}))}
          />
        </div>
      </FormPageTitle>

      <SponsorForm
        sponsor={sponsorFragData}
        isLoading={mutationResult.loading}
        errors={mutationResult.error?.graphQLErrors}
        onSubmit={async (values, options) => {
          let result = await mutate({
            variables: {
              input: {
                id: sponsor.id,
                sponsorInput: values,
              },
            },
          })

          if (options?.preventRedirect) return
          if (result.errors) return

          toast.success("Sponsor updated")
          const returnTo = searchParams.get("returnTo")
          if (returnTo) {
            navigate(returnTo)
          } else {
            navigate(adminSponsorIndexPath({}))
          }
        }}
      />

      <FormPageTitle>Bounce Reports</FormPageTitle>
      <BounceReportsTable
        bounceReports={sponsor.bounceReports?.edges.map((e) => e.node) ?? []}
        showResolvedFields={true}
        showSponsorField={false}
      />

      {sponsor.bounceReports?.pageInfo.hasNextPage && (
        <div className="p-3">
          <Button
            onClick={() => {
              invariant(sponsor.bounceReports)
              invariant(sponsor.bounceReports.pageInfo.endCursor)
              result.fetchMore({
                variables: {
                  bounceReportsAfter: sponsor.bounceReports.pageInfo.endCursor,
                },
              })
            }}
          >
            See more
          </Button>
        </div>
      )}
      <div className="p-5" />
    </FormPage>
  )
}
