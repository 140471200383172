import { useMutation, useQuery } from "@apollo/client"
import { GraphQLErrors } from "@apollo/client/errors"
import { Form, FormikProvider, useFormik } from "formik"
import { useEffect } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import { getFragmentData, gql } from "~/__generated__"
import {
  NewsletterFormFieldsFragment,
  NewsletterInput,
} from "~/__generated__/graphql"
import { displayErrors } from "~/common/validation-errors"
import {
  adminNewsletterEditMutation,
  adminNewsletterEditQuery,
} from "~/screens/admin-newsletter-edit-screen"
import { GraphqlError } from "../errors/graph-error"
import {
  FormikField,
  FormikMultiSelectWithPinField,
} from "../fields/formik-fields"
import { AdminSponsorshipTable } from "../sponsorships/admin-sponsorship-table"
import { Button } from "../ui/button"
import { ConfirmDelete } from "../ui/confirm-delete"
import { FormPageTitle } from "../ui/form-page"
import { LoadingIndicatorCentered } from "../ui/loading-indicator"

type NewsletterFields = {
  name: string
  signUpUrl: string
  notes: string
  viewInBrowserAvailable: boolean
  availableForFreeUsers: boolean
  description: string
  estimatedCpm: string
  audienceSize: string
  contact: string
  verified: boolean
  currentlyTracking: boolean
  displayTopicId: string | null
  topics: Array<string>
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
})

const query = gql(/* GraphQL */ `
  query NewsletterFormQuery {
    topics(first: 100) {
      nodes {
        id
        name
      }
    }
  }
`)

export const newsletterFormFragment = gql(/* GraphQL */ `
  fragment NewsletterFormFields on Newsletter {
    id
    name
    signUpUrl
    notes
    viewInBrowserAvailable
    availableForFreeUsers
    description
    estimatedCpm
    audienceSize
    contact
    verified
    currentlyTracking
    displayTopic {
      id
      name
    }
    topics(first: 50) {
      nodes {
        id
        name
      }
    }
  }
`)

const sponsorshipsQuery = gql(/* GraphQL */ `
  query NewsletterFormSponsorships($id: GlobalIdInput!, $after: String) {
    newsletter(id: $id) {
      id
      sponsorships(first: 50, after: $after) {
        ...AdminSponsorshipConnection
        totalCount
      }
    }
  }
`)

type NewsletterFormProps = {
  newsletter: NewsletterFormFieldsFragment
  onSubmit: (newsletterInput: NewsletterInput) => void
  isLoading: boolean
  errors?: GraphQLErrors
}

const deleteMutation = gql(/* GraphQL */ `
  mutation DeleteNewsletterMutation($input: NewsletterDeleteInput!) {
    newsletterDelete(input: $input) {
      newsletter {
        id
      }
    }
  }
`)

export const AdminEditNewsletter = ({
  id,
  onComplete,
}: {
  id: string
  onComplete: () => void
}) => {
  const result = useQuery(adminNewsletterEditQuery, {
    variables: { id },
  })
  const [mutate, mutationResult] = useMutation(adminNewsletterEditMutation, {
    onCompleted: () => {
      toast.success(`Newsletter updated`)
      onComplete()
    },
  })

  if (result.loading) return <LoadingIndicatorCentered />
  if (result.error) return <GraphqlError error={result.error} />

  invariant(result.data)
  invariant(result.data.newsletter)
  invariant(
    result.data.newsletter.__typename === "Newsletter",
    "expected a newsletter"
  )

  const newsletter = getFragmentData(
    newsletterFormFragment,
    result.data.newsletter
  )

  return (
    <NewsletterForm
      errors={mutationResult.error?.graphQLErrors}
      isLoading={mutationResult.loading}
      newsletter={newsletter}
      onSubmit={(newsletterInput) => {
        invariant(result.data)
        mutate({
          variables: {
            input: {
              id,
              newsletterInput: newsletterInput,
            },
          },
        })
      }}
    />
  )
}

export const DeleteNewsletterButton = ({
  id,
  onDeleteComplete,
}: {
  id: string
  onDeleteComplete?: () => void
}) => {
  const [execDelete, deleteMutationResult] = useMutation(deleteMutation, {
    onCompleted: () => {
      toast.success("Newletter deleted")
      if (onDeleteComplete) onDeleteComplete()
    },
  })

  return (
    <ConfirmDelete
      onDelete={() => {
        execDelete({ variables: { input: { id } } })
      }}
      message="Are you sure you want to delete this newsletter?"
      result={deleteMutationResult}
    />
  )
}

export const NewsletterForm = ({
  newsletter,
  ...props
}: NewsletterFormProps) => {
  const initialValues: NewsletterFields = {
    ...newsletter,
    signUpUrl: newsletter.signUpUrl ?? "",
    description: newsletter.description ?? "",
    notes: newsletter.notes ?? "",
    estimatedCpm: newsletter.estimatedCpm?.toString() ?? "",
    audienceSize: newsletter.audienceSize?.toString() ?? "",
    contact: newsletter.contact ?? "",
    topics: newsletter.topics.nodes.map((t) => t.id),
    displayTopicId: newsletter.displayTopic?.id ?? null,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmit({
        name: values.name,
        signUpUrl: values.signUpUrl,
        notes: values.notes,
        viewInBrowserAvailable: values.viewInBrowserAvailable,
        availableForFreeUsers: values.availableForFreeUsers,
        description: values.description,
        estimatedCpm: values.estimatedCpm
          ? parseInt(values.estimatedCpm, 10)
          : null,
        audienceSize: values.audienceSize
          ? parseInt(values.audienceSize, 10)
          : null,
        contact: values.contact,
        verified: values.verified,
        currentlyTracking: values.currentlyTracking,
        displayTopicId: values.displayTopicId,
        topicIds: values.topics,
      })
    },
  })

  const result = useQuery(query)

  const sponsorshipsResult = useQuery(sponsorshipsQuery, {
    ...(newsletter.id != null && {
      variables: {
        id: newsletter.id,
        after: null,
      },
    }),
    skip: newsletter.id == null,
  })

  useEffect(() => {
    if (props.errors) {
      displayErrors(props.errors, formik.setFieldError)
      console.log("displaying errors", props.errors)
    }
  }, [formik.setFieldError, props.errors])

  return (
    <div>
      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-3">
          <FormikField name="name" label="Name" />
          <FormikMultiSelectWithPinField
            name="topics"
            pinName="displayTopicId"
            label="Topics"
            options={
              result.data?.topics.nodes.map((t) => ({
                value: t.id,
                label: t.name,
              })) || []
            }
          />
          <div className="text-gray-400">
            Click a topic to pin it. The pinned topic is the display topic.
          </div>
          <FormikField name="signUpUrl" label="Sign up URL" type="url" />
          <FormikField name="notes" label="Notes" as="textarea" />
          <FormikField name="description" label="Description" as="textarea" />
          <FormikField
            name="estimatedCpm"
            label="Estimated CPM"
            type="number"
            step="1"
            pattern="\d+"
          />
          <FormikField
            name="audienceSize"
            label="Audience Size"
            type="number"
            step="1"
            pattern="\d+"
          />
          <FormikField name="contact" label="Contact" />

          <FormikField
            name="viewInBrowserAvailable"
            label="View in Browser Is Available"
            type="checkbox"
          />
          <FormikField
            name="availableForFreeUsers"
            label="Available to Free Users"
            type="checkbox"
          />
          <FormikField name="verified" label="Verified" type="checkbox" />
          <FormikField
            name="currentlyTracking"
            label="Currently Tracking"
            type="checkbox"
          />

          <Button
            type="submit"
            isLoading={props.isLoading}
            disabled={props.isLoading}
          >
            Save
          </Button>
        </Form>
      </FormikProvider>

      {sponsorshipsResult.data &&
      sponsorshipsResult.data.newsletter.sponsorships ? (
        <div className="mt-6">
          <FormPageTitle>Sponsorships</FormPageTitle>
          <br />
          <AdminSponsorshipTable
            sponsorships={sponsorshipsResult.data.newsletter.sponsorships}
            onFetchMore={(after) => {
              invariant(newsletter.id)
              sponsorshipsResult.fetchMore({
                variables: {
                  id: newsletter.id,
                  after,
                },
              })
            }}
            onRefetch={() => sponsorshipsResult.refetch()}
            showEditLink={false}
          />
        </div>
      ) : null}
      <GraphqlError error={sponsorshipsResult.error} />
    </div>
  )
}

export const blankNewsletter = (): NewsletterFormFieldsFragment => ({
  id: "",
  audienceSize: null,
  contact: "",
  description: "",
  estimatedCpm: null,
  verified: false,
  name: "",
  notes: "",
  signUpUrl: "",
  viewInBrowserAvailable: false,
  availableForFreeUsers: false,
  currentlyTracking: true,
  topics: {
    nodes: [],
  },
})
