import { Outlet, ScrollRestoration } from "react-router-dom"

import { Footer } from "~/components/ui/footer"
import { Header } from "~/components/ui/header"

export const LayoutScreen = () => {
  return (
    <div className="flex flex-col flex-1 break-words">
      <ScrollRestoration />
      <Header />
      <div className="flex-grow min-h-[500px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
