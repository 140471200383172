import { Link } from "react-router-dom"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { sponsorDetailPath } from "~/common/paths"
import { Badge } from "~/components/ui/badge"
import { Card, CardContent, CardTitle } from "~/components/ui/card"

export const SponsorCardFragment = gql(/* GraphQL */ `
  fragment SponsorCard on Sponsor {
    slug
    sponsorName
    logo {
      id
      thumbnailUrl
    }
    displayIndustry {
      id
      name
    }
  }
`)

export const SponsorCard = ({
  ...props
}: {
  sponsor: FragmentType<typeof SponsorCardFragment>
}) => {
  const sponsor = getFragmentData(SponsorCardFragment, props.sponsor)
  return (
    <Link
      to={sponsorDetailPath({ sponsorSlug: sponsor.slug })}
      className="flex flex-1 w-full"
      title={sponsor.sponsorName}
    >
      <Card className="overflow-hidden flex flex-col flex-1 w-full justify-between relative">
        {sponsor.logo ? (
          <img
            src={sponsor.logo.thumbnailUrl}
            className="w-full aspect-square object-cover"
            alt={`${sponsor.sponsorName} logo`}
          />
        ) : (
          <div className="w-full aspect-square bg-gray-200" />
        )}
        <div className="absolute top-1 right-1">
          {sponsor.displayIndustry && (
            <Badge variant="outline" className="bg-white text-xxs">
              {sponsor.displayIndustry.name}
            </Badge>
          )}
        </div>
        <CardContent className="p-3 flex flex-col justify-between flex-grow border-t">
          <CardTitle className="text-md flex-grow line-clamp-3">
            {sponsor.sponsorName}
          </CardTitle>
        </CardContent>
      </Card>
    </Link>
  )
}

export const SponsorCardGrid: React.FC<{ children: React.ReactNode }> = (
  props
) => (
  <div
    className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 w-full"
    {...props}
  />
)
