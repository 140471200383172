import { useMutation, useQuery } from "@apollo/client"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { SponsorshipPlacement } from "~/__generated__/graphql"
import { displayErrors } from "~/common/validation-errors"
import { GraphqlError } from "~/components/errors/graph-error"
import {
  SponsorshipForm,
  SponsorshipFormFields,
  sponsorshipFormFieldsFragment,
  validationSchema,
} from "~/components/forms/sponsorship-form"
import { LoadingIndicatorCentered } from "~/components/ui/loading-indicator"
import { ConfirmDelete } from "../ui/confirm-delete"

const query = gql(/* GraphQL */ `
  query SponsorshipEditQuery($id: ID!) {
    node(id: $id) {
      ... on Sponsorship {
        id
        ...SponsorshipFormFields
      }
    }
  }
`)

const mutation = gql(/* GraphQL */ `
  mutation UpdateSponsorship($input: SponsorshipUpdateInput!) {
    sponsorshipUpdate(input: $input) {
      sponsorship {
        ...SponsorshipFormFields
      }
    }
  }
`)

export const AdminEditSponsorship = (props: {
  sponsorshipId: string
  onUpdateComplete: () => void
}) => {
  const result = useQuery(query, {
    variables: { id: props.sponsorshipId },
  })
  if (result.loading) return <LoadingIndicatorCentered />
  if (result.error) return <GraphqlError error={result.error} />

  invariant(result.data)

  const sponsorship = result.data.node
  invariant(sponsorship)
  invariant(sponsorship.__typename === "Sponsorship")

  return (
    <EditForm
      sponsorship={sponsorship}
      onUpdateComplete={props.onUpdateComplete}
    />
  )
}

const EditForm = (props: {
  sponsorship: FragmentType<typeof sponsorshipFormFieldsFragment>
  onUpdateComplete: () => void
}) => {
  const sponsorship = getFragmentData(
    sponsorshipFormFieldsFragment,
    props.sponsorship
  )

  const [exec, mutationResult] = useMutation(mutation, {
    onError: (error) => {
      displayErrors(error?.graphQLErrors, formik.setFieldError)
    },
    onCompleted: (data) => {
      invariant(data.sponsorshipUpdate.sponsorship)
      let sponsorship = getFragmentData(
        sponsorshipFormFieldsFragment,
        data.sponsorshipUpdate.sponsorship
      )

      toast.success(`${sponsorship.sponsor.sponsorName} sponsorship updated`)
      props.onUpdateComplete()
    },
  })

  const initialValues: SponsorshipFormFields = {
    placementDate: sponsorship.placementDate,
    sponsor: sponsorship.sponsor,
    newsletter: sponsorship.newsletter,
    viewInBrowserLink: sponsorship.viewInBrowserLink ?? "",
    viewInBrowserLinkMissing: sponsorship.viewInBrowserLink == null,
    sponsorLandingPage: sponsorship.sponsorLandingPage ?? "",
    sponsorLandingPageMissing: sponsorship.sponsorLandingPage == null,
    sponsorshipPlacement: sponsorship.sponsorshipPlacement,
    images: sponsorship.images,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      invariant(values.sponsor)
      invariant(values.newsletter)

      const sponsorshipPlacement =
        values.sponsorshipPlacement as SponsorshipPlacement

      exec({
        variables: {
          input: {
            id: sponsorship.id,
            sponsorshipInput: {
              placementDate: values.placementDate,
              sponsorId: values.sponsor.id,
              newsletterId: values.newsletter.id,
              viewInBrowserLink: values.viewInBrowserLinkMissing
                ? null
                : values.viewInBrowserLink,
              sponsorLandingPage: values.sponsorLandingPageMissing
                ? null
                : values.sponsorLandingPage,
              sponsorshipPlacement,
              imageIds: values.images.map((image) => image.id),
            },
          },
        },
      })
    },
  })

  return (
    <SponsorshipForm
      formik={formik}
      isLoading={mutationResult.loading}
      showAddAnother={false}
    />
  )
}

const deleteMutation = gql(/* GraphQL */ `
  mutation DeleteSponsorship($input: SponsorshipDeleteInput!) {
    sponsorshipDelete(input: $input) {
      sponsorship {
        id
      }
    }
  }
`)

export const DeleteSponsorshipButton = (props: {
  sponsorshipId: string
  onDeleteComplete: () => void
}) => {
  const [execDelete, deleteMutationResult] = useMutation(deleteMutation, {
    onCompleted: () => {
      toast.success("Sponsorship deleted")
      props.onDeleteComplete()
    },
  })

  return (
    <ConfirmDelete
      onDelete={() => {
        execDelete({ variables: { input: { id: props.sponsorshipId } } })
      }}
      message="Are you sure you want to delete this sponsorship?"
      result={deleteMutationResult}
    />
  )
}
