import { useMutation } from "@apollo/client"
import { Blob, DirectUpload } from "@rails/activestorage"
import { useCallback, useMemo } from "react"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"

export const useDirectUpload = () => {
  const upload = useCallback((file: File) => {
    const upload = new DirectUpload(
      file,
      "/rails/active_storage/direct_uploads"
    )

    return new Promise<Blob>((resolve, reject) => {
      upload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }, [])

  return useMemo(() => {
    return {
      upload,
    }
  }, [upload])
}

export const uploadedImageFragment = gql(/* GraphQL */ `
  fragment UploadedImage on Image {
    id
    thumbnailUrl
    originalUrl
    width
    height
  }
`)

const createImageMutation = gql(/* GraphQL */ `
  mutation CreateImage($input: ImageCreateInput!) {
    imageCreate(input: $input) {
      image {
        ...UploadedImage
      }
    }
  }
`)

export const useUploadImage = () => {
  const { upload } = useDirectUpload()
  const [exec] = useMutation(createImageMutation)

  return useMemo(() => {
    return {
      upload: async (file: File) => {
        const result = await upload(file)
        const mutationResp = await exec({
          variables: {
            input: {
              signedId: result.signed_id,
            },
          },
        })
        if (mutationResp.errors) {
          toast("Error uploading image: " + mutationResp.errors[0].message)
          return
        }

        invariant(mutationResp.data)
        return getFragmentData(
          uploadedImageFragment,
          mutationResp.data.imageCreate.image
        )
      },
    }
  }, [upload, exec])
}
