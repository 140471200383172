import { useMutation, useQuery } from "@apollo/client"
import { GraphQLErrors } from "@apollo/client/errors"
import { Form, FormikProvider, useFormik } from "formik"
import { useEffect, useMemo } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { displayErrors } from "~/common/validation-errors"
import { GraphqlError } from "../errors/graph-error"
import { FormikField } from "../fields/formik-fields"
import { NewsletterField } from "../fields/newsletter-input"
import { Button } from "../ui/button"
import { LoadingIndicatorCentered } from "../ui/loading-indicator"

type Fields = {
  name: string
  newsletterIds: Array<string>
}

export const NewsletterSetForm: React.FC<{
  fields: Fields
  onSubmit: (values: Fields) => Promise<unknown>
  errors?: GraphQLErrors
  onDismiss?: () => void
  loading?: boolean
}> = ({ ...props }) => {
  const formik = useFormik<Fields>({
    initialValues: props.fields,
    enableReinitialize: true,
    onSubmit: (values) => {
      return props.onSubmit(values)
    },
  })

  useEffect(() => {
    if (props.errors) {
      displayErrors(props.errors, formik.setFieldError)
    }
  }, [formik.setFieldError, props.errors])

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="flex flex-col gap-4">
          <FormikField
            name="name"
            label="Name"
            autocomplete="off"
            disabled={props.loading}
          />
          <NewsletterField name="newsletterIds" label="Newsletters" />
          <div className="flex gap-2 w-full">
            <Button type="submit" className="flex-1">
              Save
            </Button>
            {props.onDismiss ? (
              <Button
                type="button"
                onClick={() => {
                  invariant(props.onDismiss)
                  formik.resetForm()
                  props.onDismiss()
                }}
                className="flex-1"
                variant="outline"
              >
                Cancel
              </Button>
            ) : null}
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}

const createMutation = gql(/* GraphQL */ `
  mutation CreateNewsletterSetMutation($input: NewsletterSetCreateInput!) {
    newsletterSetCreate(input: $input) {
      newsletterSet {
        id
        name
      }
    }
  }
`)

export const NewsletterSetNewForm: React.FC<{
  onCreated: (newsletterSet: { id: string }) => void
  onDismiss: () => void
  newsletterIds: Array<string>
  loading?: boolean
}> = ({ ...props }) => {
  const [createNewsletterSet, createNewsletterSetResult] = useMutation(
    createMutation,
    {
      onCompleted: (data) => {
        props.onCreated(data.newsletterSetCreate.newsletterSet)
      },
    }
  )
  const fields = useMemo(() => {
    return { name: "", newsletterIds: props.newsletterIds }
  }, [props.newsletterIds])

  return (
    <NewsletterSetForm
      fields={fields}
      loading={props.loading}
      onSubmit={async (values) => {
        await createNewsletterSet({
          variables: {
            input: {
              newsletterSetInput: {
                name: values.name,
                newsletterIds: values.newsletterIds,
              },
            },
          },
        })
      }}
      errors={createNewsletterSetResult.error?.graphQLErrors}
      onDismiss={() => {
        props.onDismiss()
      }}
    />
  )
}

const editQuery = gql(/* GraphQL */ `
  query NewsletterSetEditQuery($id: ID!) {
    newsletterSet: node(id: $id) {
      ... on NewsletterSet {
        id
        name
        newsletterIds
      }
    }
  }
`)

const updateMutation = gql(/* GraphQL */ `
  mutation UpdateNewsletterSetMutation($input: NewsletterSetUpdateInput!) {
    newsletterSetUpdate(input: $input) {
      newsletterSet {
        id
        name
        newslettersCount
        newsletterIds
      }
    }
  }
`)

export const NewsletterSetEditForm: React.FC<{
  onUpdated: (newsletterSet: { id: string }) => void
  onDismiss: () => void
  newsletterSetId: string
}> = ({ ...props }) => {
  const [updateNewsletterSet, updateNewsletterSetResult] = useMutation(
    updateMutation,
    {
      onCompleted: (data) => {
        props.onUpdated(data.newsletterSetUpdate.newsletterSet)
      },
    }
  )

  const result = useQuery(editQuery, {
    variables: { id: props.newsletterSetId },
  })

  if (result.error) {
    return <GraphqlError error={result.error} />
  }
  if (result.loading) {
    return <LoadingIndicatorCentered className="py-4" />
  }
  invariant(result.data)
  invariant(result.data.newsletterSet?.__typename === "NewsletterSet")

  return (
    <NewsletterSetForm
      fields={result.data.newsletterSet}
      onSubmit={async (values) => {
        await updateNewsletterSet({
          variables: {
            input: {
              id: props.newsletterSetId,
              newsletterSetInput: {
                name: values.name,
                newsletterIds: values.newsletterIds,
              },
            },
          },
        })
      }}
      errors={updateNewsletterSetResult.error?.graphQLErrors}
      onDismiss={() => {
        props.onDismiss()
      }}
    />
  )
}
