import { ChevronDownIcon, ChevronUpIcon } from "lucide-react"
import { useState } from "react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu"
import { Button } from "../ui/button"

export type SearchDownloadFormat =
  | "contacts-csv"
  | "contacts-xlsx"
  | "companies-csv"
  | "companies-xlsx"

const DownloadSearch = ({
  downloadSearch,
  disabled,
  minimal,
}: {
  downloadSearch: (format: SearchDownloadFormat) => void
  disabled: boolean
  minimal?: boolean
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            type="button"
            variant={minimal ? "linkSecondary" : "white"}
            size={minimal ? "unpadded" : "default"}
            className={minimal ? "" : "w-full"}
            disabled={disabled}
          >
            {minimal ? "Download" : "Download Search"}
            {!minimal && (
              <div className="scale-75">
                {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full">
          <DropdownMenuItem
            onClick={() => downloadSearch("contacts-csv")}
            className="w-full"
            disabled={disabled}
          >
            Contacts - CSV Format
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => downloadSearch("contacts-xlsx")}
            disabled={disabled}
          >
            Contacts - XLSX Format
          </DropdownMenuItem>
          <hr className="my-1" />
          <DropdownMenuItem
            onClick={() => downloadSearch("companies-csv")}
            className="w-full"
            disabled={disabled}
          >
            Companies - CSV Format
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => downloadSearch("companies-xlsx")}
            disabled={disabled}
          >
            Companies - XLSX Format
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default DownloadSearch
