import { ChevronDownIcon } from "lucide-react"
import { Button } from "../../components/ui/button"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "~/components/ui/hover-card"

export const UnauthorizedDownloadSearch = () => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <Button
          type="button"
          variant="white"
          size="default"
          className="w-full"
          disabled={true}
        >
          Download Search
          <div className="scale-75">
            <ChevronDownIcon />
          </div>
        </Button>
      </HoverCardTrigger>
      <HoverCardContent className="bg-yellow border-primary opacity-90 w-auto max-w-[500px] text-center">
        Downloads are not available on the Sales Pro trial. To complete your
        purchase, please reach out to us at{" "}
        <a href="mailto:hello@whosponsorsstuff.com" className="font-bold">
          hello@whosponsorsstuff.com
        </a>
        .
      </HoverCardContent>
    </HoverCard>
  )
}
