/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query IndustryField {\n    industries(first: 200) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.IndustryFieldDocument,
    "\n  query NewsletterInputQuery($query: String!) {\n    newsletters(first: 10, filters: { search: $query }) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n": types.NewsletterInputQueryDocument,
    "\n  mutation CreateNewsletterMutation($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        id\n        name\n      }\n    }\n  }\n": types.CreateNewsletterMutationDocument,
    "\n  query NewsletterField($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.NewsletterFieldDocument,
    "\n  query NewsletterFieldWithSet($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.NewsletterFieldWithSetDocument,
    "\n  query SponsorInputQuery($query: String!, $first: Int!, $after: String) {\n    sponsors(\n      first: $first\n      after: $after\n      filters: { search: $query, verified: null }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        node {\n          id\n          sponsorName\n          logo {\n            id\n            thumbnailUrl\n          }\n          verified\n        }\n      }\n    }\n  }\n": types.SponsorInputQueryDocument,
    "\n  mutation CreateSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        id\n        sponsorName\n        logo {\n          id\n          thumbnailUrl\n        }\n        verified\n      }\n    }\n  }\n": types.CreateSponsorMutationDocument,
    "\n  query SponsorField(\n    $query: String\n    $sponsorsAfter: String\n    $selectedSponsorIds: [ID!]!\n    $newsletterIds: [GlobalIdInput!]\n    $first: Int!\n  ) {\n    selectedSponsors: nodes(ids: $selectedSponsorIds) {\n      ... on Sponsor {\n        id\n        sponsorName\n      }\n    }\n\n    sponsors(\n      first: $first\n      filters: { search: $query, newsletterIds: $newsletterIds }\n      after: $sponsorsAfter\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          sponsorName\n        }\n      }\n    }\n  }\n": types.SponsorFieldDocument,
    "\n  query TopicField {\n    topics(first: 200) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.TopicFieldDocument,
    "\n  fragment IndustryFormFields on Industry {\n    name\n  }\n": types.IndustryFormFieldsFragmentDoc,
    "\n  query NewsletterFormQuery {\n    topics(first: 100) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n": types.NewsletterFormQueryDocument,
    "\n  fragment NewsletterFormFields on Newsletter {\n    id\n    name\n    signUpUrl\n    notes\n    viewInBrowserAvailable\n    availableForFreeUsers\n    description\n    estimatedCpm\n    audienceSize\n    contact\n    verified\n    currentlyTracking\n    displayTopic {\n      id\n      name\n    }\n    topics(first: 50) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n": types.NewsletterFormFieldsFragmentDoc,
    "\n  query NewsletterFormSponsorships($id: GlobalIdInput!, $after: String) {\n    newsletter(id: $id) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n": types.NewsletterFormSponsorshipsDocument,
    "\n  mutation DeleteNewsletterMutation($input: NewsletterDeleteInput!) {\n    newsletterDelete(input: $input) {\n      newsletter {\n        id\n      }\n    }\n  }\n": types.DeleteNewsletterMutationDocument,
    "\n  mutation CreateNewsletterSetMutation($input: NewsletterSetCreateInput!) {\n    newsletterSetCreate(input: $input) {\n      newsletterSet {\n        id\n        name\n      }\n    }\n  }\n": types.CreateNewsletterSetMutationDocument,
    "\n  query NewsletterSetEditQuery($id: ID!) {\n    newsletterSet: node(id: $id) {\n      ... on NewsletterSet {\n        id\n        name\n        newsletterIds\n      }\n    }\n  }\n": types.NewsletterSetEditQueryDocument,
    "\n  mutation UpdateNewsletterSetMutation($input: NewsletterSetUpdateInput!) {\n    newsletterSetUpdate(input: $input) {\n      newsletterSet {\n        id\n        name\n        newslettersCount\n        newsletterIds\n      }\n    }\n  }\n": types.UpdateNewsletterSetMutationDocument,
    "\n  fragment OrganizationFormFields on Organization {\n    name\n    domains\n  }\n": types.OrganizationFormFieldsFragmentDoc,
    "\n  fragment SponsorFormFields on Sponsor {\n    id\n    sponsorName\n    websiteForCompany\n    verified\n    aboutTextFromLinkedIn\n    linkedInForCompany\n    websiteForCompany\n    facebookUrl\n    twitterUrl\n    instagramUrl\n    youtubeUrl\n    discordUrl\n    contactUrl\n    bounceStatus\n    createdAt\n    slug\n    freeUsersCanView\n    requiresFurtherExploration\n    readyForAlerts\n    reverificationOn\n\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n    industries {\n      nodes {\n        id\n        name\n      }\n    }\n    sponsorContacts {\n      id\n      firstName\n      lastName\n      title\n      email\n      linkedIn\n    }\n    generalSponsorContacts {\n      id\n      email\n    }\n  }\n": types.SponsorFormFieldsFragmentDoc,
    "\n  query SponsorFormSponsorships($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n": types.SponsorFormSponsorshipsDocument,
    "\n  mutation SponsorFormClearBounce($input: BounceClearCreateInput!) {\n    bounceClearCreate(input: $input) {\n      bounceClear {\n        id\n        sponsor {\n          id\n          bounceStatus\n        }\n      }\n    }\n  }\n": types.SponsorFormClearBounceDocument,
    "\n  query SponsorForm {\n    industries(first: 200) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n": types.SponsorFormDocument,
    "\n  mutation DeleteSponsorMutation($input: SponsorDeleteInput!) {\n    sponsorDelete(input: $input) {\n      sponsor {\n        id\n      }\n    }\n  }\n": types.DeleteSponsorMutationDocument,
    "\n  fragment SponsorshipFormFields on Sponsorship {\n    id\n    sponsor {\n      id\n      sponsorName\n    }\n    newsletter {\n      id\n      name\n    }\n    placementDate\n    sponsorLandingPage\n    sponsorshipPlacement\n    viewInBrowserLink\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n": types.SponsorshipFormFieldsFragmentDoc,
    "\n  query SponsorshipFormNewsletterQuery($newsletterId: ID!) {\n    node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        viewInBrowserAvailable\n      }\n    }\n  }\n": types.SponsorshipFormNewsletterQueryDocument,
    "\n  fragment TopicFormFields on Topic {\n    name\n  }\n": types.TopicFormFieldsFragmentDoc,
    "\n  mutation UpdateUserAlertsMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserAlertsFields\n      }\n    }\n  }\n": types.UpdateUserAlertsMutationDocument,
    "\n  fragment UserFormFields on User {\n    id\n    email\n    fullName\n    role\n    organization {\n      id\n      name\n    }\n  }\n": types.UserFormFieldsFragmentDoc,
    "\n  query UserFormQuery {\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.UserFormQueryDocument,
    "\n  query TopSponsorsLayout($industryName: String, $year: Int) {\n    topSponsors(industryName: $industryName, year: $year) {\n      edges {\n        node {\n          id\n          ...TopSponsorCard\n        }\n      }\n    }\n  }\n": types.TopSponsorsLayoutDocument,
    "\n  query LandingScreenUpgradeBanner {\n    totalVerifiedSponsorsCount\n    canViewUpgradeBanner {\n      value\n    }\n  }\n": types.LandingScreenUpgradeBannerDocument,
    "\n  query SponsorshipEditQuery($id: ID!) {\n    node(id: $id) {\n      ... on Sponsorship {\n        id\n        ...SponsorshipFormFields\n      }\n    }\n  }\n": types.SponsorshipEditQueryDocument,
    "\n  mutation UpdateSponsorship($input: SponsorshipUpdateInput!) {\n    sponsorshipUpdate(input: $input) {\n      sponsorship {\n        ...SponsorshipFormFields\n      }\n    }\n  }\n": types.UpdateSponsorshipDocument,
    "\n  mutation DeleteSponsorship($input: SponsorshipDeleteInput!) {\n    sponsorshipDelete(input: $input) {\n      sponsorship {\n        id\n      }\n    }\n  }\n": types.DeleteSponsorshipDocument,
    "\n  fragment AdminSponsorshipConnection on SponsorshipConnection {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        placementDate\n        sponsorshipPlacement\n        viewInBrowserLink\n        sponsorLandingPage\n\n        ...SponsorshipImages\n\n        sponsor {\n          id\n          sponsorName\n          slug\n        }\n        newsletter {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminSponsorshipConnectionFragmentDoc,
    "\n  fragment SponsorshipImages on Sponsorship {\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n": types.SponsorshipImagesFragmentDoc,
    "\n  fragment BounceReportTable on BounceReport {\n    id\n    createdAt\n    message\n    status\n    reporter {\n      id\n      fullName\n    }\n    sponsor {\n      id\n      slug\n      sponsorName\n    }\n    bounceClear {\n      id\n      createdAt\n      user {\n        id\n        fullName\n      }\n    }\n  }\n": types.BounceReportTableFragmentDoc,
    "\n  query Header {\n    viewer {\n      id\n      email\n      role\n    }\n    canShowSponsorSearch {\n      value\n    }\n    canManage {\n      value\n    }\n  }\n": types.HeaderDocument,
    "\n  fragment NewsletterTopics on Newsletter {\n    topics(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.NewsletterTopicsFragmentDoc,
    "\n  fragment SponsorCard on Sponsor {\n    slug\n    sponsorName\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n": types.SponsorCardFragmentDoc,
    "\n  fragment TopSponsorCard on Sponsor {\n    slug\n    sponsorName\n    aboutTextFromLinkedIn\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n": types.TopSponsorCardFragmentDoc,
    "\n  fragment UploadedImage on Image {\n    id\n    thumbnailUrl\n    originalUrl\n    width\n    height\n  }\n": types.UploadedImageFragmentDoc,
    "\n  mutation CreateImage($input: ImageCreateInput!) {\n    imageCreate(input: $input) {\n      image {\n        ...UploadedImage\n      }\n    }\n  }\n": types.CreateImageDocument,
    "\n  query AdminBounceReportIndex($after: String, $status: BounceReportStatus) {\n    records: bounceReports(after: $after, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...BounceReportTable\n        }\n      }\n    }\n  }\n": types.AdminBounceReportIndexDocument,
    "\n  query AdminIndustryEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    industry: node(id: $id) {\n      ... on Industry {\n        id\n        sponsors(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            sponsorName\n            slug\n          }\n        }\n        ...IndustryFormFields\n      }\n    }\n  }\n": types.AdminIndustryEditScreenQueryDocument,
    "\n  mutation UpdateIndustryMutation($input: IndustryUpdateInput!) {\n    industryUpdate(input: $input) {\n      industry {\n        id\n        ...IndustryFormFields\n      }\n    }\n  }\n": types.UpdateIndustryMutationDocument,
    "\n  mutation DeleteIndustryMutation($input: IndustryDeleteInput!) {\n    industryDelete(input: $input) {\n      industry {\n        id\n      }\n    }\n  }\n": types.DeleteIndustryMutationDocument,
    "\n  query AdminIndustryIndex($after: String) {\n    industries(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminIndustryIndexDocument,
    "\n  mutation NewIndustryMutation($input: IndustryCreateInput!) {\n    industryCreate(input: $input) {\n      industry {\n        ...IndustryFormFields\n      }\n    }\n  }\n": types.NewIndustryMutationDocument,
    "\n  query AdminLayoutScreen {\n    viewer {\n      id\n      role\n    }\n    canManage {\n      value\n    }\n  }\n": types.AdminLayoutScreenDocument,
    "\n  query AdminNewsletterEditScreenQuery($id: ID!) {\n    newsletter: node(id: $id) {\n      ... on Newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n": types.AdminNewsletterEditScreenQueryDocument,
    "\n  mutation UpdateNewsletterMutation($input: NewsletterUpdateInput!) {\n    newsletterUpdate(input: $input) {\n      newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n": types.UpdateNewsletterMutationDocument,
    "\n  query AdminNewsletterIndexScreenQuery(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 10000, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          sponsorshipsCount\n          verified\n          availableForFreeUsers\n          currentlyTracking\n          latestSponsorshipDate\n        }\n      }\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    newsletterSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminNewsletterIndexScreenQueryDocument,
    "\n  mutation DeleteNewsletterSearch($input: NewsletterSearchDeleteInput!) {\n    newsletterSearchDelete(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n": types.DeleteNewsletterSearchDocument,
    "\n  mutation SaveNewsletterSearch($input: NewsletterSearchCreateInput!) {\n    newsletterSearchCreate(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n": types.SaveNewsletterSearchDocument,
    "\n  query AdminNewsletterIndexSearchQuery($newsletterSearchId: ID) {\n    newsletterSearch: node(id: $newsletterSearchId) {\n      ... on NewsletterSearch {\n        id\n        name\n        newsletterIds\n        search\n        sponsorIds\n\n        newsletterIds\n        search\n        sponsorIds\n        topicIds\n        sortBy {\n          field\n          direction\n        }\n        sizeBands\n        sponsorshipsCountMin\n        sponsorshipsCountMax\n        verified\n        viewInBrowserAvailable\n        availableForFreeUsers\n        currentlyTracking\n\n        mostRecentSponsorshipCreatedRelative\n        mostRecentSponsorshipCreatedMin\n        mostRecentSponsorshipCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n": types.AdminNewsletterIndexSearchQueryDocument,
    "\n  mutation CreateNewsletter($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        name\n        ...NewsletterFormFields\n      }\n    }\n  }\n": types.CreateNewsletterDocument,
    "\n  query AdminOrganizationEditScreenQuery($id: ID!) {\n    organization: node(id: $id) {\n      ... on Organization {\n        id\n        ...OrganizationFormFields\n        usersCount\n      }\n    }\n  }\n": types.AdminOrganizationEditScreenQueryDocument,
    "\n  mutation UpdateOrganizationMutation($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationFormFields\n      }\n    }\n  }\n": types.UpdateOrganizationMutationDocument,
    "\n  mutation DeleteOrganizationMutation($input: OrganizationDeleteInput!) {\n    organizationDelete(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n": types.DeleteOrganizationMutationDocument,
    "\n  query AdminOrganizationIndexScreen($after: String) {\n    organizations(after: $after) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          id\n          name\n          domains\n          usersCount\n        }\n      }\n    }\n  }\n": types.AdminOrganizationIndexScreenDocument,
    "\n  mutation NewOrganizationMutation($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        ...OrganizationFormFields\n      }\n    }\n  }\n": types.NewOrganizationMutationDocument,
    "\n  query AdminSponsorEditScreenQuery(\n    $slug: String!\n    $bounceReportsAfter: String\n  ) {\n    sponsor(slug: $slug) {\n      id\n      slug\n      ...SponsorFormFields\n      bounceReports(after: $bounceReportsAfter) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          node {\n            id\n            ...BounceReportTable\n          }\n        }\n      }\n    }\n  }\n": types.AdminSponsorEditScreenQueryDocument,
    "\n  mutation UpdateSponsorMutation($input: SponsorUpdateInput!) {\n    sponsorUpdate(input: $input) {\n      sponsor {\n        id\n        ...SponsorFormFields\n      }\n    }\n  }\n": types.UpdateSponsorMutationDocument,
    "\n  query AdminSponsorIndexScreenQuery(\n    $after: String\n    $filters: SponsorSearchInput\n  ) {\n    sponsors(first: 100, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          createdAt\n          sponsorName\n          websiteForCompany\n          verified\n          requiresFurtherExploration\n          readyForAlerts\n          slug\n        }\n      }\n      totalCount\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    sponsorSearches(first: 500, context: ADMIN_SHARED) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminSponsorIndexScreenQueryDocument,
    "\n  query AdminSponsorIndexSearchQuery($sponsorSearchId: ID) {\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        name\n        newsletterIds\n        availableForFreeUsers\n        readyForAlerts\n        requiresFurtherExploration\n        search\n        verified\n        contactEmail\n        upcomingAlerts\n        contactInfo\n        sortOrderField\n        sortOrderDirection\n        website\n        sponsorshipCountMin\n        sponsorshipCountMax\n        reverification\n        mostRecentSponsorshipCreated {\n          min\n          max\n        }\n        sponsorCreatedRelative\n        sponsorCreatedMin\n        sponsorCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n": types.AdminSponsorIndexSearchQueryDocument,
    "\n  mutation DeleteSponsorAdminSearch($input: SponsorSearchDeleteInput!) {\n    sponsorSearchDelete(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n": types.DeleteSponsorAdminSearchDocument,
    "\n  mutation SaveSponsorSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n": types.SaveSponsorSearchDocument,
    "\n  mutation NewSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        ...SponsorFormFields\n      }\n    }\n  }\n": types.NewSponsorMutationDocument,
    "\n  query AdminSponsorshipIndexScreenQuery(\n    $after: String\n    $filters: SponsorshipSearchInput\n  ) {\n    viewer {\n      id\n      role\n    }\n    sponsorships(first: 100, after: $after, filters: $filters) {\n      ...AdminSponsorshipConnection\n      totalCount\n    }\n\n    sponsorshipSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminSponsorshipIndexScreenQueryDocument,
    "\n  query AdminSponsorshipIndexSearchQuery($sponsorshipSearchId: ID) {\n    sponsorshipSearch: node(id: $sponsorshipSearchId) {\n      ... on SponsorshipSearch {\n        id\n        name\n        newsletterIds\n        newsletterSearch\n        sponsorIds\n        sponsorSearch\n        sponsorshipPlacement\n        placementDateMin\n        placementDateMax\n        viewInBrowserLinkMissing\n        sponsorLandingPageMissing\n        createdRelative\n        createdRange {\n          min\n          max\n        }\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n": types.AdminSponsorshipIndexSearchQueryDocument,
    "\n  mutation DeleteSponsorshipSearch($input: SponsorshipSearchDeleteInput!) {\n    sponsorshipSearchDelete(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n": types.DeleteSponsorshipSearchDocument,
    "\n  mutation SaveSponsorshipSearch($input: SponsorshipSearchCreateInput!) {\n    sponsorshipSearchCreate(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n": types.SaveSponsorshipSearchDocument,
    "\n  mutation CreateSponsorship($input: SponsorshipCreateInput!) {\n    sponsorshipCreate(input: $input) {\n      sponsorship {\n        id\n        sponsor {\n          id\n          sponsorName\n        }\n        newsletter {\n          id\n        }\n      }\n    }\n  }\n": types.CreateSponsorshipDocument,
    "\n  query AdminSponsorshipNewQuery($newsletterId: ID!) {\n    newsletter: node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        name\n      }\n    }\n  }\n": types.AdminSponsorshipNewQueryDocument,
    "\n  query AdminTopicEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    topic: node(id: $id) {\n      ... on Topic {\n        id\n        newsletters(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            name\n            audienceSize\n          }\n        }\n        ...TopicFormFields\n      }\n    }\n  }\n": types.AdminTopicEditScreenQueryDocument,
    "\n  mutation UpdateTopicMutation($input: TopicUpdateInput!) {\n    topicUpdate(input: $input) {\n      topic {\n        id\n        ...TopicFormFields\n      }\n    }\n  }\n": types.UpdateTopicMutationDocument,
    "\n  mutation DeleteTopicMutation($input: TopicDeleteInput!) {\n    topicDelete(input: $input) {\n      topic {\n        id\n      }\n    }\n  }\n": types.DeleteTopicMutationDocument,
    "\n  query getTopics($after: String) {\n    topics(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.GetTopicsDocument,
    "\n  mutation NewTopicMutation($input: TopicCreateInput!) {\n    topicCreate(input: $input) {\n      topic {\n        ...TopicFormFields\n      }\n    }\n  }\n": types.NewTopicMutationDocument,
    "\n  query AdminUserEditScreenQuery($id: ID!) {\n    node(id: $id) {\n      ... on User {\n        ...UserFormFields\n      }\n    }\n  }\n": types.AdminUserEditScreenQueryDocument,
    "\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n": types.UpdateUserMutationDocument,
    "\n  mutation DeleteUserMutation($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.DeleteUserMutationDocument,
    "\n  query AdminUsersScreenQuery(\n    $after: String\n    $query: String\n    $organizationId: GlobalIdInput\n    $sortBy: UserSortByInput\n    $role: Role\n  ) {\n    users(\n      first: 50\n      after: $after\n      query: $query\n      organizationId: $organizationId\n      sortBy: $sortBy\n      role: $role\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          email\n          fullName\n          role\n          organization {\n            id\n            name\n          }\n        }\n      }\n    }\n\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.AdminUsersScreenQueryDocument,
    "\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n": types.CreateUserMutationDocument,
    "\n  query MyProfileScreenQuery {\n    viewer {\n      id\n      email\n      fullName\n    }\n  }\n": types.MyProfileScreenQueryDocument,
    "\n  mutation UpdateViewerMutation($input: ViewerUpdateInput!) {\n    viewerUpdate(input: $input) {\n      viewer {\n        id\n        email\n        fullName\n      }\n    }\n  }\n": types.UpdateViewerMutationDocument,
    "\n  query NewsletterDetailScreen($id: GlobalIdInput!) {\n    newsletter(id: $id) {\n      id\n      name\n      description\n      audienceSize\n      audienceSizeBand\n      signUpUrl\n      sponsorshipsCount\n      currentlyTracking\n      verified\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      ...NewsletterTopics\n    }\n  }\n": types.NewsletterDetailScreenDocument,
    "\n  query NewsletterSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...NewsletterDetailSponsorship\n        }\n      }\n    }\n  }\n": types.NewsletterSponsorshipsDocument,
    "\n  fragment NewsletterDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    sponsor {\n      id\n      sponsorName\n      slug\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n": types.NewsletterDetailSponsorshipFragmentDoc,
    "\n  query NewsletterSetsScreenQuery {\n    canCreateSponsorSearch {\n      value\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          ...NewsletterSetRow_newsletterSet\n        }\n      }\n    }\n  }\n": types.NewsletterSetsScreenQueryDocument,
    "\n  fragment NewsletterSetRow_newsletterSet on NewsletterSet {\n    id\n    name\n    newslettersCount\n  }\n": types.NewsletterSetRow_NewsletterSetFragmentDoc,
    "\n  mutation NewsletterSetDeleteMutation($id: ID!) {\n    newsletterSetDelete(input: { id: $id }) {\n      newsletterSet {\n        id\n      }\n    }\n  }\n": types.NewsletterSetDeleteMutationDocument,
    "\n  query NewsletterSetDeleteQuery($newsletterSetId: ID!) {\n    node(id: $newsletterSetId) {\n      ... on NewsletterSet {\n        id\n        sponsorSearches {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.NewsletterSetDeleteQueryDocument,
    "\n  query NewslettersIndexScreen(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 48, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          allTopics {\n            id\n            name\n          }\n        }\n      }\n    }\n    newsletterSets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n": types.NewslettersIndexScreenDocument,
    "\n  query SearchesScreen($after: String) {\n    canCreateSponsorSearch {\n      value\n    }\n    sponsorSearches(first: 50, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorSearchRow\n        }\n      }\n    }\n  }\n": types.SearchesScreenDocument,
    "\n  fragment UserAlertsFields on User {\n    id\n    canReceiveAlerts\n    sendNewSponsorAlertsDaily\n    sendNewSponsorAlertsWeekly\n    sendBounceAlertsWeekly\n  }\n": types.UserAlertsFieldsFragmentDoc,
    "\n  query UserAlertsQuery {\n    viewer {\n      ...UserAlertsFields\n    }\n  }\n": types.UserAlertsQueryDocument,
    "\n  fragment SponsorSearchRow on SponsorSearch {\n    id\n    name\n\n    emailsDaily\n    emailsWeekly\n\n    canDownload {\n      value\n    }\n  }\n": types.SponsorSearchRowFragmentDoc,
    "\n  mutation DeleteSponsorSearch($id: ID!) {\n    sponsorSearchDelete(input: { id: $id }) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n": types.DeleteSponsorSearchDocument,
    "\n  mutation UpdateSponsorSearch($input: SponsorSearchUpdateInput!) {\n    sponsorSearchUpdate(input: $input) {\n      sponsorSearch {\n        id\n        ...SponsorSearchRow\n      }\n    }\n  }\n": types.UpdateSponsorSearchDocument,
    "\n  mutation SponsorSearchDownload($input: SponsorSearchDownloadInput!) {\n    sponsorSearchDownload(input: $input) {\n      success\n    }\n  }\n": types.SponsorSearchDownloadDocument,
    "\n  query SponsorDetailScreen($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      sponsorName\n      websiteForCompany\n      linkedInForCompany\n      aboutTextFromLinkedIn\n\n      facebookUrl\n      twitterUrl\n      instagramUrl\n      youtubeUrl\n      discordUrl\n      contactUrl\n\n      logo {\n        id\n        thumbnailUrl\n      }\n      sponsorContacts {\n        id\n        ...ContactFragment\n      }\n\n      generalSponsorContacts {\n        id\n        email\n      }\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      sponsorshipsCount\n      earliestSponsorshipDate\n      latestSponsorshipDate\n\n      ...SponsorIndustries\n    }\n  }\n": types.SponsorDetailScreenDocument,
    "\n  query SponsorSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...SponsorDetailSponsorship\n        }\n      }\n    }\n  }\n": types.SponsorSponsorshipsDocument,
    "\n  query SponsorDetailScreenChart($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      estimatedNewsletterSpendingByMonth {\n        estimatedSpending\n        month\n      }\n    }\n  }\n": types.SponsorDetailScreenChartDocument,
    "\n  query SponsorDetailScreenRelated($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      verified\n      relatedSponsors(first: 25, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n        edges {\n          node {\n            id\n            ...SponsorCard\n          }\n        }\n      }\n    }\n  }\n": types.SponsorDetailScreenRelatedDocument,
    "\n  fragment ContactFragment on SponsorContact {\n    id\n    firstName\n    lastName\n    email\n    title\n    linkedIn\n  }\n": types.ContactFragmentFragmentDoc,
    "\n  fragment SponsorDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    newsletter {\n      id\n      name\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n": types.SponsorDetailSponsorshipFragmentDoc,
    "\n  mutation BounceReport($input: BounceReportCreateInput!) {\n    bounceReportCreate(input: $input) {\n      bounceReport {\n        id\n      }\n    }\n  }\n": types.BounceReportDocument,
    "\n  fragment SponsorIndustries on Sponsor {\n    industries(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.SponsorIndustriesFragmentDoc,
    "\n  query LandingScreen(\n    $filters: SponsorSearchInput\n    $sponsorSearchId: ID\n    $after: String\n  ) {\n    sponsors(\n      first: 50\n      after: $after\n      filters: $filters\n      sponsorSearchId: $sponsorSearchId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorCard\n        }\n      }\n      totalCount\n    }\n\n    paidSponsorsCount(filters: $filters, sponsorSearchId: $sponsorSearchId)\n\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        ...SponsorSearchFilters\n      }\n    }\n\n    canViewTestimonials {\n      value\n    }\n    canViewWhoSponsorsNewslettersBanner {\n      value\n    }\n    canViewGetWholePictureBanner {\n      value\n    }\n  }\n": types.LandingScreenDocument,
    "\n  fragment SponsorSearchFilters on SponsorSearch {\n    id\n\n    sponsorshipCreatedRelative\n    sponsorshipCreatedMax\n    sponsorshipCreatedMin\n    sponsorCreatedRelative\n    sponsorCreatedMax\n    sponsorCreatedMin\n    industryIds\n    name\n    newsletterIds\n    newsletterSetIds\n    sponsorIds\n    newsletterSizeBands\n    search\n    sortOrderDirection\n    sortOrderField\n    sponsorshipCountMin\n    topicIds\n    verified\n  }\n": types.SponsorSearchFiltersFragmentDoc,
    "\n  query LandingScreenFilters {\n    canFilterSponsors {\n      value\n    }\n    canCreateSponsorSearch {\n      value\n    }\n    canShowDownloadSponsorSearch {\n      value\n    }\n  }\n": types.LandingScreenFiltersDocument,
    "\n  mutation SaveSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n": types.SaveSearchDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query IndustryField {\n    industries(first: 200) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query IndustryField {\n    industries(first: 200) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterInputQuery($query: String!) {\n    newsletters(first: 10, filters: { search: $query }) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterInputQuery($query: String!) {\n    newsletters(first: 10, filters: { search: $query }) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateNewsletterMutation($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateNewsletterMutation($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterField($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterField($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterFieldWithSet($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterFieldWithSet($filters: NewsletterSearchInput) {\n    newsletters(first: 10000, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorInputQuery($query: String!, $first: Int!, $after: String) {\n    sponsors(\n      first: $first\n      after: $after\n      filters: { search: $query, verified: null }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        node {\n          id\n          sponsorName\n          logo {\n            id\n            thumbnailUrl\n          }\n          verified\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorInputQuery($query: String!, $first: Int!, $after: String) {\n    sponsors(\n      first: $first\n      after: $after\n      filters: { search: $query, verified: null }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n\n      edges {\n        node {\n          id\n          sponsorName\n          logo {\n            id\n            thumbnailUrl\n          }\n          verified\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        id\n        sponsorName\n        logo {\n          id\n          thumbnailUrl\n        }\n        verified\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        id\n        sponsorName\n        logo {\n          id\n          thumbnailUrl\n        }\n        verified\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorField(\n    $query: String\n    $sponsorsAfter: String\n    $selectedSponsorIds: [ID!]!\n    $newsletterIds: [GlobalIdInput!]\n    $first: Int!\n  ) {\n    selectedSponsors: nodes(ids: $selectedSponsorIds) {\n      ... on Sponsor {\n        id\n        sponsorName\n      }\n    }\n\n    sponsors(\n      first: $first\n      filters: { search: $query, newsletterIds: $newsletterIds }\n      after: $sponsorsAfter\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          sponsorName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorField(\n    $query: String\n    $sponsorsAfter: String\n    $selectedSponsorIds: [ID!]!\n    $newsletterIds: [GlobalIdInput!]\n    $first: Int!\n  ) {\n    selectedSponsors: nodes(ids: $selectedSponsorIds) {\n      ... on Sponsor {\n        id\n        sponsorName\n      }\n    }\n\n    sponsors(\n      first: $first\n      filters: { search: $query, newsletterIds: $newsletterIds }\n      after: $sponsorsAfter\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          sponsorName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TopicField {\n    topics(first: 200) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query TopicField {\n    topics(first: 200) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment IndustryFormFields on Industry {\n    name\n  }\n"): (typeof documents)["\n  fragment IndustryFormFields on Industry {\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterFormQuery {\n    topics(first: 100) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterFormQuery {\n    topics(first: 100) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NewsletterFormFields on Newsletter {\n    id\n    name\n    signUpUrl\n    notes\n    viewInBrowserAvailable\n    availableForFreeUsers\n    description\n    estimatedCpm\n    audienceSize\n    contact\n    verified\n    currentlyTracking\n    displayTopic {\n      id\n      name\n    }\n    topics(first: 50) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NewsletterFormFields on Newsletter {\n    id\n    name\n    signUpUrl\n    notes\n    viewInBrowserAvailable\n    availableForFreeUsers\n    description\n    estimatedCpm\n    audienceSize\n    contact\n    verified\n    currentlyTracking\n    displayTopic {\n      id\n      name\n    }\n    topics(first: 50) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterFormSponsorships($id: GlobalIdInput!, $after: String) {\n    newsletter(id: $id) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterFormSponsorships($id: GlobalIdInput!, $after: String) {\n    newsletter(id: $id) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteNewsletterMutation($input: NewsletterDeleteInput!) {\n    newsletterDelete(input: $input) {\n      newsletter {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteNewsletterMutation($input: NewsletterDeleteInput!) {\n    newsletterDelete(input: $input) {\n      newsletter {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateNewsletterSetMutation($input: NewsletterSetCreateInput!) {\n    newsletterSetCreate(input: $input) {\n      newsletterSet {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateNewsletterSetMutation($input: NewsletterSetCreateInput!) {\n    newsletterSetCreate(input: $input) {\n      newsletterSet {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterSetEditQuery($id: ID!) {\n    newsletterSet: node(id: $id) {\n      ... on NewsletterSet {\n        id\n        name\n        newsletterIds\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterSetEditQuery($id: ID!) {\n    newsletterSet: node(id: $id) {\n      ... on NewsletterSet {\n        id\n        name\n        newsletterIds\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateNewsletterSetMutation($input: NewsletterSetUpdateInput!) {\n    newsletterSetUpdate(input: $input) {\n      newsletterSet {\n        id\n        name\n        newslettersCount\n        newsletterIds\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateNewsletterSetMutation($input: NewsletterSetUpdateInput!) {\n    newsletterSetUpdate(input: $input) {\n      newsletterSet {\n        id\n        name\n        newslettersCount\n        newsletterIds\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment OrganizationFormFields on Organization {\n    name\n    domains\n  }\n"): (typeof documents)["\n  fragment OrganizationFormFields on Organization {\n    name\n    domains\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorFormFields on Sponsor {\n    id\n    sponsorName\n    websiteForCompany\n    verified\n    aboutTextFromLinkedIn\n    linkedInForCompany\n    websiteForCompany\n    facebookUrl\n    twitterUrl\n    instagramUrl\n    youtubeUrl\n    discordUrl\n    contactUrl\n    bounceStatus\n    createdAt\n    slug\n    freeUsersCanView\n    requiresFurtherExploration\n    readyForAlerts\n    reverificationOn\n\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n    industries {\n      nodes {\n        id\n        name\n      }\n    }\n    sponsorContacts {\n      id\n      firstName\n      lastName\n      title\n      email\n      linkedIn\n    }\n    generalSponsorContacts {\n      id\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorFormFields on Sponsor {\n    id\n    sponsorName\n    websiteForCompany\n    verified\n    aboutTextFromLinkedIn\n    linkedInForCompany\n    websiteForCompany\n    facebookUrl\n    twitterUrl\n    instagramUrl\n    youtubeUrl\n    discordUrl\n    contactUrl\n    bounceStatus\n    createdAt\n    slug\n    freeUsersCanView\n    requiresFurtherExploration\n    readyForAlerts\n    reverificationOn\n\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n    industries {\n      nodes {\n        id\n        name\n      }\n    }\n    sponsorContacts {\n      id\n      firstName\n      lastName\n      title\n      email\n      linkedIn\n    }\n    generalSponsorContacts {\n      id\n      email\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorFormSponsorships($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorFormSponsorships($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      sponsorships(first: 50, after: $after) {\n        ...AdminSponsorshipConnection\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SponsorFormClearBounce($input: BounceClearCreateInput!) {\n    bounceClearCreate(input: $input) {\n      bounceClear {\n        id\n        sponsor {\n          id\n          bounceStatus\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SponsorFormClearBounce($input: BounceClearCreateInput!) {\n    bounceClearCreate(input: $input) {\n      bounceClear {\n        id\n        sponsor {\n          id\n          bounceStatus\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorForm {\n    industries(first: 200) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorForm {\n    industries(first: 200) {\n      nodes {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSponsorMutation($input: SponsorDeleteInput!) {\n    sponsorDelete(input: $input) {\n      sponsor {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSponsorMutation($input: SponsorDeleteInput!) {\n    sponsorDelete(input: $input) {\n      sponsor {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorshipFormFields on Sponsorship {\n    id\n    sponsor {\n      id\n      sponsorName\n    }\n    newsletter {\n      id\n      name\n    }\n    placementDate\n    sponsorLandingPage\n    sponsorshipPlacement\n    viewInBrowserLink\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorshipFormFields on Sponsorship {\n    id\n    sponsor {\n      id\n      sponsorName\n    }\n    newsletter {\n      id\n      name\n    }\n    placementDate\n    sponsorLandingPage\n    sponsorshipPlacement\n    viewInBrowserLink\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorshipFormNewsletterQuery($newsletterId: ID!) {\n    node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        viewInBrowserAvailable\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorshipFormNewsletterQuery($newsletterId: ID!) {\n    node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        viewInBrowserAvailable\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TopicFormFields on Topic {\n    name\n  }\n"): (typeof documents)["\n  fragment TopicFormFields on Topic {\n    name\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserAlertsMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserAlertsFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserAlertsMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserAlertsFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserFormFields on User {\n    id\n    email\n    fullName\n    role\n    organization {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment UserFormFields on User {\n    id\n    email\n    fullName\n    role\n    organization {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserFormQuery {\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query UserFormQuery {\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query TopSponsorsLayout($industryName: String, $year: Int) {\n    topSponsors(industryName: $industryName, year: $year) {\n      edges {\n        node {\n          id\n          ...TopSponsorCard\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query TopSponsorsLayout($industryName: String, $year: Int) {\n    topSponsors(industryName: $industryName, year: $year) {\n      edges {\n        node {\n          id\n          ...TopSponsorCard\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LandingScreenUpgradeBanner {\n    totalVerifiedSponsorsCount\n    canViewUpgradeBanner {\n      value\n    }\n  }\n"): (typeof documents)["\n  query LandingScreenUpgradeBanner {\n    totalVerifiedSponsorsCount\n    canViewUpgradeBanner {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorshipEditQuery($id: ID!) {\n    node(id: $id) {\n      ... on Sponsorship {\n        id\n        ...SponsorshipFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorshipEditQuery($id: ID!) {\n    node(id: $id) {\n      ... on Sponsorship {\n        id\n        ...SponsorshipFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSponsorship($input: SponsorshipUpdateInput!) {\n    sponsorshipUpdate(input: $input) {\n      sponsorship {\n        ...SponsorshipFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSponsorship($input: SponsorshipUpdateInput!) {\n    sponsorshipUpdate(input: $input) {\n      sponsorship {\n        ...SponsorshipFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSponsorship($input: SponsorshipDeleteInput!) {\n    sponsorshipDelete(input: $input) {\n      sponsorship {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSponsorship($input: SponsorshipDeleteInput!) {\n    sponsorshipDelete(input: $input) {\n      sponsorship {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AdminSponsorshipConnection on SponsorshipConnection {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        placementDate\n        sponsorshipPlacement\n        viewInBrowserLink\n        sponsorLandingPage\n\n        ...SponsorshipImages\n\n        sponsor {\n          id\n          sponsorName\n          slug\n        }\n        newsletter {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment AdminSponsorshipConnection on SponsorshipConnection {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        placementDate\n        sponsorshipPlacement\n        viewInBrowserLink\n        sponsorLandingPage\n\n        ...SponsorshipImages\n\n        sponsor {\n          id\n          sponsorName\n          slug\n        }\n        newsletter {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorshipImages on Sponsorship {\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorshipImages on Sponsorship {\n    images {\n      id\n      thumbnailUrl\n      originalUrl\n      width\n      height\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BounceReportTable on BounceReport {\n    id\n    createdAt\n    message\n    status\n    reporter {\n      id\n      fullName\n    }\n    sponsor {\n      id\n      slug\n      sponsorName\n    }\n    bounceClear {\n      id\n      createdAt\n      user {\n        id\n        fullName\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BounceReportTable on BounceReport {\n    id\n    createdAt\n    message\n    status\n    reporter {\n      id\n      fullName\n    }\n    sponsor {\n      id\n      slug\n      sponsorName\n    }\n    bounceClear {\n      id\n      createdAt\n      user {\n        id\n        fullName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Header {\n    viewer {\n      id\n      email\n      role\n    }\n    canShowSponsorSearch {\n      value\n    }\n    canManage {\n      value\n    }\n  }\n"): (typeof documents)["\n  query Header {\n    viewer {\n      id\n      email\n      role\n    }\n    canShowSponsorSearch {\n      value\n    }\n    canManage {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NewsletterTopics on Newsletter {\n    topics(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NewsletterTopics on Newsletter {\n    topics(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorCard on Sponsor {\n    slug\n    sponsorName\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorCard on Sponsor {\n    slug\n    sponsorName\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TopSponsorCard on Sponsor {\n    slug\n    sponsorName\n    aboutTextFromLinkedIn\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  fragment TopSponsorCard on Sponsor {\n    slug\n    sponsorName\n    aboutTextFromLinkedIn\n    logo {\n      id\n      thumbnailUrl\n    }\n    displayIndustry {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UploadedImage on Image {\n    id\n    thumbnailUrl\n    originalUrl\n    width\n    height\n  }\n"): (typeof documents)["\n  fragment UploadedImage on Image {\n    id\n    thumbnailUrl\n    originalUrl\n    width\n    height\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateImage($input: ImageCreateInput!) {\n    imageCreate(input: $input) {\n      image {\n        ...UploadedImage\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateImage($input: ImageCreateInput!) {\n    imageCreate(input: $input) {\n      image {\n        ...UploadedImage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminBounceReportIndex($after: String, $status: BounceReportStatus) {\n    records: bounceReports(after: $after, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...BounceReportTable\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminBounceReportIndex($after: String, $status: BounceReportStatus) {\n    records: bounceReports(after: $after, status: $status) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...BounceReportTable\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminIndustryEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    industry: node(id: $id) {\n      ... on Industry {\n        id\n        sponsors(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            sponsorName\n            slug\n          }\n        }\n        ...IndustryFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminIndustryEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    industry: node(id: $id) {\n      ... on Industry {\n        id\n        sponsors(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            sponsorName\n            slug\n          }\n        }\n        ...IndustryFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateIndustryMutation($input: IndustryUpdateInput!) {\n    industryUpdate(input: $input) {\n      industry {\n        id\n        ...IndustryFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateIndustryMutation($input: IndustryUpdateInput!) {\n    industryUpdate(input: $input) {\n      industry {\n        id\n        ...IndustryFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteIndustryMutation($input: IndustryDeleteInput!) {\n    industryDelete(input: $input) {\n      industry {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteIndustryMutation($input: IndustryDeleteInput!) {\n    industryDelete(input: $input) {\n      industry {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminIndustryIndex($after: String) {\n    industries(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminIndustryIndex($after: String) {\n    industries(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewIndustryMutation($input: IndustryCreateInput!) {\n    industryCreate(input: $input) {\n      industry {\n        ...IndustryFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewIndustryMutation($input: IndustryCreateInput!) {\n    industryCreate(input: $input) {\n      industry {\n        ...IndustryFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminLayoutScreen {\n    viewer {\n      id\n      role\n    }\n    canManage {\n      value\n    }\n  }\n"): (typeof documents)["\n  query AdminLayoutScreen {\n    viewer {\n      id\n      role\n    }\n    canManage {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminNewsletterEditScreenQuery($id: ID!) {\n    newsletter: node(id: $id) {\n      ... on Newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminNewsletterEditScreenQuery($id: ID!) {\n    newsletter: node(id: $id) {\n      ... on Newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateNewsletterMutation($input: NewsletterUpdateInput!) {\n    newsletterUpdate(input: $input) {\n      newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateNewsletterMutation($input: NewsletterUpdateInput!) {\n    newsletterUpdate(input: $input) {\n      newsletter {\n        id\n        ...NewsletterFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminNewsletterIndexScreenQuery(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 10000, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          sponsorshipsCount\n          verified\n          availableForFreeUsers\n          currentlyTracking\n          latestSponsorshipDate\n        }\n      }\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    newsletterSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminNewsletterIndexScreenQuery(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 10000, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          sponsorshipsCount\n          verified\n          availableForFreeUsers\n          currentlyTracking\n          latestSponsorshipDate\n        }\n      }\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    newsletterSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteNewsletterSearch($input: NewsletterSearchDeleteInput!) {\n    newsletterSearchDelete(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteNewsletterSearch($input: NewsletterSearchDeleteInput!) {\n    newsletterSearchDelete(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveNewsletterSearch($input: NewsletterSearchCreateInput!) {\n    newsletterSearchCreate(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveNewsletterSearch($input: NewsletterSearchCreateInput!) {\n    newsletterSearchCreate(input: $input) {\n      newsletterSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminNewsletterIndexSearchQuery($newsletterSearchId: ID) {\n    newsletterSearch: node(id: $newsletterSearchId) {\n      ... on NewsletterSearch {\n        id\n        name\n        newsletterIds\n        search\n        sponsorIds\n\n        newsletterIds\n        search\n        sponsorIds\n        topicIds\n        sortBy {\n          field\n          direction\n        }\n        sizeBands\n        sponsorshipsCountMin\n        sponsorshipsCountMax\n        verified\n        viewInBrowserAvailable\n        availableForFreeUsers\n        currentlyTracking\n\n        mostRecentSponsorshipCreatedRelative\n        mostRecentSponsorshipCreatedMin\n        mostRecentSponsorshipCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminNewsletterIndexSearchQuery($newsletterSearchId: ID) {\n    newsletterSearch: node(id: $newsletterSearchId) {\n      ... on NewsletterSearch {\n        id\n        name\n        newsletterIds\n        search\n        sponsorIds\n\n        newsletterIds\n        search\n        sponsorIds\n        topicIds\n        sortBy {\n          field\n          direction\n        }\n        sizeBands\n        sponsorshipsCountMin\n        sponsorshipsCountMax\n        verified\n        viewInBrowserAvailable\n        availableForFreeUsers\n        currentlyTracking\n\n        mostRecentSponsorshipCreatedRelative\n        mostRecentSponsorshipCreatedMin\n        mostRecentSponsorshipCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateNewsletter($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        name\n        ...NewsletterFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateNewsletter($input: NewsletterCreateInput!) {\n    newsletterCreate(input: $input) {\n      newsletter {\n        name\n        ...NewsletterFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrganizationEditScreenQuery($id: ID!) {\n    organization: node(id: $id) {\n      ... on Organization {\n        id\n        ...OrganizationFormFields\n        usersCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminOrganizationEditScreenQuery($id: ID!) {\n    organization: node(id: $id) {\n      ... on Organization {\n        id\n        ...OrganizationFormFields\n        usersCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateOrganizationMutation($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOrganizationMutation($input: OrganizationUpdateInput!) {\n    organizationUpdate(input: $input) {\n      organization {\n        id\n        ...OrganizationFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteOrganizationMutation($input: OrganizationDeleteInput!) {\n    organizationDelete(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteOrganizationMutation($input: OrganizationDeleteInput!) {\n    organizationDelete(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminOrganizationIndexScreen($after: String) {\n    organizations(after: $after) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          id\n          name\n          domains\n          usersCount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminOrganizationIndexScreen($after: String) {\n    organizations(after: $after) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          id\n          name\n          domains\n          usersCount\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewOrganizationMutation($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        ...OrganizationFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewOrganizationMutation($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        ...OrganizationFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorEditScreenQuery(\n    $slug: String!\n    $bounceReportsAfter: String\n  ) {\n    sponsor(slug: $slug) {\n      id\n      slug\n      ...SponsorFormFields\n      bounceReports(after: $bounceReportsAfter) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          node {\n            id\n            ...BounceReportTable\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorEditScreenQuery(\n    $slug: String!\n    $bounceReportsAfter: String\n  ) {\n    sponsor(slug: $slug) {\n      id\n      slug\n      ...SponsorFormFields\n      bounceReports(after: $bounceReportsAfter) {\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n        edges {\n          node {\n            id\n            ...BounceReportTable\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSponsorMutation($input: SponsorUpdateInput!) {\n    sponsorUpdate(input: $input) {\n      sponsor {\n        id\n        ...SponsorFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSponsorMutation($input: SponsorUpdateInput!) {\n    sponsorUpdate(input: $input) {\n      sponsor {\n        id\n        ...SponsorFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorIndexScreenQuery(\n    $after: String\n    $filters: SponsorSearchInput\n  ) {\n    sponsors(first: 100, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          createdAt\n          sponsorName\n          websiteForCompany\n          verified\n          requiresFurtherExploration\n          readyForAlerts\n          slug\n        }\n      }\n      totalCount\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    sponsorSearches(first: 500, context: ADMIN_SHARED) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorIndexScreenQuery(\n    $after: String\n    $filters: SponsorSearchInput\n  ) {\n    sponsors(first: 100, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          createdAt\n          sponsorName\n          websiteForCompany\n          verified\n          requiresFurtherExploration\n          readyForAlerts\n          slug\n        }\n      }\n      totalCount\n    }\n\n    viewer {\n      id\n      role\n    }\n\n    sponsorSearches(first: 500, context: ADMIN_SHARED) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorIndexSearchQuery($sponsorSearchId: ID) {\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        name\n        newsletterIds\n        availableForFreeUsers\n        readyForAlerts\n        requiresFurtherExploration\n        search\n        verified\n        contactEmail\n        upcomingAlerts\n        contactInfo\n        sortOrderField\n        sortOrderDirection\n        website\n        sponsorshipCountMin\n        sponsorshipCountMax\n        reverification\n        mostRecentSponsorshipCreated {\n          min\n          max\n        }\n        sponsorCreatedRelative\n        sponsorCreatedMin\n        sponsorCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorIndexSearchQuery($sponsorSearchId: ID) {\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        name\n        newsletterIds\n        availableForFreeUsers\n        readyForAlerts\n        requiresFurtherExploration\n        search\n        verified\n        contactEmail\n        upcomingAlerts\n        contactInfo\n        sortOrderField\n        sortOrderDirection\n        website\n        sponsorshipCountMin\n        sponsorshipCountMax\n        reverification\n        mostRecentSponsorshipCreated {\n          min\n          max\n        }\n        sponsorCreatedRelative\n        sponsorCreatedMin\n        sponsorCreatedMax\n\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSponsorAdminSearch($input: SponsorSearchDeleteInput!) {\n    sponsorSearchDelete(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSponsorAdminSearch($input: SponsorSearchDeleteInput!) {\n    sponsorSearchDelete(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveSponsorSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveSponsorSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        ...SponsorFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewSponsorMutation($input: SponsorCreateInput!) {\n    sponsorCreate(input: $input) {\n      sponsor {\n        ...SponsorFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorshipIndexScreenQuery(\n    $after: String\n    $filters: SponsorshipSearchInput\n  ) {\n    viewer {\n      id\n      role\n    }\n    sponsorships(first: 100, after: $after, filters: $filters) {\n      ...AdminSponsorshipConnection\n      totalCount\n    }\n\n    sponsorshipSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorshipIndexScreenQuery(\n    $after: String\n    $filters: SponsorshipSearchInput\n  ) {\n    viewer {\n      id\n      role\n    }\n    sponsorships(first: 100, after: $after, filters: $filters) {\n      ...AdminSponsorshipConnection\n      totalCount\n    }\n\n    sponsorshipSearches(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorshipIndexSearchQuery($sponsorshipSearchId: ID) {\n    sponsorshipSearch: node(id: $sponsorshipSearchId) {\n      ... on SponsorshipSearch {\n        id\n        name\n        newsletterIds\n        newsletterSearch\n        sponsorIds\n        sponsorSearch\n        sponsorshipPlacement\n        placementDateMin\n        placementDateMax\n        viewInBrowserLinkMissing\n        sponsorLandingPageMissing\n        createdRelative\n        createdRange {\n          min\n          max\n        }\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorshipIndexSearchQuery($sponsorshipSearchId: ID) {\n    sponsorshipSearch: node(id: $sponsorshipSearchId) {\n      ... on SponsorshipSearch {\n        id\n        name\n        newsletterIds\n        newsletterSearch\n        sponsorIds\n        sponsorSearch\n        sponsorshipPlacement\n        placementDateMin\n        placementDateMax\n        viewInBrowserLinkMissing\n        sponsorLandingPageMissing\n        createdRelative\n        createdRange {\n          min\n          max\n        }\n        canDestroy {\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSponsorshipSearch($input: SponsorshipSearchDeleteInput!) {\n    sponsorshipSearchDelete(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSponsorshipSearch($input: SponsorshipSearchDeleteInput!) {\n    sponsorshipSearchDelete(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveSponsorshipSearch($input: SponsorshipSearchCreateInput!) {\n    sponsorshipSearchCreate(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveSponsorshipSearch($input: SponsorshipSearchCreateInput!) {\n    sponsorshipSearchCreate(input: $input) {\n      sponsorshipSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateSponsorship($input: SponsorshipCreateInput!) {\n    sponsorshipCreate(input: $input) {\n      sponsorship {\n        id\n        sponsor {\n          id\n          sponsorName\n        }\n        newsletter {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSponsorship($input: SponsorshipCreateInput!) {\n    sponsorshipCreate(input: $input) {\n      sponsorship {\n        id\n        sponsor {\n          id\n          sponsorName\n        }\n        newsletter {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminSponsorshipNewQuery($newsletterId: ID!) {\n    newsletter: node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminSponsorshipNewQuery($newsletterId: ID!) {\n    newsletter: node(id: $newsletterId) {\n      ... on Newsletter {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminTopicEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    topic: node(id: $id) {\n      ... on Topic {\n        id\n        newsletters(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            name\n            audienceSize\n          }\n        }\n        ...TopicFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminTopicEditScreenQuery($id: ID!, $newslettersAfter: String) {\n    topic: node(id: $id) {\n      ... on Topic {\n        id\n        newsletters(first: 50, after: $newslettersAfter) {\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n          nodes {\n            id\n            name\n            audienceSize\n          }\n        }\n        ...TopicFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateTopicMutation($input: TopicUpdateInput!) {\n    topicUpdate(input: $input) {\n      topic {\n        id\n        ...TopicFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTopicMutation($input: TopicUpdateInput!) {\n    topicUpdate(input: $input) {\n      topic {\n        id\n        ...TopicFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteTopicMutation($input: TopicDeleteInput!) {\n    topicDelete(input: $input) {\n      topic {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteTopicMutation($input: TopicDeleteInput!) {\n    topicDelete(input: $input) {\n      topic {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getTopics($after: String) {\n    topics(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTopics($after: String) {\n    topics(after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewTopicMutation($input: TopicCreateInput!) {\n    topicCreate(input: $input) {\n      topic {\n        ...TopicFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewTopicMutation($input: TopicCreateInput!) {\n    topicCreate(input: $input) {\n      topic {\n        ...TopicFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUserEditScreenQuery($id: ID!) {\n    node(id: $id) {\n      ... on User {\n        ...UserFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUserEditScreenQuery($id: ID!) {\n    node(id: $id) {\n      ... on User {\n        ...UserFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserMutation($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteUserMutation($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUserMutation($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsersScreenQuery(\n    $after: String\n    $query: String\n    $organizationId: GlobalIdInput\n    $sortBy: UserSortByInput\n    $role: Role\n  ) {\n    users(\n      first: 50\n      after: $after\n      query: $query\n      organizationId: $organizationId\n      sortBy: $sortBy\n      role: $role\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          email\n          fullName\n          role\n          organization {\n            id\n            name\n          }\n        }\n      }\n    }\n\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsersScreenQuery(\n    $after: String\n    $query: String\n    $organizationId: GlobalIdInput\n    $sortBy: UserSortByInput\n    $role: Role\n  ) {\n    users(\n      first: 50\n      after: $after\n      query: $query\n      organizationId: $organizationId\n      sortBy: $sortBy\n      role: $role\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          email\n          fullName\n          role\n          organization {\n            id\n            name\n          }\n        }\n      }\n    }\n\n    organizations(first: 500) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUserMutation($input: UserCreateInput!) {\n    userCreate(input: $input) {\n      user {\n        ...UserFormFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query MyProfileScreenQuery {\n    viewer {\n      id\n      email\n      fullName\n    }\n  }\n"): (typeof documents)["\n  query MyProfileScreenQuery {\n    viewer {\n      id\n      email\n      fullName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateViewerMutation($input: ViewerUpdateInput!) {\n    viewerUpdate(input: $input) {\n      viewer {\n        id\n        email\n        fullName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateViewerMutation($input: ViewerUpdateInput!) {\n    viewerUpdate(input: $input) {\n      viewer {\n        id\n        email\n        fullName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterDetailScreen($id: GlobalIdInput!) {\n    newsletter(id: $id) {\n      id\n      name\n      description\n      audienceSize\n      audienceSizeBand\n      signUpUrl\n      sponsorshipsCount\n      currentlyTracking\n      verified\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      ...NewsletterTopics\n    }\n  }\n"): (typeof documents)["\n  query NewsletterDetailScreen($id: GlobalIdInput!) {\n    newsletter(id: $id) {\n      id\n      name\n      description\n      audienceSize\n      audienceSizeBand\n      signUpUrl\n      sponsorshipsCount\n      currentlyTracking\n      verified\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      ...NewsletterTopics\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...NewsletterDetailSponsorship\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...NewsletterDetailSponsorship\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NewsletterDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    sponsor {\n      id\n      sponsorName\n      slug\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n"): (typeof documents)["\n  fragment NewsletterDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    sponsor {\n      id\n      sponsorName\n      slug\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterSetsScreenQuery {\n    canCreateSponsorSearch {\n      value\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          ...NewsletterSetRow_newsletterSet\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterSetsScreenQuery {\n    canCreateSponsorSearch {\n      value\n    }\n    newsletterSets(first: 100) {\n      edges {\n        node {\n          id\n          ...NewsletterSetRow_newsletterSet\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NewsletterSetRow_newsletterSet on NewsletterSet {\n    id\n    name\n    newslettersCount\n  }\n"): (typeof documents)["\n  fragment NewsletterSetRow_newsletterSet on NewsletterSet {\n    id\n    name\n    newslettersCount\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation NewsletterSetDeleteMutation($id: ID!) {\n    newsletterSetDelete(input: { id: $id }) {\n      newsletterSet {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation NewsletterSetDeleteMutation($id: ID!) {\n    newsletterSetDelete(input: { id: $id }) {\n      newsletterSet {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewsletterSetDeleteQuery($newsletterSetId: ID!) {\n    node(id: $newsletterSetId) {\n      ... on NewsletterSet {\n        id\n        sponsorSearches {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewsletterSetDeleteQuery($newsletterSetId: ID!) {\n    node(id: $newsletterSetId) {\n      ... on NewsletterSet {\n        id\n        sponsorSearches {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NewslettersIndexScreen(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 48, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          allTopics {\n            id\n            name\n          }\n        }\n      }\n    }\n    newsletterSets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query NewslettersIndexScreen(\n    $after: String\n    $filters: NewsletterSearchInput\n  ) {\n    newsletters(first: 48, after: $after, filters: $filters) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          name\n          audienceSizeBand\n          allTopics {\n            id\n            name\n          }\n        }\n      }\n    }\n    newsletterSets(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SearchesScreen($after: String) {\n    canCreateSponsorSearch {\n      value\n    }\n    sponsorSearches(first: 50, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorSearchRow\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchesScreen($after: String) {\n    canCreateSponsorSearch {\n      value\n    }\n    sponsorSearches(first: 50, after: $after) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorSearchRow\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserAlertsFields on User {\n    id\n    canReceiveAlerts\n    sendNewSponsorAlertsDaily\n    sendNewSponsorAlertsWeekly\n    sendBounceAlertsWeekly\n  }\n"): (typeof documents)["\n  fragment UserAlertsFields on User {\n    id\n    canReceiveAlerts\n    sendNewSponsorAlertsDaily\n    sendNewSponsorAlertsWeekly\n    sendBounceAlertsWeekly\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserAlertsQuery {\n    viewer {\n      ...UserAlertsFields\n    }\n  }\n"): (typeof documents)["\n  query UserAlertsQuery {\n    viewer {\n      ...UserAlertsFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorSearchRow on SponsorSearch {\n    id\n    name\n\n    emailsDaily\n    emailsWeekly\n\n    canDownload {\n      value\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorSearchRow on SponsorSearch {\n    id\n    name\n\n    emailsDaily\n    emailsWeekly\n\n    canDownload {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteSponsorSearch($id: ID!) {\n    sponsorSearchDelete(input: { id: $id }) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSponsorSearch($id: ID!) {\n    sponsorSearchDelete(input: { id: $id }) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateSponsorSearch($input: SponsorSearchUpdateInput!) {\n    sponsorSearchUpdate(input: $input) {\n      sponsorSearch {\n        id\n        ...SponsorSearchRow\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSponsorSearch($input: SponsorSearchUpdateInput!) {\n    sponsorSearchUpdate(input: $input) {\n      sponsorSearch {\n        id\n        ...SponsorSearchRow\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SponsorSearchDownload($input: SponsorSearchDownloadInput!) {\n    sponsorSearchDownload(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation SponsorSearchDownload($input: SponsorSearchDownloadInput!) {\n    sponsorSearchDownload(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorDetailScreen($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      sponsorName\n      websiteForCompany\n      linkedInForCompany\n      aboutTextFromLinkedIn\n\n      facebookUrl\n      twitterUrl\n      instagramUrl\n      youtubeUrl\n      discordUrl\n      contactUrl\n\n      logo {\n        id\n        thumbnailUrl\n      }\n      sponsorContacts {\n        id\n        ...ContactFragment\n      }\n\n      generalSponsorContacts {\n        id\n        email\n      }\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      sponsorshipsCount\n      earliestSponsorshipDate\n      latestSponsorshipDate\n\n      ...SponsorIndustries\n    }\n  }\n"): (typeof documents)["\n  query SponsorDetailScreen($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      sponsorName\n      websiteForCompany\n      linkedInForCompany\n      aboutTextFromLinkedIn\n\n      facebookUrl\n      twitterUrl\n      instagramUrl\n      youtubeUrl\n      discordUrl\n      contactUrl\n\n      logo {\n        id\n        thumbnailUrl\n      }\n      sponsorContacts {\n        id\n        ...ContactFragment\n      }\n\n      generalSponsorContacts {\n        id\n        email\n      }\n\n      canSponsorships {\n        value\n      }\n\n      canAdditionalInformation {\n        value\n      }\n\n      sponsorshipsCount\n      earliestSponsorshipDate\n      latestSponsorshipDate\n\n      ...SponsorIndustries\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...SponsorDetailSponsorship\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorSponsorships(\n    $after: String\n    $newsletterIds: [GlobalIdInput!]\n    $sponsorIds: [GlobalIdInput!]\n    $placementDateMin: ISO8601Date\n    $placementDateMax: ISO8601Date\n  ) {\n    sponsorships(\n      first: 30\n      after: $after\n      filters: {\n        sponsorIds: $sponsorIds\n        newsletterIds: $newsletterIds\n        placementDateMin: $placementDateMin\n        placementDateMax: $placementDateMax\n      }\n    ) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          ...SponsorDetailSponsorship\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorDetailScreenChart($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      estimatedNewsletterSpendingByMonth {\n        estimatedSpending\n        month\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorDetailScreenChart($slug: String!) {\n    sponsor(slug: $slug) {\n      id\n\n      estimatedNewsletterSpendingByMonth {\n        estimatedSpending\n        month\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query SponsorDetailScreenRelated($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      verified\n      relatedSponsors(first: 25, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n        edges {\n          node {\n            id\n            ...SponsorCard\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SponsorDetailScreenRelated($slug: String!, $after: String) {\n    sponsor(slug: $slug) {\n      id\n      verified\n      relatedSponsors(first: 25, after: $after) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n        }\n        edges {\n          node {\n            id\n            ...SponsorCard\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ContactFragment on SponsorContact {\n    id\n    firstName\n    lastName\n    email\n    title\n    linkedIn\n  }\n"): (typeof documents)["\n  fragment ContactFragment on SponsorContact {\n    id\n    firstName\n    lastName\n    email\n    title\n    linkedIn\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    newsletter {\n      id\n      name\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n"): (typeof documents)["\n  fragment SponsorDetailSponsorship on Sponsorship {\n    id\n    placementDate\n    newsletter {\n      id\n      name\n    }\n    sponsorLandingPage\n    viewInBrowserLink\n    ...SponsorshipImages\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation BounceReport($input: BounceReportCreateInput!) {\n    bounceReportCreate(input: $input) {\n      bounceReport {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation BounceReport($input: BounceReportCreateInput!) {\n    bounceReportCreate(input: $input) {\n      bounceReport {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorIndustries on Sponsor {\n    industries(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SponsorIndustries on Sponsor {\n    industries(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LandingScreen(\n    $filters: SponsorSearchInput\n    $sponsorSearchId: ID\n    $after: String\n  ) {\n    sponsors(\n      first: 50\n      after: $after\n      filters: $filters\n      sponsorSearchId: $sponsorSearchId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorCard\n        }\n      }\n      totalCount\n    }\n\n    paidSponsorsCount(filters: $filters, sponsorSearchId: $sponsorSearchId)\n\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        ...SponsorSearchFilters\n      }\n    }\n\n    canViewTestimonials {\n      value\n    }\n    canViewWhoSponsorsNewslettersBanner {\n      value\n    }\n    canViewGetWholePictureBanner {\n      value\n    }\n  }\n"): (typeof documents)["\n  query LandingScreen(\n    $filters: SponsorSearchInput\n    $sponsorSearchId: ID\n    $after: String\n  ) {\n    sponsors(\n      first: 50\n      after: $after\n      filters: $filters\n      sponsorSearchId: $sponsorSearchId\n    ) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...SponsorCard\n        }\n      }\n      totalCount\n    }\n\n    paidSponsorsCount(filters: $filters, sponsorSearchId: $sponsorSearchId)\n\n    sponsorSearch: node(id: $sponsorSearchId) {\n      ... on SponsorSearch {\n        id\n        ...SponsorSearchFilters\n      }\n    }\n\n    canViewTestimonials {\n      value\n    }\n    canViewWhoSponsorsNewslettersBanner {\n      value\n    }\n    canViewGetWholePictureBanner {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment SponsorSearchFilters on SponsorSearch {\n    id\n\n    sponsorshipCreatedRelative\n    sponsorshipCreatedMax\n    sponsorshipCreatedMin\n    sponsorCreatedRelative\n    sponsorCreatedMax\n    sponsorCreatedMin\n    industryIds\n    name\n    newsletterIds\n    newsletterSetIds\n    sponsorIds\n    newsletterSizeBands\n    search\n    sortOrderDirection\n    sortOrderField\n    sponsorshipCountMin\n    topicIds\n    verified\n  }\n"): (typeof documents)["\n  fragment SponsorSearchFilters on SponsorSearch {\n    id\n\n    sponsorshipCreatedRelative\n    sponsorshipCreatedMax\n    sponsorshipCreatedMin\n    sponsorCreatedRelative\n    sponsorCreatedMax\n    sponsorCreatedMin\n    industryIds\n    name\n    newsletterIds\n    newsletterSetIds\n    sponsorIds\n    newsletterSizeBands\n    search\n    sortOrderDirection\n    sortOrderField\n    sponsorshipCountMin\n    topicIds\n    verified\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query LandingScreenFilters {\n    canFilterSponsors {\n      value\n    }\n    canCreateSponsorSearch {\n      value\n    }\n    canShowDownloadSponsorSearch {\n      value\n    }\n  }\n"): (typeof documents)["\n  query LandingScreenFilters {\n    canFilterSponsors {\n      value\n    }\n    canCreateSponsorSearch {\n      value\n    }\n    canShowDownloadSponsorSearch {\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SaveSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SaveSearch($input: SponsorSearchCreateInput!) {\n    sponsorSearchCreate(input: $input) {\n      sponsorSearch {\n        id\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;