import { useCallback, useState } from "react"
import toast from "react-hot-toast"
import { deviseDestroySessionPath } from "~/common/paths"
import { request } from "~/common/request"

export const useSignout = () => {
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle")
  const execute = useCallback(async () => {
    setStatus("loading")
    const [status, response] = await request(deviseDestroySessionPath({}), {
      method: "DELETE",
    })

    if (status === "success") {
      window.location.replace("/")
      setStatus("success")
    } else {
      let errorMessage = response.error || response.message
      toast.error(errorMessage)
      setStatus("error")
    }
  }, [])

  return { execute, status }
}
