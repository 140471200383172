import { gql, useQuery } from "@apollo/client"
import { XIcon } from "lucide-react"
import { useMemo } from "react"
import { salesProUrl } from "~/common/paths"
import { Button } from "~/components/ui/button"
import { Card, CardContent } from "~/components/ui/card"
import { LinkButton } from "~/components/ui/link"

const upgradeBannerQuery = gql(/* GraphQL */ `
  query LandingScreenUpgradeBanner {
    totalVerifiedSponsorsCount
    canViewUpgradeBanner {
      value
    }
  }
`)

const UpgradeBanner = ({ onClose }: { onClose: () => void }) => {
  const result = useQuery(upgradeBannerQuery)

  const formattedCount = useMemo(() => {
    if (!result.data) return "over 6,000"
    return new Intl.NumberFormat().format(
      result.data.totalVerifiedSponsorsCount
    )
  }, [result.data])

  if (!result.data?.canViewUpgradeBanner.value) {
    return null
  }

  return (
    <div className="sticky z-10 text-center bottom-0 mt-3">
      <Card className="mx-auto max-w-3xl mb-10 bg-yellow text-yellow-foreground border-none relative opacity-90">
        <CardContent className="flex flex-col md:flex-row py-4 px-6 pr-10 items-center">
          <div className="flex-shrink max-w-lg text-center md:text-left">
            As a free user you are limited to browsing a small sample of our
            database. Upgrade to access our full catalog of {formattedCount}{" "}
            sponsors.
          </div>
          <LinkButton
            className="md:ml-auto mt-3 md:mt-0"
            variant="black"
            to={salesProUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Sales Pro
          </LinkButton>
          <div className="absolute right-1 top-1">
            <Button className="p-1" variant="ghost" onClick={onClose}>
              <XIcon />
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default UpgradeBanner
