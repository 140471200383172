import { DialogClose } from "@radix-ui/react-dialog"
import { Button } from "./button"
import { Dialog, DialogContent, DialogTrigger } from "./dialog"
import { MutationResult } from "@apollo/client"
import { GraphqlError } from "../errors/graph-error"

export const ConfirmDelete = (props: {
  onDelete: () => void
  result: MutationResult<any>
  message?: string
  messageSlot?: React.ReactNode
  buttonSlot?: React.ReactNode
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        {props.buttonSlot ?? (
          <Button type="button" variant="destructive" size="sm">
            Delete
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <div>
          {props.messageSlot ?? (
            <div className="mb-2">
              {props.message ?? "Are you sure you want to delete this record?"}
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <Button
            type="button"
            variant="destructive"
            onClick={props.onDelete}
            disabled={props.result.loading}
            isLoading={props.result.loading}
          >
            Delete
          </Button>
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
        </div>
        <GraphqlError error={props.result.error} />
      </DialogContent>
    </Dialog>
  )
}
