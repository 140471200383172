import { useMutation, useQuery } from "@apollo/client"
import { useId } from "@reach/auto-id"
import { useField, useFormikContext } from "formik"
import { ChevronsUpDown, Verified } from "lucide-react"
import * as React from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { isBlank } from "~/common/is-blank"
import { GraphqlError } from "~/components/errors/graph-error"
import { Button } from "~/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover"
import { SponsorForm, blankSponsor } from "../forms/sponsor-form"
import { Dialog, DialogContent } from "../ui/dialog"
import { MultiSelect } from "../ui/multi-select"
import { LabelWithClear } from "../ui/label-with-clear"
import { ScrollArea } from "../ui/scroll-area"

export type SponsorInputOption = {
  sponsorName: string
  id: string
}

type SponsorInputProps = {
  value: SponsorInputOption | null
  onValueChange: (value: SponsorInputOption | null) => void
}

const sponsorInputQuery = gql(/* GraphQL */ `
  query SponsorInputQuery($query: String!, $first: Int!, $after: String) {
    sponsors(
      first: $first
      after: $after
      filters: { search: $query, verified: null }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }

      edges {
        node {
          id
          sponsorName
          logo {
            id
            thumbnailUrl
          }
          verified
        }
      }
    }
  }
`)

const createSponsorMutation = gql(/* GraphQL */ `
  mutation CreateSponsorMutation($input: SponsorCreateInput!) {
    sponsorCreate(input: $input) {
      sponsor {
        id
        sponsorName
        logo {
          id
          thumbnailUrl
        }
        verified
      }
    }
  }
`)

export const SponsorInput = ({ value, onValueChange }: SponsorInputProps) => {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")

  const result = useQuery(sponsorInputQuery, {
    variables: { query: inputValue, first: 50, after: null },
  })

  const options =
    result.data?.sponsors.edges.map((e) => e.node) ??
    result.previousData?.sponsors.edges.map((e) => e.node) ??
    []

  const [createSponsor, createSponsorResult] = useMutation(
    createSponsorMutation,
    {}
  )

  const [dialogState, setDialogState] = React.useState<
    | {
        state: "sponsor"
        sponsorName: string
      }
    | { state: "closed" }
  >({ state: "closed" })

  const closeDialog = () => setDialogState({ state: "closed" })

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-[400px] max-w-full justify-between"
          >
            {value ? value.sponsorName : "Select sponsor…"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] max-w-full p-0">
          <Command shouldFilter={false}>
            <CommandInput
              placeholder="Search sponsors…"
              value={inputValue}
              onValueChange={(value) => setInputValue(value)}
            />
            <CommandEmpty>
              <div className="mb-2">No sponsor found.</div>
            </CommandEmpty>
            <ScrollArea className="h-[300px]">
              <CommandGroup>
                {options.map((option) => (
                  <CommandItem
                    key={option.id}
                    value={option.id}
                    onSelect={(currentValue) => {
                      let match = options.find(
                        (o) => o.id.toLowerCase().trim() === currentValue
                      )
                      invariant(match, "match should exist")

                      onValueChange(
                        value != null && currentValue === value.id
                          ? null
                          : match
                      )
                      setOpen(false)
                    }}
                  >
                    <div className="flex gap-2">
                      {option.logo ? (
                        <img
                          src={option.logo.thumbnailUrl}
                          className="object-cover"
                          alt=""
                          width={36}
                          height={36}
                        />
                      ) : null}
                      <div className="flex flex-col">
                        {option.sponsorName}
                        {option.verified ? <Verified size={14} /> : null}
                      </div>
                    </div>
                  </CommandItem>
                ))}
                {result.data?.sponsors.pageInfo.hasNextPage ? (
                  <CommandItem
                    disabled={result.loading}
                    onSelect={async () => {
                      result.fetchMore({
                        variables: {
                          after: result.data?.sponsors.pageInfo.endCursor,
                        },
                      })
                    }}
                  >
                    Load more
                  </CommandItem>
                ) : null}
                {inputValue.trim().length > 0 &&
                options.find(
                  (o) =>
                    o.sponsorName.toLowerCase() ===
                    inputValue.trim().toLowerCase()
                ) == null ? (
                  <CommandItem
                    onSelect={async () => {
                      setDialogState({
                        state: "sponsor",
                        sponsorName: inputValue,
                      })
                      setInputValue("")
                      setOpen(false)
                    }}
                    disabled={createSponsorResult.loading}
                  >
                    {createSponsorResult.loading ? (
                      <>Creating “{inputValue}”</>
                    ) : (
                      <>Create new sponsor “{inputValue}”</>
                    )}
                  </CommandItem>
                ) : null}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>

      <Dialog open={dialogState.state === "sponsor"} onOpenChange={closeDialog}>
        <DialogContent className="max-w-5xl">
          <div className="flex justify-between">
            <h2 className="text-xl">Create sponsor</h2>
          </div>
          <SponsorForm
            isLoading={createSponsorResult.loading}
            sponsor={{
              ...blankSponsor(),
              sponsorName:
                dialogState.state === "sponsor" ? dialogState.sponsorName : "",
            }}
            errors={createSponsorResult.error?.graphQLErrors}
            onSubmit={async (values) => {
              let sponsor = await createSponsor({
                variables: {
                  input: { sponsorInput: values },
                },
              })

              if (sponsor.errors) {
                return
              }

              invariant(sponsor.data, "sponsor.data should exist")
              onValueChange(sponsor.data.sponsorCreate.sponsor)
              closeDialog()
            }}
          />
        </DialogContent>
      </Dialog>
      {result.error ? <GraphqlError error={result.error} /> : null}
    </>
  )
}

const sponsorFieldQuery = gql(/* GraphQL */ `
  query SponsorField(
    $query: String
    $sponsorsAfter: String
    $selectedSponsorIds: [ID!]!
    $newsletterIds: [GlobalIdInput!]
    $first: Int!
  ) {
    selectedSponsors: nodes(ids: $selectedSponsorIds) {
      ... on Sponsor {
        id
        sponsorName
      }
    }

    sponsors(
      first: $first
      filters: { search: $query, newsletterIds: $newsletterIds }
      after: $sponsorsAfter
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          sponsorName
        }
      }
    }
  }
`)

export const SponsorField = ({
  label,
  newsletterId,
  placeholder,
  tooltip,
  ...props
}: {
  name: string
  label?: string
  id?: string
  newsletterId?: string
  placeholder?: string
  tooltip?: string
}) => {
  const [field] = useField(props)
  const formik = useFormikContext()
  const [search, setSearch] = React.useState("")

  const filterResult = useQuery(sponsorFieldQuery, {
    variables: {
      first: 50,
      query: isBlank(search) ? null : search,
      newsletterIds: newsletterId ? [newsletterId] : null,
      selectedSponsorIds: field.value,
      sponsorsAfter: null,
    },
  })

  const filterData = filterResult.data ?? filterResult.previousData

  const selectedSponsors =
    filterData?.selectedSponsors.map((n) => {
      invariant(n.__typename === "Sponsor")
      return n
    }) ?? []

  const sponsorOptions =
    filterData?.sponsors.edges
      .map((edge) => edge.node)
      .map((sponsor) => ({
        label: sponsor.sponsorName,
        value: sponsor.id,
      })) ?? []

  const autoId = useId()
  const id = props.id ?? autoId

  return (
    <div>
      <LabelWithClear
        id={id}
        label={label}
        clearable={field.value.length > 0}
        onClear={() => formik.setFieldValue(field.name, [])}
        tooltip={tooltip}
      />
      <div>
        <MultiSelect
          id={id}
          name={field.name}
          options={sponsorOptions}
          getLabel={(id: string) =>
            selectedSponsors.find(
              (n: { id: string; sponsorName: string }) => n.id === id
            )?.sponsorName ?? ""
          }
          selected={field.value}
          inputValue={search}
          onInputChange={(value) => setSearch(value)}
          onValuesChange={(ids) => {
            formik.setFieldValue(field.name, ids)
          }}
          shouldFilter={false}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}
