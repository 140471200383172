import { SponsorBounceStatus } from "~/__generated__/graphql"
import { Badge } from "./badge"
import { exhaustiveCheck } from "ts-exhaustive-check"

export const BounceBadge = ({
  ...props
}: {
  bounceStatus: SponsorBounceStatus
}) => {
  switch (props.bounceStatus) {
    case SponsorBounceStatus.ClearedBounces:
      return <Badge variant="default">Bounces cleared</Badge>
    case SponsorBounceStatus.HasBounces:
      return <Badge variant="destructive">Has Bounces</Badge>
    case SponsorBounceStatus.NoBounces:
      return <Badge variant="secondary">No bounces</Badge>
    default:
      exhaustiveCheck(props.bounceStatus)
  }
}
