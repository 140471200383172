import { useFormikContext } from "formik"
import React from "react"
import debounce from "just-debounce-it"

export const FormikAutoSave = ({ debounceMs }: { debounceMs: number }) => {
  const formik = useFormikContext()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [debounceMs, formik.submitForm]
  )

  React.useEffect(() => {
    if (formik.dirty) debouncedSubmit()
  }, [debouncedSubmit, formik.values, formik.dirty])

  return null
}
