import { FragmentType, getFragmentData, gql } from "~/__generated__"
import {
  adminBounceReportIndexPath,
  adminSponsorEditPath,
  adminUserEditPath,
} from "~/common/paths"
import { DateTime } from "~/components/ui/datetime"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "~/components/ui/hover-card"
import { Link, LinkButton } from "~/components/ui/link"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table"

const fragment = gql(/* GraphQL */ `
  fragment BounceReportTable on BounceReport {
    id
    createdAt
    message
    status
    reporter {
      id
      fullName
    }
    sponsor {
      id
      slug
      sponsorName
    }
    bounceClear {
      id
      createdAt
      user {
        id
        fullName
      }
    }
  }
`)

export const BounceReportsTable: React.FC<{
  bounceReports: Array<FragmentType<typeof fragment>>
  showResolvedFields: boolean
  showSponsorField?: boolean
}> = ({ showSponsorField = true, ...props }) => {
  const bounceReports = props.bounceReports.map((report) =>
    getFragmentData(fragment, report)
  )

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          {showSponsorField ? <TableHead>Sponsor</TableHead> : null}
          <TableHead>Reporter</TableHead>
          <TableHead>Message</TableHead>
          <TableHead>Status</TableHead>
          {props.showResolvedFields && (
            <>
              <TableHead>Cleared by</TableHead>
              <TableHead>Cleared on</TableHead>
            </>
          )}

          {showSponsorField ? <TableHead></TableHead> : null}
        </TableRow>
      </TableHeader>
      <TableBody>
        {bounceReports.map((record) => (
          <TableRow key={record.id}>
            <TableCell>
              <DateTime input={record.createdAt} />
            </TableCell>
            {showSponsorField ? (
              <TableCell>
                <Link to={adminSponsorEditPath({ slug: record.sponsor.slug })}>
                  {record.sponsor.sponsorName}
                </Link>
              </TableCell>
            ) : null}
            <TableCell>
              <Link to={adminUserEditPath({ userId: record.reporter.id })}>
                {record.reporter.fullName}
              </Link>
            </TableCell>
            <TableCell>
              <HoverCard>
                <div className="relative overflow-hidden truncate">
                  <HoverCardTrigger>{record.message}</HoverCardTrigger>
                </div>
                <HoverCardContent side="left" className="w-[500px]">
                  <div className="whitespace-pre-wrap">{record.message}</div>
                </HoverCardContent>
              </HoverCard>
            </TableCell>
            <TableCell className="font-mono">{record.status}</TableCell>
            {props.showResolvedFields && (
              <>
                <TableCell>
                  {record.bounceClear ? (
                    <Link
                      to={adminUserEditPath({
                        userId: record.bounceClear.user.id,
                      })}
                    >
                      {record.bounceClear.user.fullName}
                    </Link>
                  ) : null}
                </TableCell>
                <TableCell>
                  {record.bounceClear?.createdAt ? (
                    <DateTime input={record.bounceClear?.createdAt} />
                  ) : null}
                </TableCell>
              </>
            )}
            {showSponsorField ? (
              <TableCell>
                <LinkButton
                  to={
                    adminSponsorEditPath({ slug: record.sponsor.slug }) +
                    "?" +
                    new URLSearchParams({
                      returnTo: adminBounceReportIndexPath({}),
                    })
                  }
                  size="sm"
                >
                  Edit Sponsor
                </LinkButton>
              </TableCell>
            ) : null}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
