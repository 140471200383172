import React from "react"

export const FormPage: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <React.Fragment>{children}</React.Fragment>

export const FormPageTitle: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <h1 className="font-bold p-3 border-b flex items-center">{children}</h1>

export const FormPageBody: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="p-3 border-b">{children}</div>
