export function isBlank(
  input: string | null | undefined
): input is null | undefined {
  return !input || /^\s*$/.test(input)
}

/**
 * returns null if the input is blank, otherwise returns the input
 */
export function presence(input: string | null | undefined) {
  return isBlank(input) ? null : input
}
