import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { NewsletterSizeBand } from "~/__generated__/graphql"
import { Badge } from "~/components/ui/badge"

const newsletterTopicsFragment = gql(/* GraphQL */ `
  fragment NewsletterTopics on Newsletter {
    topics(first: 50) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export const NewsletterTopicsBadges = ({
  data,
}: {
  data: FragmentType<typeof newsletterTopicsFragment>
}) => {
  const newsletter = getFragmentData(newsletterTopicsFragment, data)
  const topics = newsletter.topics.edges.map((e) => e.node)
  return (
    <div className="flex flex-wrap gap-1">
      {topics.map((topic) => (
        <Badge key={topic.id}>{topic.name}</Badge>
      ))}
    </div>
  )
}
const newsletterSizeBandLabel = {
  [NewsletterSizeBand.Band_0_10k]: "0-10,000",
  [NewsletterSizeBand.Band_10k_50k]: "10,000-50,000",
  [NewsletterSizeBand.Band_50k_250k]: "50,000-250,000",
  [NewsletterSizeBand.Band_250k_1mm]: "250,000-1,000,000",
  [NewsletterSizeBand.Band_1mmPlus]: "1,000,000+",
}

export function formatNewsletterSizeBand(band: NewsletterSizeBand): string {
  return newsletterSizeBandLabel[band]
}

export const NewsletterAudienceSizeBand = ({
  ...props
}: {
  audienceSizeBand?: NewsletterSizeBand | null
}) => {
  if (props.audienceSizeBand == null) return null
  return (
    <Badge color="gray" {...props}>
      {formatNewsletterSizeBand(props.audienceSizeBand)}
    </Badge>
  )
}
