import { useQuery } from "@apollo/client"
import { DialogClose } from "@radix-ui/react-dialog"
import { arrayMoveImmutable } from "array-move"
import { Form, FormikProvider, useFormik } from "formik"
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  PlusCircle,
  Trash2,
  ZoomInIcon,
} from "lucide-react"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort"
import ReactImageGallery from "react-image-gallery"
import invariant from "tiny-invariant"
import * as Yup from "yup"
import { gql } from "~/__generated__"
import { UploadedImageFragment } from "~/__generated__/graphql"
import {
  FormikField,
  FormikValidationError,
} from "~/components/fields/formik-fields"
import {
  NewsletterInput,
  NewsletterInputOption,
} from "~/components/fields/newsletter-input"
import { Button } from "~/components/ui/button"
import { Card } from "~/components/ui/card"
import { Label } from "~/components/ui/label"
import { SelectItem } from "~/components/ui/select"
import { useUploadImage } from "~/hooks/use-upload"
import { SponsorInput, SponsorInputOption } from "../fields/sponsor-input"
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog"
import { Drop, DropMessage, IMAGE_ACCEPT_OPTIONS } from "../ui/drag-and-drop"

export const sponsorshipFormFieldsFragment = gql(/* GraphQL */ `
  fragment SponsorshipFormFields on Sponsorship {
    id
    sponsor {
      id
      sponsorName
    }
    newsletter {
      id
      name
    }
    placementDate
    sponsorLandingPage
    sponsorshipPlacement
    viewInBrowserLink
    images {
      id
      thumbnailUrl
      originalUrl
      width
      height
    }
  }
`)

const newsletterQuery = gql(/* GraphQL */ `
  query SponsorshipFormNewsletterQuery($newsletterId: ID!) {
    node(id: $newsletterId) {
      ... on Newsletter {
        id
        viewInBrowserAvailable
      }
    }
  }
`)

export const validationSchema = Yup.object({
  placementDate: Yup.date().required("Placement date is required"),
  sponsor: Yup.object().required("Sponsor is required"),
  newsletter: Yup.object().required("Newsletter is required"),
  viewInBrowserLinkMissing: Yup.boolean(),
  viewInBrowserLink: Yup.string().when("viewInBrowserLinkMissing", {
    is: false,
    then: (schema) =>
      schema
        .url("Must be a valid URL")
        .required(
          "View in browser link must be a URL or select 'Missing' below"
        ),
  }),
  sponsorLandingPageMissing: Yup.boolean(),
  sponsorLandingPage: Yup.string().when("sponsorLandingPageMissing", {
    is: false,
    then: (schema) =>
      schema
        .url("Must be a valid URL")
        .required(
          "Sponsor landing page must be a URL or select 'Missing' below"
        ),
  }),
  images: Yup.array().of(Yup.object()).min(1, "At least one image is required"),
})

export type SponsorshipFormFields = {
  placementDate: string
  sponsor: SponsorInputOption | null
  newsletter: NewsletterInputOption | null
  viewInBrowserLink: string
  viewInBrowserLinkMissing: boolean
  sponsorLandingPage: string
  sponsorLandingPageMissing: boolean
  sponsorshipPlacement: string
  images: UploadedImageFragment[]
  destination?: "add-another" | "exit"
}

export const SponsorshipForm = ({
  formik,
  isLoading: mutationIsLoading,
  showAddAnother,
}: {
  formik: ReturnType<typeof useFormik<SponsorshipFormFields>>
  isLoading: boolean
  showAddAnother: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { upload } = useUploadImage()

  const onDrop = useCallback(
    async (files: Array<File>) => {
      // Do something with the files
      setIsLoading(true)

      const imagePromises = [...files].map((file) => upload(file))
      const images = (await Promise.allSettled(imagePromises))
        .filter((x) => x.status === "fulfilled")
        .map((x) => {
          invariant("value" in x && x.value)
          return x.value
        })
      formik.setFieldValue("images", [...formik.values.images, ...images])
      setIsLoading(false)
    },
    [formik, upload]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: IMAGE_ACCEPT_OPTIONS,
    noClick: true,
  })

  const [warningOpen, setWarningOpen] = useState(false)

  const newsletterResult = useQuery(newsletterQuery, {
    ...(formik.values.newsletter?.id && {
      variables: {
        newsletterId: formik.values.newsletter.id,
      },
    }),
    skip: formik.values.newsletter?.id == null,
  })

  const newsletter = newsletterResult.data?.node
    ? newsletterResult.data.node.__typename === "Newsletter"
      ? newsletterResult.data.node
      : null
    : null

  const isViewInBrowserWarning =
    formik.values.viewInBrowserLinkMissing &&
    newsletter?.id === formik.values.newsletter?.id &&
    newsletter?.viewInBrowserAvailable === true

  const [galleryImages, setGalleryImages] = useState<
    | {
        isOpen: true
        imageIndex: number
      }
    | {
        isOpen: false
        imageIndex?: number
      }
  >({ isOpen: false })

  const closeGallery = () =>
    setGalleryImages((prev) => ({ ...prev, isOpen: false }))

  return (
    <FormikProvider value={formik}>
      <Form id="sponsorship-form">
        <div className="flex flex-col gap-4 mb-8">
          <FormikField
            name="placementDate"
            label="Placement Date"
            type="date"
            className="w-[400px] max-w-full"
          />
          <div className="flex flex-col gap-1">
            <Label>Newsletter</Label>
            <NewsletterInput
              value={formik.values.newsletter}
              onValueChange={(value) => {
                formik.setFieldValue("newsletter", value)
              }}
            />
            <FormikValidationError name="newsletter" />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Sponsor</Label>
            <SponsorInput
              value={formik.values.sponsor}
              onValueChange={(value) => {
                formik.setFieldValue("sponsor", value)
              }}
            />
            <FormikValidationError name="sponsor" />
          </div>
          <div className="flex flex-col gap-2">
            <FormikField
              name="viewInBrowserLink"
              label="View in browser link"
              type="url"
              value={
                formik.values.viewInBrowserLinkMissing
                  ? "NA"
                  : formik.values.viewInBrowserLink
              }
              disabled={formik.values.viewInBrowserLinkMissing}
              placeholder="https://"
            />
            <FormikField
              name="viewInBrowserLinkMissing"
              label="Missing"
              type="checkbox"
            />
          </div>
          <div className="flex flex-col gap-2">
            <FormikField
              name="sponsorLandingPage"
              label="Sponsor Landing Page"
              type="url"
              placeholder="https://"
              value={
                formik.values.sponsorLandingPageMissing
                  ? "NA"
                  : formik.values.sponsorLandingPage
              }
              disabled={formik.values.sponsorLandingPageMissing}
            />
            <FormikField
              name="sponsorLandingPageMissing"
              label="Missing"
              type="checkbox"
            />
          </div>

          <div className="w-[400px] max-w-full">
            <FormikField
              as="select"
              name="sponsorshipPlacement"
              label="Placement"
            >
              <SelectItem value="primary">Primary</SelectItem>
              <SelectItem value="secondary">Secondary</SelectItem>
            </FormikField>
          </div>

          <Dialog open={galleryImages.isOpen} onOpenChange={closeGallery}>
            <div {...getRootProps()}>
              <Label>Screenshot of sponsorship</Label>
              <Drop variant={isDragActive ? "dragging" : "default"}>
                <SortableList
                  onSortEnd={(oldIndex, newIndex) => {
                    formik.setFieldValue(
                      "images",
                      arrayMoveImmutable(
                        formik.values.images,
                        oldIndex,
                        newIndex
                      )
                    )
                  }}
                  className="grid grid-cols-6 gap-2 mb-3"
                  draggedItemClassName="dragged"
                >
                  {formik.values.images.map((image) => (
                    <SortableItem key={image.id}>
                      <Card key={image.id} className="relative">
                        <SortableKnob>
                          <div className="cursor-grab h-full">
                            <img
                              src={image.thumbnailUrl}
                              alt=""
                              width={250}
                              height={250}
                              className="object-cover select-none pointer-events-none"
                            />
                          </div>
                        </SortableKnob>
                        <div className="absolute right-0 top-0 me-2 mt-2 flex gap-1 z-10">
                          <Button
                            size="sm"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setGalleryImages({
                                isOpen: true,
                                imageIndex: formik.values.images.indexOf(image),
                              })
                            }}
                            variant="outline"
                          >
                            <ZoomInIcon />
                          </Button>
                          <Button
                            size="sm"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              formik.setFieldValue("images", [
                                ...formik.values.images.filter(
                                  (i) => i.id !== image.id
                                ),
                              ])
                            }}
                            type="button"
                            aria-label="Delete"
                            variant="destructive"
                          >
                            <Trash2 />
                          </Button>
                        </div>
                      </Card>
                    </SortableItem>
                  ))}
                </SortableList>

                <DialogContent className="max-w-5xl pt-10">
                  <ReactImageGallery
                    startIndex={galleryImages.imageIndex}
                    items={
                      formik.values.images.map((i) => ({
                        original: i.originalUrl,
                        thumbnail: i.thumbnailUrl,
                        originalWidth: i.width ?? undefined,
                        originalHeight: i.height ?? undefined,
                      })) ?? []
                    }
                    showPlayButton={false}
                    slideDuration={0}
                    showFullscreenButton={false}
                    renderLeftNav={(onClick, disabled) => (
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onClick(e)
                        }}
                        disabled={disabled}
                        size="icon"
                        variant="ghost"
                        className="image-gallery-left-nav absolute z-10 hover:bg-transparent"
                      >
                        <ArrowLeftCircle />
                      </Button>
                    )}
                    renderRightNav={(onClick, disabled) => (
                      <Button
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          onClick(e)
                        }}
                        disabled={disabled}
                        size="icon"
                        variant="ghost"
                        className="image-gallery-right-nav absolute z-10 hover:bg-transparent"
                      >
                        <ArrowRightCircle />
                      </Button>
                    )}
                  />
                </DialogContent>

                <input {...getInputProps()} />
                <DropMessage open={open} isLoading={isLoading} />

                <FormikValidationError name="images" />
              </Drop>
            </div>
          </Dialog>

          <div className="grid gap-2 grid-cols-2 max-w-lg">
            {showAddAnother ? (
              <Button
                type="submit"
                disabled={isLoading || mutationIsLoading}
                isLoading={mutationIsLoading}
                name="destination"
                value="add-another"
                onClick={() =>
                  formik.setFieldValue("destination", "add-another")
                }
              >
                <PlusCircle className="me-2" />
                Save and add another
              </Button>
            ) : null}
            {isViewInBrowserWarning ? (
              <Dialog open={warningOpen} onOpenChange={setWarningOpen}>
                <DialogTrigger asChild>
                  <Button variant={showAddAnother ? "secondary" : "default"}>
                    Save
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <div className="text-center py-4">
                    This newsletter usually does have a View in Browser link.
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <Button
                      type="submit"
                      disabled={isLoading || mutationIsLoading}
                      isLoading={mutationIsLoading}
                      name="destination"
                      form="sponsorship-form"
                      value="exit"
                      onClick={() => {
                        formik.setFieldValue("destination", "exit")
                        setWarningOpen(false)
                      }}
                    >
                      Confirm missing
                    </Button>
                    <DialogClose asChild>
                      <Button variant="outline">Cancel</Button>
                    </DialogClose>
                  </div>
                </DialogContent>
              </Dialog>
            ) : (
              <Button
                type="submit"
                disabled={isLoading || mutationIsLoading}
                isLoading={mutationIsLoading}
                variant={showAddAnother ? "secondary" : "default"}
                name="destination"
                value="exit"
                onClick={() => formik.setFieldValue("destination", "exit")}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}
