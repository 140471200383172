import { useState } from "react"
import { Dialog, DialogContent } from "../ui/dialog"
import ImageGallery from "react-image-gallery"
import { Button } from "../ui/button"
import { ArrowLeftCircle, ArrowRightCircle, ZoomIn } from "lucide-react"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { cn } from "~/lib/utils"

export const sponsorshipImagesFragment = gql(/* GraphQL */ `
  fragment SponsorshipImages on Sponsorship {
    images {
      id
      thumbnailUrl
      originalUrl
      width
      height
    }
  }
`)

export const SponsorshipImages: React.FC<{
  sponsorship: FragmentType<typeof sponsorshipImagesFragment>
  containerClassName?: string
  imageClassName?: string
  imageSize?: 50 | 100
}> = ({ imageSize = 100, ...props }) => {
  const images = getFragmentData(
    sponsorshipImagesFragment,
    props.sponsorship
  ).images

  const [galleryImages, setGalleryImages] = useState<
    | {
        isOpen: true
        imageIndex: number
      }
    | {
        isOpen: false
        imageIndex?: number
      }
  >({ isOpen: false })

  const closeGallery = () =>
    setGalleryImages((prev) => ({ ...prev, isOpen: false }))

  return (
    <Dialog open={galleryImages.isOpen} onOpenChange={closeGallery}>
      <div
        className={
          props.containerClassName ?? "flex gap-2 w-[324px] overflow-auto"
        }
      >
        {images.map((image, index) => (
          <button
            key={image.id}
            onClick={() =>
              setGalleryImages({
                isOpen: true,
                imageIndex: index,
              })
            }
            className="flex-shrink-0 inline-flex"
          >
            <img
              key={image.id}
              src={image.thumbnailUrl}
              className={cn(props.imageClassName ?? "object-contain border", {
                "w-[50px] h-[50px]": imageSize === 50,
                "w-[100px] h-[100px]": imageSize === 100,
              })}
              width={imageSize}
              height={imageSize}
              alt=""
            />
          </button>
        ))}
      </div>

      <DialogContent className="max-w-5xl pt-10">
        <ImageGallery
          startIndex={galleryImages.imageIndex}
          items={
            images.map((i) => ({
              original: i.originalUrl,
              thumbnail: i.thumbnailUrl,
              originalWidth: i.width ?? undefined,
              originalHeight: i.height ?? undefined,
            })) ?? []
          }
          showPlayButton={false}
          renderFullscreenButton={(onClick, disabled) => (
            <Button
              onClick={onClick}
              disabled={disabled}
              size="icon"
              variant="ghost"
              className="absolute z-10 hover:bg-transparent right-0 bottom-0"
            >
              <ZoomIn />
            </Button>
          )}
          renderLeftNav={(onClick, disabled) => (
            <Button
              onClick={onClick}
              disabled={disabled}
              size="icon"
              variant="ghost"
              className="image-gallery-left-nav absolute z-10 hover:bg-transparent"
            >
              <ArrowLeftCircle />
            </Button>
          )}
          renderRightNav={(onClick, disabled) => (
            <Button
              onClick={onClick}
              disabled={disabled}
              size="icon"
              variant="ghost"
              className="image-gallery-right-nav absolute z-10 hover:bg-transparent"
            >
              <ArrowRightCircle />
            </Button>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}
