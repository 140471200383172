import {
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip"
import { TooltipRoot } from "./tooltip"

const TooltipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="M8 7.167a.5.5 0 01.5.5V11a.5.5 0 11-1 0V7.667a.5.5 0 01.5-.5zM8 6a.667.667 0 100-1.333A.667.667 0 008 6z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.167 8a5.833 5.833 0 1111.666 0A5.833 5.833 0 012.167 8zM8 3.167a4.833 4.833 0 100 9.666 4.833 4.833 0 000-9.666z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export const LabelTooltip = ({ text }: { text: string }) => {
  return (
    <TooltipProvider delayDuration={300}>
      <TooltipRoot>
        <TooltipTrigger asChild={true}>
          <button type="button">
            <TooltipIcon />
          </button>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            sideOffset={5}
            side="right"
            className="z-[100] bg-white text-sm rounded-lg px-4 py-3 border text-gray-500 border-[##CBD5E1]"
          >
            {text}
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  )
}
