import { useEffect } from "react"
import { createBrowserRouter, redirect, useRouteError } from "react-router-dom"
import * as paths from "~/common/paths"
import { Header } from "./components/ui/header"
import { LayoutScreen } from "./screens/layout-screen"
import { SponsorsIndexScreen } from "./screens/sponsors-index-screen"

function ErrorBoundary() {
  let error = useRouteError()

  useEffect(() => {
    if (
      (error as Error)?.message?.includes(
        "Failed to fetch dynamically imported module"
      ) &&
      !window.location.href.includes("reload=1")
    ) {
      if (window.location.href.includes("?")) {
        window.location.href = window.location.href + "&reload=1"
      } else {
        window.location.href = window.location.href + "?reload=1"
      }
      return
    }
  }, [error])

  return (
    <>
      <Header />
      <div className="max-w-[600px] w-full mx-auto p-4">
        <div className="border-red-500 border rounded p-3 text-red-600">
          <div className="mb-5">
            <div className="text-lg font-bold">An error occured</div>
            <div>
              <a
                href="mailto:hello@whosponsorsstuff.com"
                className="underline text-primary "
              >
                Report an issue
              </a>
            </div>
            <a href="/" className="underline text-primary ">
              Go back to the home page
            </a>
          </div>
          <div>Error details:</div>
          <div className="font-mono">
            {(error ? (error as any).message : null) || "Unknown error"}
          </div>
        </div>
      </div>
    </>
  )
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutScreen />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: paths.landingPath.pattern,
        element: <SponsorsIndexScreen />,
      },
      {
        path: paths.newslettersPath.pattern,
        lazy: async () => {
          let { NewslettersIndexScreen: Component } = await import(
            "./screens/newsletters-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.newsletterDetailPath.pattern,
        lazy: async () => {
          let { NewsletterDetailScreen: Component } = await import(
            "./screens/newsletter-detail-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.logInPath.pattern,
        lazy: async () => {
          let { LogInScreen: Component } = await import(
            "./screens/login-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.forgotPasswordPath.pattern,
        lazy: async () => {
          let { ForgotPasswordScreen: Component } = await import(
            "./screens/forgot-password-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.resetPasswordPath.pattern,
        lazy: async () => {
          let { ResetPasswordScreen: Component } = await import(
            "./screens/reset-password-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.registrationPath.pattern,
        lazy: async () => {
          let { RegistrationScreen: Component } = await import(
            "./screens/registration-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.sponsorDetailPath.pattern,
        lazy: async () => {
          let { SponsorDetailScreen: Component } = await import(
            "./screens/sponsor-detail-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.myProfilePath.pattern,
        lazy: async () => {
          let { MyProfileScreen: Component } = await import(
            "./screens/my-profile-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsPath.pattern,
        lazy: async () => {
          let { TopSponsorsScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInAIPath.pattern,
        lazy: async () => {
          let { TopSponsorsAIScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-ai-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInB2BPath.pattern,
        lazy: async () => {
          let { TopSponsorsB2BScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-b2b-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInCryptoAndWeb3Path.pattern,
        lazy: async () => {
          let { TopSponsorsCryptoWeb3Screen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-crypto-web3-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInEducationPath.pattern,
        lazy: async () => {
          let { TopSponsorsEducationScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-education-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInHealthAndFitnessPath.pattern,
        lazy: async () => {
          let { TopSponsorsHealthFitnessScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-health-fitness-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInInvestingPath.pattern,
        lazy: async () => {
          let { TopSponsorsInvestingScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-investing-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInMarketingPath.pattern,
        lazy: async () => {
          let { TopSponsorsMarketingScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-marketing-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInSoftwarePath.pattern,
        lazy: async () => {
          let { TopSponsorsSoftwareScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-software-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInSportsPath.pattern,
        lazy: async () => {
          let { TopSponsorsSportsScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-sports-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInMensInterestPath.pattern,
        lazy: async () => {
          let { TopSponsorsMensInterestScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-mens-interest-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsInWomensInterestPath.pattern,
        lazy: async () => {
          let { TopSponsorsWomensInterestScreen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-womens-interest-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsIn2021Path.pattern,
        lazy: async () => {
          let { TopSponsors2021Screen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-2021-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsIn2022Path.pattern,
        lazy: async () => {
          let { TopSponsors2022Screen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-2022-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.topSponsorsIn2023Path.pattern,
        lazy: async () => {
          let { TopSponsors2023Screen: Component } = await import(
            "./screens/top-sponsors/top-sponsors-2023-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.searchesPath.pattern,
        lazy: async () => {
          let { SearchesScreen: Component } = await import(
            "./screens/searches-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.newsletterSetsPath.pattern,
        lazy: async () => {
          let { NewsletterSetsScreen: Component } = await import(
            "./screens/newsletter-sets-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.todoPath.pattern,
        lazy: async () => {
          let { TodoScreen: Component } = await import("./screens/todo-screen")
          return { Component }
        },
      },
    ],
  },
  {
    path: paths.adminPath.pattern,
    lazy: async () => {
      let { AdminLayoutScreen: Component } = await import(
        "./screens/admin-layout-screen"
      )
      return { Component }
    },
    children: [
      {
        path: paths.adminTodoPath.pattern,
        lazy: async () => {
          let { TodoScreen: Component } = await import("./screens/todo-screen")
          return { Component }
        },
      },
      {
        path: paths.adminDashboardPath.pattern,
        loader: () => {
          return redirect(paths.adminSponsorshipIndexPath({}))
        },
      },
      {
        path: paths.adminUserIndexPath.pattern,
        lazy: async () => {
          let { AdminUserIndexScreen: Component } = await import(
            "./screens/admin-user-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminUserNewPath.pattern,
        lazy: async () => {
          let { AdminUserNewScreen: Component } = await import(
            "./screens/admin-user-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminUserEditPath.pattern,
        lazy: async () => {
          let { AdminUserEditScreen: Component } = await import(
            "./screens/admin-user-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminTopicIndexPath.pattern,
        lazy: async () => {
          let { AdminTopicIndexScreen: Component } = await import(
            "./screens/admin-topic-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminTopicNewPath.pattern,
        lazy: async () => {
          let { AdminTopicNewScreen: Component } = await import(
            "./screens/admin-topic-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminTopicEditPath.pattern,
        lazy: async () => {
          let { AdminTopicEditScreen: Component } = await import(
            "./screens/admin-topic-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorIndexPath.pattern,
        lazy: async () => {
          let { AdminSponsorIndexScreen: Component } = await import(
            "./screens/admin-sponsor-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorNewPath.pattern,
        lazy: async () => {
          let { AdminSponsorNewScreen: Component } = await import(
            "./screens/admin-sponsor-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorEditPath.pattern,
        lazy: async () => {
          let { AdminSponsorEditScreen: Component } = await import(
            "./screens/admin-sponsor-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorNewPath.pattern,
        lazy: async () => {
          let { AdminSponsorNewScreen: Component } = await import(
            "./screens/admin-sponsor-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorshipIndexPath.pattern,
        lazy: async () => {
          let { AdminSponsorshipIndexScreen: Component } = await import(
            "./screens/admin-sponsorship-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorshipNewPath.pattern,
        lazy: async () => {
          let { AdminSponsorshipNewScreen: Component } = await import(
            "./screens/admin-sponsorship-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminNewsletterIndexPath.pattern,
        lazy: async () => {
          let { AdminNewsletterIndexScreen: Component } = await import(
            "./screens/admin-newsletter-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminNewsletterEditPath.pattern,
        lazy: async () => {
          let { AdminNewsletterEditScreen: Component } = await import(
            "./screens/admin-newsletter-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminNewsletterNewPath.pattern,
        lazy: async () => {
          let { AdminNewsletterNewScreen: Component } = await import(
            "./screens/admin-newsletter-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminSponsorshipEditPath.pattern,
        lazy: async () => {
          let { AdminSponsorshipEditScreen: Component } = await import(
            "./screens/admin-sponsorship-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminIndustryIndexPath.pattern,
        lazy: async () => {
          let { AdminIndustryIndexScreen: Component } = await import(
            "./screens/admin-industry-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminIndustryNewPath.pattern,
        lazy: async () => {
          let { AdminIndustryNewScreen: Component } = await import(
            "./screens/admin-industry-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminIndustryEditPath.pattern,
        lazy: async () => {
          let { AdminIndustryEditScreen: Component } = await import(
            "./screens/admin-industry-edit-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminBounceReportIndexPath.pattern,
        lazy: async () => {
          let { AdminBounceReportIndexScreen: Component } = await import(
            "./screens/admin-bounce-report-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminOrganizationIndexPath.pattern,
        lazy: async () => {
          let { AdminOrganizationIndexScreen: Component } = await import(
            "./screens/admin-organization-index-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminOrganizationNewPath.pattern,
        lazy: async () => {
          let { AdminOrganizationNewScreen: Component } = await import(
            "./screens/admin-organization-new-screen"
          )
          return { Component }
        },
      },
      {
        path: paths.adminOrganizationEditPath.pattern,
        lazy: async () => {
          let { AdminOrganizationEditScreen: Component } = await import(
            "./screens/admin-organization-edit-screen"
          )
          return { Component }
        },
      },
    ],
  },
])
