// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path

import { path } from "static-path"

export const deviseSessionCreatePath = path("/users/sign_in")
export const deviseRegistrationCreatePath = path("/users")
export const deviseDestroySessionPath = path("/users/sign_out")
export const devisePasswordCreatePath = path("/users/password")
export const devisePasswordEditPath = path("/users/password/edit")

export const impersonateUserPath = path("/users/:id/impersonate")

export const landingPath = path("/")
export const examplePath = path("/example/:exampleId")
export const registrationPath = path("/sign-up")
export const forgotPasswordPath = path("/forgot-password")
export const resetPasswordPath = path("/reset-password")
export const logInPath = path("/login")
export const newsletterDetailPath = path("/newsletters/:newsletterId")
export const newslettersPath = path("/newsletters")
export const sponsorDetailPath = path("/sponsors/:sponsorSlug")
export const myProfilePath = path("/my-profile")

export const topSponsorsPath = path("/top-sponsors")
export const topSponsorsInAIPath = path("/top-sponsors-ai")
export const topSponsorsInB2BPath = path("/top-sponsors-b2b")
export const topSponsorsInCryptoAndWeb3Path = path("/top-sponsors-crypto-web3")
export const topSponsorsInEducationPath = path("/top-sponsors-education")
export const topSponsorsInHealthAndFitnessPath = path(
  "/top-sponsors-health-fitness"
)
export const topSponsorsInInvestingPath = path("/top-sponsors-investing")
export const topSponsorsInMarketingPath = path("/top-sponsors-marketing")
export const topSponsorsInSoftwarePath = path("/top-sponsors-software")
export const topSponsorsInSportsPath = path("/top-sponsors-sports")
export const topSponsorsInMensInterestPath = path("/top-sponsors-mens-interest")
export const topSponsorsInWomensInterestPath = path(
  "/top-sponsors-womens-interest"
)
export const topSponsorsIn2021Path = path("/top-100-sponsors-of-2021")
export const topSponsorsIn2022Path = path("/top-100-sponsors-of-2022")
export const topSponsorsIn2023Path = path("/top-100-sponsors-of-2023")
export const searchesPath = path("/searches")
export const newsletterSetsPath = path("/newsletter-sets")

/** @deprecated */
export const todoPath = path("/todo")

export const adminPath = path("/admin")
export const adminDashboardPath = path("/admin")
export const adminUserIndexPath = path("/admin/users")
export const adminUserNewPath = path("/admin/users/new")
export const adminUserEditPath = path("/admin/users/:userId/edit")
export const adminSponsorIndexPath = path("/admin/sponsors")
export const adminSponsorNewPath = path("/admin/sponsors/new")
export const adminSponsorEditPath = path("/admin/sponsors/:slug/edit")
export const adminTopicIndexPath = path("/admin/topics")
export const adminTopicEditPath = path("/admin/topics/:topicId/edit")
export const adminTopicNewPath = path("/admin/topics/new")
export const adminTodoPath = path("/admin/todo")
export const adminSponsorshipIndexPath = path("/admin/sponsorships")
export const adminSponsorshipNewPath = path("/admin/sponsorships/new")
export const adminNewsletterIndexPath = path("/admin/newsletters")
export const adminNewsletterEditPath = path(
  "/admin/newsletters/:newsletterId/edit"
)
export const adminNewsletterNewPath = path("/admin/newsletters/new")
export const adminSponsorshipEditPath = path(
  "/admin/sponsorships/:sponsorshipId/edit"
)

export const adminIndustryIndexPath = path("/admin/industries")
export const adminIndustryNewPath = path("/admin/industries/new")
export const adminIndustryEditPath = path("/admin/industries/:industryId/edit")

export const adminBounceReportIndexPath = path("/admin/bounces")

export const adminOrganizationIndexPath = path("/admin/organizations")
export const adminOrganizationNewPath = path("/admin/organizations/new")
export const adminOrganizationEditPath = path("/admin/organizations/:id/edit")

export const salesProUrl = "https://site.whosponsorsstuff.com/sales-pro/"

export function searchPath(sponsorSearchId: string) {
  return (
    landingPath({}) +
    "?" +
    new URLSearchParams({ sponsorSearch: sponsorSearchId })
  )
}
