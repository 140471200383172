import { cn } from "~/lib/utils"
import { Button } from "./button"
import { Label } from "./label"
import { LabelTooltip } from "./label-tooltip"

export const LabelWithClear = ({
  id,
  label,
  clearable,
  onClear,
  tooltip,
  className,
}: {
  id?: string
  label?: string
  clearable: boolean
  onClear: () => void
  tooltip?: string
  className?: string
}) => {
  if (!label) {
    return null
  }

  return (
    <div
      className={cn(
        "flex justify-between items-center pb-2 h-8 flex-1",
        className
      )}
    >
      <Label htmlFor={id} className="pb-0 flex gap-1 items-center">
        {label}
        {tooltip && (
          <div className="z-10 inline">
            <LabelTooltip text={tooltip} />
          </div>
        )}
      </Label>
      {clearable && (
        <Button
          variant="ghost"
          size="unpadded"
          onClick={onClear}
          className="text-sm underline hover:bg-inherit hover:text-inherit"
          type="button"
        >
          Clear
        </Button>
      )}
    </div>
  )
}
