import { VariantProps, cva } from "class-variance-authority"
import {
  NavLink,
  NavLinkProps,
  Link as RRLink,
  LinkProps as RRLinkProps,
} from "react-router-dom"
import { cn } from "~/lib/utils"
import { buttonVariants } from "./button"

const linkVariants = cva("", {
  variants: {
    variant: {
      default: "text-secondary hover:text-secondary/80",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

type BaseLinkProps = {} & RRLinkProps & React.RefAttributes<HTMLAnchorElement>

type LinkProps = BaseLinkProps & VariantProps<typeof linkVariants>

export const Link = ({ variant, className, ...props }: LinkProps) => {
  return (
    <RRLink {...props} className={cn(linkVariants({ variant, className }))} />
  )
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof linkVariants>

export const Anchor = ({
  children,
  variant,
  className,
  ...props
}: AnchorProps) => {
  return (
    <a
      {...props}
      className={cn(linkVariants({ variant, className }))}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

type LinkButtonProps = VariantProps<typeof buttonVariants> & BaseLinkProps
export const LinkButton = ({ ...props }: LinkButtonProps) => {
  const className = buttonVariants({
    variant: props.variant,
    size: props.size,
  })
  return <RRLink {...props} className={cn(className, props.className)} />
}

type NavLinkButtonProps = VariantProps<
  typeof buttonVariants
> & {} & NavLinkProps &
  React.RefAttributes<HTMLAnchorElement>
export const NavLinkButton = ({ ...props }: NavLinkButtonProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) => {
        const className = buttonVariants({
          variant: props.variant,
          size: props.size,
        })

        return cn(className, props.className, {
          "text-gray-500 font-normal": !isActive,
        })
      }}
    />
  )
}

type AnchorButtonProps = VariantProps<typeof buttonVariants> &
  React.AnchorHTMLAttributes<HTMLAnchorElement>
export const AnchorButton = ({ ...props }: AnchorButtonProps) => {
  const className = buttonVariants({
    variant: props.variant,
    size: props.size,
    className: props.className,
  })
  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      className={className}
    />
  )
}
