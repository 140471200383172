import { forwardRef } from "react"
import TextareaAutosize from "react-autosize-textarea"

import { cn } from "~/lib/utils"

export interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "css"> {
  /**
   * Enable autosizing of the textarea.
   */
  autosize?: boolean
  /**
   * Max number of rows to default to.
   */
  maxRows?: number
  /**
   * Number of rows to default to.
   */
  rows?: number
}

const Textarea = forwardRef(function Textarea(
  { autosize, rows, maxRows, className, ...p }: TextAreaProps,
  ref: React.Ref<HTMLTextAreaElement>
) {
  return autosize ? (
    // @ts-expect-error bad types
    <TextareaAutosize
      {...p}
      async
      ref={ref}
      rows={rows ? rows : 2}
      maxRows={maxRows}
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
    />
  ) : (
    <textarea
      ref={ref}
      {...p}
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
    />
  )
})

Textarea.displayName = "Textarea"

export { Textarea }
