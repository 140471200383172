import { registrationPath } from "~/common/paths"
import { LinkButton } from "~/components/ui/link"

export const WhoSponsorsNewslettersBanner = () => {
  return (
    <div className="bg-green text-green-foreground mt-4 md:mt-10">
      <div className="flex flex-col gap-6 p-10 max-w-page mx-auto text-center">
        <h2 className="text-3xl font-semibold">Who’s sponsoring newsletters</h2>
        <p>
          Sign up free to browse the databse and receive weekly intelligence on
          email sponsors
        </p>
        <div>
          <LinkButton to={registrationPath({})}>SIGN UP FREE</LinkButton>
        </div>
      </div>
    </div>
  )
}
