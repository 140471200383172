import * as Yup from "yup"
import { getFragmentData, gql } from "~/__generated__"

import { useMutation, useQuery } from "@apollo/client"
import { GraphQLErrors } from "@apollo/client/errors"
import { Form, FormikProvider, useFormik } from "formik"
import { Trash, Trash2 } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import toast from "react-hot-toast"
import { useNavigate, useSearchParams } from "react-router-dom"
import invariant from "tiny-invariant"
import {
  SponsorBounceStatus,
  SponsorFormFieldsFragment,
  SponsorInput,
  UploadedImageFragment,
} from "~/__generated__/graphql"
import { formatDate } from "~/common/dates"
import { isBlank } from "~/common/is-blank"
import { displayErrors } from "~/common/validation-errors"
import {
  FormikField,
  FormikMultiSelectWithPinField,
} from "~/components/fields/formik-fields"
import { Button } from "~/components/ui/button"
import { useUploadImage } from "~/hooks/use-upload"
import {
  adminSponsorEditQuery,
  sponsorEditMutation,
} from "~/screens/admin-sponsor-edit-screen"
import { GraphqlError } from "../errors/graph-error"
import { FormikAutoSave } from "../fields/formik-auto-save"
import { AdminSponsorshipTable } from "../sponsorships/admin-sponsorship-table"
import { BounceBadge } from "../ui/bounce-badge"
import { Card, CardContent, CardFooter } from "../ui/card"
import { ConfirmDelete } from "../ui/confirm-delete"
import { Drop, DropMessage, IMAGE_ACCEPT_OPTIONS } from "../ui/drag-and-drop"
import { FormPageBody, FormPageTitle } from "../ui/form-page"
import { Label } from "../ui/label"
import {
  LoadingIndicator,
  LoadingIndicatorCentered,
} from "../ui/loading-indicator"

export const sponsorFormFragment = gql(/* GraphQL */ `
  fragment SponsorFormFields on Sponsor {
    id
    sponsorName
    websiteForCompany
    verified
    aboutTextFromLinkedIn
    linkedInForCompany
    websiteForCompany
    facebookUrl
    twitterUrl
    instagramUrl
    youtubeUrl
    discordUrl
    contactUrl
    bounceStatus
    createdAt
    slug
    freeUsersCanView
    requiresFurtherExploration
    readyForAlerts
    reverificationOn

    logo {
      id
      thumbnailUrl
    }
    displayIndustry {
      id
      name
    }
    industries {
      nodes {
        id
        name
      }
    }
    sponsorContacts {
      id
      firstName
      lastName
      title
      email
      linkedIn
    }
    generalSponsorContacts {
      id
      email
    }
  }
`)

const sponsorshipsQuery = gql(/* GraphQL */ `
  query SponsorFormSponsorships($slug: String!, $after: String) {
    sponsor(slug: $slug) {
      id
      sponsorships(first: 50, after: $after) {
        ...AdminSponsorshipConnection
        totalCount
      }
    }
  }
`)

export const clearBounceMutation = gql(/* GraphQL */ `
  mutation SponsorFormClearBounce($input: BounceClearCreateInput!) {
    bounceClearCreate(input: $input) {
      bounceClear {
        id
        sponsor {
          id
          bounceStatus
        }
      }
    }
  }
`)

export const validationSchema = Yup.object({
  sponsorName: Yup.string().required("Name is required"),
  websiteForCompany: Yup.string()
    .url("Must be a valid URL")
    .required("Website is required"),
  linkedInForCompany: Yup.string().url("Must be a valid URL").nullable(),
  facebookUrl: Yup.string().url("Must be a valid URL").nullable(),
  twitterUrl: Yup.string().url("Must be a valid URL").nullable(),
  instagramUrl: Yup.string().url("Must be a valid URL").nullable(),
  sponsorContacts: Yup.array().of(
    Yup.object({
      firstName: Yup.string().nullable(),
      lastName: Yup.string().nullable(),
      email: Yup.string().email("Must be a valid email").nullable(),
      title: Yup.string().nullable(),
      linkedIn: Yup.string().url("Must be a valid URL").nullable(),
    })
  ),
  generalSponsorContacts: Yup.array().of(
    Yup.object({
      email: Yup.string().email("Must be a valid email").nullable(),
    })
  ),
})

export const query = gql(/* GraphQL */ `
  query SponsorForm {
    industries(first: 200) {
      nodes {
        id
        name
      }
    }
  }
`)

const deleteMutation = gql(/* GraphQL */ `
  mutation DeleteSponsorMutation($input: SponsorDeleteInput!) {
    sponsorDelete(input: $input) {
      sponsor {
        id
      }
    }
  }
`)

type SponsorContact = NonNullable<
  SponsorFormFieldsFragment["sponsorContacts"]
>[number]

type GeneralSponsorContact = NonNullable<
  SponsorFormFieldsFragment["generalSponsorContacts"]
>[number]

type SponsorContactFields = Omit<SponsorContact, "id"> & {
  id?: string
  _destroy?: boolean
}
type GeneralSponsorContactFields = Omit<GeneralSponsorContact, "id"> & {
  id?: string
  _destroy?: boolean
}
export type SponsorFormSponsor =
  | SponsorFormFieldsFragment
  | (Omit<
      SponsorFormFieldsFragment,
      | "sponsorContacts"
      | "generalSponsorContacts"
      | "id"
      | "bounceStatus"
      | "createdAt"
      | "slug"
    > & {
      sponsorContacts: Array<Partial<SponsorContact>>
      generalSponsorContacts: Array<Partial<GeneralSponsorContact>>
      bounceStatus?: undefined
      id?: undefined
      createdAt?: undefined
    })

export const DeleteSponsorButton = ({
  id,
  onComplete,
}: {
  id: string
  onComplete?: () => void
}) => {
  const [execDelete, deleteMutationResult] = useMutation(deleteMutation, {
    onCompleted: () => {
      toast.success("Sponsor deleted")
      if (onComplete) onComplete()
    },
  })

  return (
    <ConfirmDelete
      onDelete={() => {
        execDelete({ variables: { input: { id } } })
      }}
      message="Are you sure you want to delete this sponsor?"
      result={deleteMutationResult}
    />
  )
}

export const AdminEditSponsorForm = ({
  slug,
  onComplete,
}: {
  slug: string
  onComplete: () => void
}) => {
  const result = useQuery(adminSponsorEditQuery, {
    variables: { slug, bounceReportsAfter: null },
  })

  const [mutate, mutationResult] = useMutation(sponsorEditMutation, {
    onCompleted: () => {
      toast.success(`Sponsor updated`)
      onComplete()
    },
  })

  if (result.error) return <GraphqlError error={result.error} />
  if (result.loading) return <LoadingIndicatorCentered />

  invariant(result.data)

  const sponsor = result.data.sponsor
  const sponsorFragData = getFragmentData(
    sponsorFormFragment,
    result.data.sponsor
  )

  return (
    <SponsorForm
      sponsor={sponsorFragData}
      isLoading={mutationResult.loading}
      errors={mutationResult.error?.graphQLErrors}
      onSubmit={(values) => {
        return mutate({
          variables: {
            input: {
              id: sponsor.id,
              sponsorInput: values,
            },
          },
        })
      }}
    />
  )
}

export const SponsorForm = ({
  sponsor,
  ...props
}: {
  sponsor: SponsorFormSponsor
  onSubmit: (
    values: SponsorInput,
    options?: { preventRedirect?: boolean }
  ) => Promise<unknown>
  isLoading: boolean
  errors?: GraphQLErrors
}) => {
  const result = useQuery(query)
  invariant(sponsor.sponsorContacts)
  invariant(sponsor.generalSponsorContacts)

  const formik = useFormik({
    initialValues: {
      sponsorName: sponsor.sponsorName ?? "",
      websiteForCompany: sponsor.websiteForCompany ?? "",
      linkedInForCompany: sponsor.linkedInForCompany ?? "",
      verified: sponsor.verified,
      logo: sponsor.logo as UploadedImageFragment,
      aboutTextFromLinkedIn: sponsor.aboutTextFromLinkedIn ?? "",
      twitterUrl: sponsor.twitterUrl ?? "",
      facebookUrl: sponsor.facebookUrl ?? "",
      instagramUrl: sponsor.instagramUrl ?? "",
      youtubeUrl: sponsor.youtubeUrl ?? "",
      discordUrl: sponsor.discordUrl ?? "",
      contactUrl: sponsor.contactUrl ?? "",
      displayIndustryId: sponsor.displayIndustry?.id ?? null,
      industryIds: sponsor.industries.nodes.map((i) => i.id) ?? [],
      sponsorContacts: (sponsor.sponsorContacts.map((sc) => {
        let { email, firstName, id, lastName, linkedIn, title } = sc
        return {
          email: email ?? "",
          firstName: firstName ?? "",
          id: id,
          lastName: lastName ?? "",
          linkedIn: linkedIn ?? "",
          title: title ?? "",
        }
      }) ?? []) as Array<SponsorContactFields>,
      generalSponsorContacts: (sponsor.generalSponsorContacts.map((sc) => {
        let { id, email } = sc
        return {
          id: id,
          email: email ?? "",
        }
      }) ?? []) as Array<GeneralSponsorContactFields>,
      freeUsersCanView: sponsor.freeUsersCanView,
      requiresFurtherExploration: sponsor.requiresFurtherExploration,
      readyForAlerts: sponsor.readyForAlerts,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let { logo, sponsorContacts, generalSponsorContacts, ...input } = values
      const sponsorContactsAttributes = sponsorContacts.map((sc) => {
        const fields = [
          "email",
          "firstName",
          "lastName",
          "linkedIn",
          "title",
        ] as const

        const allFieldsBlank = fields.every((field) => {
          return isBlank(sc[field])
        })

        return allFieldsBlank ? { ...sc, _destroy: true } : sc
      })
      const generalSponsorContactsAttributes = generalSponsorContacts.map(
        (sc) => {
          const fields = ["email"] as const

          const allFieldsBlank = fields.every((field) => {
            return isBlank(sc[field])
          })

          return allFieldsBlank ? { ...sc, _destroy: true } : sc
        }
      )

      props.onSubmit({
        ...input,
        logoId: logo ? logo.id : null,
        sponsorContactsAttributes,
        generalSponsorContactsAttributes,
      })
    },
  })

  const reverificationformik = useFormik({
    initialValues: {
      reverificationOn: sponsor.reverificationOn,
    },
    onSubmit: (values) => {
      return props.onSubmit(
        {
          reverificationOn: values.reverificationOn,
        },
        {
          preventRedirect: true,
        }
      )
    },
  })

  useEffect(() => {
    if (props.errors) {
      displayErrors(props.errors, formik.setFieldError)
    }
  }, [formik.setFieldError, props.errors])

  const { upload } = useUploadImage()

  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback(
    async (files: Array<File>) => {
      setIsLoading(true)
      if (!files || files.length !== 1) {
        toast.error("Missing file, please try again")
        setIsLoading(false)
        return
      }

      const image = await upload(files[0])
      formik.setFieldValue("logo", image)

      setIsLoading(false)
    },
    [formik, upload]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: IMAGE_ACCEPT_OPTIONS,
  })

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [bounceClearMutation, bounceClearResult] = useMutation(
    clearBounceMutation,
    {
      refetchQueries: [
        {
          query: adminSponsorEditQuery,
          variables: {
            slug: sponsor.id ? sponsor.slug : undefined,
            bounceReportsAfter: null,
          },
        },
      ],
      onCompleted: () => {
        toast.success("Bounce cleared")

        const returnTo = searchParams.get("returnTo")

        if (!formik.dirty && returnTo) {
          navigate(returnTo)
        }
      },
      onError: (err) => {
        displayErrors(err.graphQLErrors)
      },
    }
  )

  const sponsorshipsResult = useQuery(sponsorshipsQuery, {
    ...(sponsor.id != null && {
      variables: {
        slug: sponsor.slug,
        after: null,
      },
    }),
    skip: sponsor.id == null,
  })

  return (
    <>
      <FormPageBody>
        <FormikProvider value={formik}>
          <Form className="flex flex-col gap-3">
            <div className="grid gap-4 grid-cols-1 lg:grid-cols-form-2">
              <div className="flex-1">
                <FormikField name="sponsorName" label="Name" />
                <FormikField
                  name="websiteForCompany"
                  label="Website URL"
                  type="url"
                />
                <FormikField
                  name="linkedInForCompany"
                  label="LinkedIn URL"
                  type="url"
                />
                <FormikField name="twitterUrl" label="Twitter URL" type="url" />
                <FormikField
                  name="facebookUrl"
                  label="Facebook URL"
                  type="url"
                />
                <FormikField
                  name="instagramUrl"
                  label="Instagram URL"
                  type="url"
                />
                <FormikField name="youtubeUrl" label="YouTube URL" type="url" />
                <FormikField name="discordUrl" label="Discord URL" type="url" />
                <FormikField name="contactUrl" label="Contact URL" type="url" />
                <FormikField
                  name="aboutTextFromLinkedIn"
                  label="LinkedIn About Text"
                  as="textarea"
                />
                <FormikMultiSelectWithPinField
                  name="industryIds"
                  pinName="displayIndustryId"
                  label="Industries"
                  options={
                    result.data?.industries.nodes.map((i) => ({
                      label: i.name,
                      value: i.id,
                    })) ?? []
                  }
                />
                <div className="text-gray-400">
                  Click an industry to pin it. The pinned industry is the
                  display industry.
                </div>

                <div className="flex flex-col gap-2" {...getRootProps()}>
                  <Label>Logo</Label>
                  <div>
                    <Drop
                      variant={isDragActive ? "dragging" : "default"}
                      className="flex flex-col"
                    >
                      {formik.values.logo ? (
                        <div className="flex">
                          <Card className="relative">
                            <img
                              src={formik.values.logo.thumbnailUrl}
                              alt=""
                              width={250}
                              height={250}
                              className="object-cover"
                            />
                            <Button
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                formik.setFieldValue("logo", null)
                              }}
                              aria-label="Delete"
                              className="absolute right-0 top-0 me-2 mt-2"
                              type="button"
                            >
                              <Trash2 />
                            </Button>
                          </Card>
                        </div>
                      ) : null}

                      <input {...getInputProps()} />
                      <DropMessage
                        open={open}
                        isLoading={isLoading}
                        multiple={false}
                      />
                    </Drop>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <Card>
                  <CardContent className="pt-6 flex flex-col gap-3">
                    <FormikField
                      name="verified"
                      label="Verified"
                      type="checkbox"
                    />
                    <FormikField
                      name="freeUsersCanView"
                      label="Free users can view"
                      type="checkbox"
                    />
                    <FormikField
                      name="requiresFurtherExploration"
                      label="Requires further exploration"
                      type="checkbox"
                    />
                    <FormikField
                      name="readyForAlerts"
                      label="Ready for alerts"
                      type="checkbox"
                    />
                  </CardContent>
                </Card>
                {sponsor.id ? (
                  <Card>
                    <CardContent className="pt-6">
                      <div className="flex flex-col gap-4 relative">
                        {reverificationformik.isSubmitting ? (
                          <div className="absolute top-0 right-0 ">
                            <LoadingIndicator size="small" />
                          </div>
                        ) : null}
                        <GraphqlError error={bounceClearResult.error} />

                        <div className="flex flex-col">
                          <Label>Bounce Status</Label>
                          {sponsor.bounceStatus ===
                          SponsorBounceStatus.HasBounces ? (
                            <Button
                              onClick={() => {
                                bounceClearMutation({
                                  variables: {
                                    input: {
                                      bounceClearInput: {
                                        sponsorId: sponsor.id,
                                      },
                                    },
                                  },
                                })
                              }}
                              isLoading={bounceClearResult.loading}
                              disabled={bounceClearResult.loading}
                            >
                              Clear bounce
                            </Button>
                          ) : (
                            <div>
                              <BounceBadge
                                bounceStatus={sponsor.bounceStatus}
                              />
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <FormikProvider value={reverificationformik}>
                            <Form>
                              <FormikAutoSave debounceMs={300} />
                              <FormikField
                                name="reverificationOn"
                                label="Re-verification date"
                                as="date"
                                isDirty={
                                  reverificationformik.values
                                    .reverificationOn != null
                                }
                                onClear={() => {
                                  reverificationformik.setFieldValue(
                                    "reverificationOn",
                                    null
                                  )
                                }}
                              />
                            </Form>
                          </FormikProvider>
                        </div>
                        <div className="flex flex-col">
                          <Label>Created</Label>
                          {formatDate(sponsor.createdAt)}
                        </div>
                        <div className="flex flex-col">
                          <Label>Sponsorship Count</Label>
                          {sponsorshipsResult?.data?.sponsor?.sponsorships
                            ?.totalCount || 0}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ) : null}
              </div>
            </div>

            <Label>Contacts</Label>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
              {formik.values.sponsorContacts.map((contact, index) => {
                if (contact._destroy) return null
                return (
                  <Card key={index}>
                    <CardContent className="pt-4">
                      <div className="flex gap-4 flex-col">
                        <div className="flex flex-col flex-1">
                          <>
                            <FormikField
                              name={`sponsorContacts[${index}].firstName`}
                              label="First Name"
                            />
                            <FormikField
                              name={`sponsorContacts[${index}].lastName`}
                              label="Last Name"
                            />
                            <FormikField
                              name={`sponsorContacts[${index}].title`}
                              label="Title"
                            />
                            <FormikField
                              name={`sponsorContacts[${index}].email`}
                              label="Email"
                              type="email"
                            />
                            <FormikField
                              name={`sponsorContacts[${index}].linkedIn`}
                              label="LinkedIn"
                              type="url"
                            />
                          </>
                        </div>
                      </div>
                    </CardContent>

                    <CardFooter>
                      <Button
                        variant="destructive"
                        type="button"
                        size="icon"
                        className="ml-auto"
                        onClick={() => {
                          formik.setFieldValue(
                            "sponsorContacts",
                            formik.values.sponsorContacts.map((sc, i) => {
                              if (i === index) {
                                return { ...sc, _destroy: true }
                              } else {
                                return sc
                              }
                            })
                          )
                        }}
                      >
                        <Trash />
                      </Button>
                    </CardFooter>
                  </Card>
                )
              })}
            </div>
            <div>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  let newContact: SponsorContactFields = {
                    firstName: "",
                    lastName: "",
                  }

                  formik.setFieldValue("sponsorContacts", [
                    ...formik.values.sponsorContacts,
                    newContact,
                  ])
                }}
              >
                Add contact
              </Button>
            </div>

            <Label>General Contacts</Label>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
              {formik.values.generalSponsorContacts.map((contact, index) => {
                if (contact._destroy) return null
                return (
                  <Card key={index}>
                    <CardContent className="pt-4">
                      <div className="flex gap-4 flex-col">
                        <div className="flex flex-col flex-1">
                          <div className="hidden">
                            <FormikField
                              name={`generalSponsorContacts[${index}].isGeneral`}
                              label="General"
                              type="checkbox"
                              value={true}
                            />
                          </div>
                          <FormikField
                            name={`generalSponsorContacts[${index}].email`}
                            label="Email"
                            type="email"
                          />
                        </div>
                      </div>
                    </CardContent>

                    <CardFooter>
                      <Button
                        variant="destructive"
                        type="button"
                        size="icon"
                        className="ml-auto"
                        onClick={() => {
                          formik.setFieldValue(
                            "generalSponsorContacts",
                            formik.values.generalSponsorContacts.map(
                              (sc, i) => {
                                if (i === index) {
                                  return { ...sc, _destroy: true }
                                } else {
                                  return sc
                                }
                              }
                            )
                          )
                        }}
                      >
                        <Trash />
                      </Button>
                    </CardFooter>
                  </Card>
                )
              })}
            </div>

            <div>
              <Button
                type="button"
                variant="secondary"
                onClick={() => {
                  let newContact: SponsorContactFields = {
                    email: "",
                  }

                  formik.setFieldValue("generalSponsorContacts", [
                    ...formik.values.generalSponsorContacts,
                    newContact,
                  ])
                }}
              >
                Add general contact
              </Button>
            </div>

            <div className="mb-12 flex flex-1 w-full sticky bottom-0 py-2">
              <Button
                type="submit"
                disabled={props.isLoading}
                isLoading={props.isLoading}
                className="flex-1"
                size="lg"
              >
                Save
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </FormPageBody>

      {sponsorshipsResult.data &&
      sponsorshipsResult.data.sponsor.sponsorships ? (
        <>
          <FormPageTitle>Sponsorships</FormPageTitle>
          <AdminSponsorshipTable
            sponsorships={sponsorshipsResult.data.sponsor.sponsorships}
            onFetchMore={(after) => {
              invariant(sponsor.id)
              sponsorshipsResult.fetchMore({
                variables: {
                  slug: sponsor.slug,
                  after,
                },
              })
            }}
            onRefetch={() => sponsorshipsResult.refetch()}
            showEditLink={false}
          />
        </>
      ) : null}
      <GraphqlError error={sponsorshipsResult.error} />
    </>
  )
}

export function blankSponsor(): SponsorFormSponsor {
  return {
    sponsorName: "",
    websiteForCompany: "",
    industries: {
      nodes: [],
    },
    verified: false,
    aboutTextFromLinkedIn: "",
    linkedInForCompany: "",
    logo: null,
    sponsorContacts: [
      {
        firstName: "",
        lastName: "",
      },
    ],
    generalSponsorContacts: [
      {
        email: "",
      },
    ],
    freeUsersCanView: false,
    requiresFurtherExploration: false,
    readyForAlerts: false,
  }
}
