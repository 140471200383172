import { Chrome, PlaneLanding, XOctagon } from "lucide-react"
import { ReactNode, useState } from "react"
import invariant from "tiny-invariant"
import { FragmentType, getFragmentData, gql } from "~/__generated__"
import { formatDate } from "~/common/dates"
import {
  adminNewsletterEditPath,
  adminSponsorEditPath,
  adminSponsorshipEditPath,
} from "~/common/paths"
import { Button } from "~/components/ui/button"
import { FormatDateWithHover } from "~/components/ui/datetime"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "~/components/ui/hover-card"
import { Link, LinkButton } from "~/components/ui/link"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table"
import { Dialog, DialogContent, DialogHeader } from "../ui/dialog"
import {
  AdminEditSponsorship,
  DeleteSponsorshipButton,
} from "./admin-edit-sponsorship"
import { SponsorshipImages } from "./sponsorship-images"

const adminSponsorshipListFragment = gql(/* GraphQL */ `
  fragment AdminSponsorshipConnection on SponsorshipConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        createdAt
        placementDate
        sponsorshipPlacement
        viewInBrowserLink
        sponsorLandingPage

        ...SponsorshipImages

        sponsor {
          id
          sponsorName
          slug
        }
        newsletter {
          id
          name
        }
      }
    }
  }
`)

export const AdminSponsorshipTable = ({
  showEditLink = true,
  ...props
}: {
  sponsorships: FragmentType<typeof adminSponsorshipListFragment>
  onFetchMore: (after: string) => void
  onRefetch: () => void
  showEditLink?: boolean
}) => {
  const data = getFragmentData(adminSponsorshipListFragment, props.sponsorships)

  const sponsorships = data.edges.map((edge) => edge.node)
  const [editingSponsorship, setEditingSponsorship] = useState<string | null>(
    null
  )

  return (
    <div>
      <Dialog
        open={editingSponsorship != null}
        onOpenChange={() => setEditingSponsorship(null)}
      >
        {editingSponsorship && (
          <DialogContent>
            <DialogHeader>
              <div className="flex gap-2">
                <div>Edit sponsorship</div>
                <div className="ms-auto">
                  <DeleteSponsorshipButton
                    sponsorshipId={editingSponsorship}
                    onDeleteComplete={() => {
                      setEditingSponsorship(null)
                      props.onRefetch()
                    }}
                  />
                </div>
              </div>
            </DialogHeader>
            <AdminEditSponsorship
              sponsorshipId={editingSponsorship}
              onUpdateComplete={() => setEditingSponsorship(null)}
            />
          </DialogContent>
        )}
      </Dialog>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Placement date</TableHead>
            <TableHead>Created</TableHead>
            <TableHead>Newsletter</TableHead>
            <TableHead>Sponsor</TableHead>
            <TableHead>Placement</TableHead>
            <TableHead>Links</TableHead>
            <TableHead>Images</TableHead>
            {showEditLink ? <TableHead>Actions</TableHead> : null}
          </TableRow>
        </TableHeader>
        <TableBody>
          {sponsorships.map((sponsorship) => (
            <TableRow key={sponsorship.id}>
              <TableCell>
                <button
                  onClick={() => setEditingSponsorship(sponsorship.id)}
                  type="button"
                >
                  {formatDate(sponsorship.placementDate)}
                </button>
              </TableCell>
              <TableCell>
                <FormatDateWithHover input={sponsorship.createdAt} />
              </TableCell>
              <TableCell>
                <Link
                  to={adminNewsletterEditPath({
                    newsletterId: sponsorship.newsletter.id,
                  })}
                >
                  {sponsorship.newsletter.name}
                </Link>
              </TableCell>
              <TableCell>
                <Link
                  to={adminSponsorEditPath({
                    slug: sponsorship.sponsor.slug,
                  })}
                >
                  {sponsorship.sponsor.sponsorName}
                </Link>
              </TableCell>
              <TableCell className="font-mono">
                {sponsorship.sponsorshipPlacement}
              </TableCell>
              <TableCell className="font-mono flex gap-2">
                <HoverLink url={sponsorship.viewInBrowserLink}>
                  <Chrome />
                </HoverLink>
                <HoverLink url={sponsorship.sponsorLandingPage}>
                  <PlaneLanding />
                </HoverLink>
              </TableCell>
              <TableCell className="whitespace-nowrap overflow-auto">
                <SponsorshipImages
                  sponsorship={sponsorship}
                  containerClassName="flex gap-2 max-w-full overflow-auto"
                  imageSize={50}
                />
              </TableCell>
              {showEditLink ? (
                <TableCell>
                  <LinkButton
                    to={adminSponsorshipEditPath({
                      sponsorshipId: sponsorship.id,
                    })}
                    onClick={() => setEditingSponsorship(sponsorship.id)}
                    size="sm"
                  >
                    Edit
                  </LinkButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.pageInfo.hasNextPage && (
        <div className="p-3">
          <Button
            onClick={() => {
              invariant(data.pageInfo.endCursor)
              props.onFetchMore(data.pageInfo.endCursor)
            }}
          >
            See more
          </Button>
        </div>
      )}
    </div>
  )
}

const HoverLink = ({
  url,
  children,
}: {
  url?: string | null
  children: ReactNode
}) => {
  if (!url) {
    return <XOctagon className="opacity-25" />
  }

  return (
    <HoverCard>
      <HoverCardTrigger>
        <a href={url} target="_blank" rel="noreferrer">
          {children}
        </a>
      </HoverCardTrigger>
      <HoverCardContent className="w-auto">
        <div className="whitespace-pre-wrap text-sm">{url}</div>
      </HoverCardContent>
    </HoverCard>
  )
}
