import { useId } from "@reach/auto-id"
import clsx from "clsx"
import { format } from "date-fns"
import { Field, useField, useFormikContext } from "formik"
import { CalendarIcon, X } from "lucide-react"
import { useMemo } from "react"
import { DateRange } from "react-day-picker"
import { formatDate } from "~/common/dates"
import { cn } from "~/lib/utils"
import { Button } from "../ui/button"
import { Calendar } from "../ui/calendar"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { LabelTooltip } from "../ui/label-tooltip"
import { LabelWithClear } from "../ui/label-with-clear"
import { MultiSelect, MultiSelectProps } from "../ui/multi-select"
import {
  MultiSelectWithPin,
  MultiSelectWithPinProps,
} from "../ui/multi-select-with-pin"
import { OutboundLink } from "../ui/outbound-link"
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover"
import { Select, SelectContent, SelectTrigger, SelectValue } from "../ui/select"
import { Switch } from "../ui/switch"
import { Textarea } from "../ui/textarea"

export type FormikFieldFn = typeof Field & {
  row?: boolean
  containerClassName?: string
  showCheckedLabel?: boolean
  onClear?: () => void
  isDirty?: boolean
  tooltip?: string
}

const FormikInputField: FormikFieldFn = ({ ...props }) => {
  const [field] = useField(props)
  const formik = useFormikContext()

  if (props.as === "textarea") {
    return (
      <Textarea
        {...field}
        {...props}
        className={cn(props.className, "text-foreground")}
        autosize={true}
      />
    )
  }

  if (props.as === "select") {
    return (
      <Select
        {...props}
        onValueChange={(value) => formik.setFieldValue(field.name, value)}
        value={field.value}
        className={cn(props.className, "text-foreground")}
      >
        <SelectTrigger>
          <SelectValue placeholder={props.placeholder} />
        </SelectTrigger>
        <SelectContent>{props.children}</SelectContent>
      </Select>
    )
  }

  if (props.as === "switch") {
    return (
      <Switch
        {...props}
        checked={field.value}
        onCheckedChange={(value) => {
          props.onCheckedChange?.(value)
          formik.setFieldValue(field.name, value)
        }}
      />
    )
  }

  if (props.as === "date") {
    let date = field.value as Date
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? formatDate(date) : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            initialFocus={true}
            selected={date}
            onSelect={(value) => formik.setFieldValue(field.name, value)}
          />
        </PopoverContent>
      </Popover>
    )
  }

  if (props.as === "date-range") {
    let date = field.value as DateRange | undefined
    return (
      <Popover>
        <div className="relative">
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={"outline"}
              className={cn(
                "h-auto flex pr-12 w-full justify-start font-normal",
                !date ? "text-muted-foreground" : "text-foreground",
                props.className
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <div className="flex flex-wrap gap-x-1">
                    <span className="break-keep">
                      {format(date.from, "LLL dd, y")}
                    </span>
                    <> - </>
                    <span className="break-keep">
                      {format(date.to, "LLL dd, y")}
                    </span>
                  </div>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>{props.placeholder || "Pick a date"}</span>
              )}
            </Button>
          </PopoverTrigger>
          {date && (
            <button
              type="button"
              className="absolute z-10 top-0 right-3 opacity-85 h-full flex items-center p-1 -mr-1 hover:opacity-75"
              onClick={() => formik.setFieldValue(field.name, undefined)}
            >
              <X size={16} />
            </button>
          )}
        </div>

        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(value) => formik.setFieldValue(field.name, value)}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    )
  }

  if (props.type === "url") {
    return (
      <div className="flex gap-2 items-center">
        <Input
          {...field}
          {...props}
          className={cn(props.className, "text-foreground")}
        />

        <OutboundLink href={field.value} />
      </div>
    )
  }

  return (
    <Input
      {...field}
      {...props}
      className={cn(props.className, "text-foreground")}
    />
  )
}

export const FormikField: FormikFieldFn = ({
  as,
  label,
  hideErrors,
  hidden,
  containerClassName,
  row = false,
  onClear,
  isDirty,
  tooltip,
  ...props
}) => {
  const [field, meta] = useField(props)
  const autoId = useId()
  const id = props.id || autoId

  const showClearButton = useMemo(() => {
    if (onClear) {
      return isDirty === undefined ? field.value.length > 0 : isDirty
    }
    return false
  }, [field.value, isDirty, onClear])

  return (
    <div
      className={clsx("w-full flex", containerClassName, {
        hidden: hidden,
        "flex-col": !row,
      })}
      data-test={`${props.name}-field`}
    >
      {props.type === "checkbox" ? (
        <div className="flex items-center space-x-2 text-foreground">
          <FormikInputField id={id} {...props} as={as} />

          <label
            htmlFor={id}
            className="ms-2 flex items-center gap-1 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {label}
            {!!tooltip && (
              <div className="z-[100] inline relative -top-[2px]">
                <LabelTooltip text={tooltip} />
              </div>
            )}
          </label>
        </div>
      ) : (
        <>
          <LabelWithClear
            id={id}
            label={label}
            clearable={showClearButton}
            onClear={onClear}
            tooltip={tooltip}
          />
          <FormikInputField id={id} {...props} as={as} />
        </>
      )}

      {!hideErrors && meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
    </div>
  )
}

export const FormikMultiSelectField = ({
  options,
  label,
  hideErrors,
  tooltip,
  ...props
}: {
  name: string
  options: MultiSelectProps["options"]
  id?: string
  label: string
  hideErrors?: boolean
  tooltip?: string
}) => {
  const [field, meta] = useField(props)
  const formik = useFormikContext()
  const autoId = useId()
  const id = props.id || autoId

  return (
    <div>
      <LabelWithClear
        id={id}
        label={label}
        clearable={field.value.length > 0}
        onClear={() => formik.setFieldValue(field.name, [])}
        tooltip={tooltip}
      />
      <div className="text-foreground">
        <MultiSelect
          options={options}
          selected={field.value}
          onValuesChange={(values) => {
            formik.setFieldValue(props.name, values)
          }}
        />
      </div>
      {!hideErrors && meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
    </div>
  )
}

export const FormikMultiSelectWithPinField = ({
  pinName,
  options,
  label,
  hideErrors,
  ...props
}: {
  name: string
  pinName: string
  options: MultiSelectWithPinProps["options"]
  id?: string
  label: string
  hideErrors?: boolean
}) => {
  const [field, meta] = useField(props)
  const [pinField, pinMeta] = useField({ ...props, name: pinName })
  const formik = useFormikContext()
  const autoId = useId()
  const id = props.id || autoId

  return (
    <div>
      <Label htmlFor={id}>{label}</Label>
      <div className="text-foreground">
        <MultiSelectWithPin
          options={options}
          selected={field.value}
          pinValue={pinField.value}
          onValuesChange={(values) => {
            formik.setFieldValue(props.name, values)
          }}
          onSetPinValue={(value) => {
            formik.setFieldValue(pinName, value)
          }}
        />
      </div>
      {!hideErrors && meta.touched && meta.error ? (
        <ValidationError>{meta.error}</ValidationError>
      ) : null}
      {!hideErrors && pinMeta.touched && pinMeta.error ? (
        <ValidationError>{pinMeta.error}</ValidationError>
      ) : null}
    </div>
  )
}

export const ValidationError = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <div className="mt-1 text-sm text-red-700">{children}</div>
}

export const FormikValidationError = ({ name }: { name: string }) => {
  const [_field, meta] = useField(name)
  return meta.touched && meta.error ? (
    <ValidationError>{meta.error}</ValidationError>
  ) : null
}
