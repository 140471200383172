import { useQuery } from "@apollo/client"
import { ChevronDownIcon, ChevronUpIcon, MenuIcon, XIcon } from "lucide-react"
import { useEffect, useMemo, useState } from "react"
import { Link, NavLink, To, useLocation, useNavigate } from "react-router-dom"
import { gql } from "~/__generated__"
import { Role } from "~/__generated__/graphql"
import {
  adminDashboardPath,
  landingPath,
  logInPath,
  myProfilePath,
  newsletterSetsPath,
  newslettersPath,
  registrationPath,
  salesProUrl,
  searchesPath,
} from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu"
import { LinkButton } from "~/components/ui/link"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "~/components/ui/navigation-menu"
import { useSignout } from "~/hooks/auth"
import userProfileIcon from "~/images/user-profile-icon.png"
import logo from "~/images/who-sponsors-stuff-logo.png"
import { cn } from "~/lib/utils"

const query = gql(/* GraphQL */ `
  query Header {
    viewer {
      id
      email
      role
    }
    canShowSponsorSearch {
      value
    }
    canManage {
      value
    }
  }
`)

type SubMenuItem = {
  name: string
  to: To
}

type MenuItem = {
  name: string
  to?: To
  subMenu?: SubMenuItem[]
}

export const Header = () => {
  const result = useQuery(query)
  const signOut = useSignout()
  const navigate = useNavigate()

  const menu = useMemo(
    () => [
      {
        name: "Sponsors",
        to: landingPath({}),
      },
      {
        name: "Newsletters",
        to: newslettersPath({}),
      },
      {
        name: "Email Intelligence",
        subMenu: [
          {
            name: "Articles",
            to: "https://site.whosponsorsstuff.com/email-intelligence",
          },
          {
            name: "Newsletter Ad Calculator",
            to: "https://site.whosponsorsstuff.com/newsletter-ad-calculator",
          },
          {
            name: "Newsletter Subject Line Generator",
            to: "https://site.whosponsorsstuff.com/newsletter-subject-line-generator",
          },
          {
            name: "Newsletter Poll Generator",
            to: "https://site.whosponsorsstuff.com/newsletter-poll-generator",
          },
        ],
      },
      {
        name: "Consulting",
        to: "https://site.whosponsorsstuff.com/consulting",
      },
      ...(!result.data?.viewer
        ? [
            {
              name: "Learn About Sales Pro",
              to: salesProUrl,
            },
          ]
        : []),
      ...(result.data?.viewer?.role === Role.FreeUser
        ? [
            {
              name: "Upgrade to Sales Pro",
              to: salesProUrl,
            },
          ]
        : []),
      ...(result.data?.canManage.value
        ? [{ name: "Admin", to: adminDashboardPath({}) }]
        : []),
    ],
    [result.data?.canManage.value, result.data?.viewer]
  )

  const profileDropdown = useMemo(() => {
    return result.data?.viewer ? (
      <div className="md:mr-10">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <img
              src={userProfileIcon}
              alt="User Menu"
              data-test-id="profile-menu"
              width={40}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={() => {
                navigate(myProfilePath({}))
              }}
            >
              My Profile
            </DropdownMenuItem>
            {result.data?.canShowSponsorSearch?.value && (
              <DropdownMenuItem asChild>
                <Link to={searchesPath({})}>My Saved Searches & Alerts</Link>
              </DropdownMenuItem>
            )}
            {result.data?.canShowSponsorSearch?.value && (
              <DropdownMenuItem asChild>
                <Link to={newsletterSetsPath({})}>My Newsletter Sets</Link>
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={() => {
                signOut.execute()
              }}
            >
              Sign out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    ) : null
  }, [
    result.data?.viewer,
    result.data?.canShowSponsorSearch?.value,
    navigate,
    signOut,
  ])

  const loggedOutActions = useMemo(() => {
    return result.data?.viewer ? null : (
      <>
        <LinkButton variant="secondary" to={logInPath({})}>
          Log in
        </LinkButton>
        <LinkButton variant="green" to={registrationPath({})}>
          Sign up
        </LinkButton>
      </>
    )
  }, [result.data?.viewer])

  return (
    <>
      <div className="hidden md:block">
        <DesktopHeader
          loading={result.loading && result.data == null}
          menu={menu}
          profileDropdown={profileDropdown}
          loggedOutActions={loggedOutActions}
        />
      </div>
      <div className="md:hidden">
        <MobileHeader
          menu={menu}
          profileDropdown={profileDropdown}
          loggedOutActions={loggedOutActions}
        />
      </div>
    </>
  )
}

const DesktopHeader = ({
  loading,
  menu,
  profileDropdown,
  loggedOutActions,
}: {
  loading: boolean
  menu: MenuItem[]
  profileDropdown: React.ReactNode
  loggedOutActions: React.ReactNode
}) => {
  return (
    <div className="bg-primary text-primary-foreground">
      <div className="max-w-page mx-auto w-full">
        <div className="flex justify-between items-center gap-2">
          <NavLink to={landingPath({})}>
            <img src={logo} alt="Who Sponsors Stuff" width={250} height={250} />
          </NavLink>
          <NavigationMenu>
            <NavigationMenuList>
              {menu.map((item) => (
                <DesktopMenuItem key={item.name} item={item} />
              ))}
            </NavigationMenuList>
          </NavigationMenu>

          <div className="ms-auto flex gap-2">
            {loading ? null : (
              <>
                {profileDropdown}
                {loggedOutActions}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const MobileHeader = ({
  menu,
  profileDropdown,
  loggedOutActions,
}: {
  menu: MenuItem[]
  profileDropdown: React.ReactNode
  loggedOutActions: React.ReactNode
}) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(false)
  }, [location.pathname])

  return (
    <div className="bg-primary text-primary-foreground">
      <div className="flex justify-between items-center gap-4 pe-4">
        <NavLink to={landingPath({})} className="flex-grow">
          <img src={logo} alt="Who Sponsors Stuff" width={100} height={100} />
        </NavLink>
        <div className="flex gap-2">
          {profileDropdown}
          {loggedOutActions}
        </div>
        <button onClick={() => setExpanded(!expanded)}>
          {expanded ? <XIcon /> : <MenuIcon />}
        </button>
      </div>
      {expanded && (
        <div className="flex flex-col p-2">
          {menu.map((item) => (
            <MobileMenuItem key={item.name} item={item} />
          ))}
        </div>
      )}
    </div>
  )
}

const DesktopMenuItem = ({ item }: { item: MenuItem }) => {
  if (item.subMenu) {
    return (
      <NavigationMenu>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="bg-primary">
            {item.name}
          </NavigationMenuTrigger>
          <NavigationMenuContent className="bg-primary text-primary-foreground border-none border-transparent">
            <div className="flex flex-col w-[400px]">
              {item.subMenu.map((subItem) => (
                <DesktopNestedHeaderLink key={subItem.name} to={subItem.to}>
                  {subItem.name}
                </DesktopNestedHeaderLink>
              ))}
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenu>
    )
  } else if (item.to) {
    return <DesktopHeaderLink to={item.to}>{item.name}</DesktopHeaderLink>
  }
}

const MobileMenuItem = ({ item }: { item: MenuItem }) => {
  const location = useLocation()
  if (item.subMenu) {
    return <MobileSubmenu name={item.name} subMenu={item.subMenu} />
  } else if (item.to) {
    return (
      <NavLink
        className={cn(
          "p-3",
          location.pathname === item.to
            ? "bg-secondary text-secondary-foreground"
            : ""
        )}
        to={item.to}
      >
        {item.name}
      </NavLink>
    )
  }
}

const MobileSubmenu = ({
  name,
  subMenu,
}: {
  name: string
  subMenu: SubMenuItem[]
}) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(false)
  }, [location.pathname])

  return (
    <>
      <button
        onClick={() => setExpanded(!expanded)}
        className="flex justify-between p-3"
      >
        <div>{name}</div>
        {expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
      </button>
      {expanded &&
        subMenu.map((subItem) => (
          <NavLink className="p-3 pl-5 ml-3 border-l-4" to={subItem.to}>
            {subItem.name}
          </NavLink>
        ))}
    </>
  )
}

const DesktopHeaderLink = ({
  to,
  children,
}: {
  to: To
  children: React.ReactNode | string
}) => {
  const location = useLocation()

  return (
    <NavigationMenuItem>
      <NavLink
        to={to}
        className={cn(
          navigationMenuTriggerStyle(),
          location.pathname === to
            ? "bg-secondary text-secondary-foreground"
            : "bg-primary text-primary-foreground"
        )}
      >
        {children}
      </NavLink>
    </NavigationMenuItem>
  )
}

const DesktopNestedHeaderLink = ({
  to,
  children,
}: {
  to: To
  children: React.ReactNode | string
}) => {
  return (
    <NavLink
      to={to}
      className={cn(
        navigationMenuTriggerStyle(),
        "bg-primary text-primary-foreground w-[100%] justify-start"
      )}
    >
      {children}
    </NavLink>
  )
}
