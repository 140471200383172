import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "~/lib/utils"
import { Button } from "./button"

const dropVariants = cva("border-dashed p-3 rounded border-2", {
  variants: {
    variant: {
      default: "bg-gray-100 border-gray-400",
      dragging: "bg-yellow-100 border-yellow-800",
    },
  },
  defaultVariants: {
    variant: "default",
  },
})

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof dropVariants> {}

function Drop({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(dropVariants({ variant }), className)} {...props} />
}

function DropMessage({
  open,
  multiple = true,
  isLoading,
}: {
  open: () => void
  isLoading: boolean
  multiple?: boolean
}) {
  return (
    <>
      <p className="mb-2">
        {multiple ? "Drag and drop images here" : "Drag and drop an image here"}
      </p>
      <Button type="button" onClick={open} isLoading={isLoading}>
        Choose {multiple ? "files" : "file"}
      </Button>
    </>
  )
}

const IMAGE_ACCEPT_OPTIONS = {
  "image/*": [],
}

export { Drop, dropVariants, DropMessage, IMAGE_ACCEPT_OPTIONS }
