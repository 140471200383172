import * as React from "react"
import { Toaster } from "react-hot-toast"
import { RouterProvider } from "react-router-dom"

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev"
import { relayStylePagination } from "@apollo/client/utilities"
import { createConsumer } from "@rails/actioncable"
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink"
import { csrfToken } from "./common/csrf"
import { router } from "./router"

// @ts-expect-error this is a vite-only feature
if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const cable = createConsumer()

const hasSubscriptionOperation = ({ query: { definitions } }: any) => {
  return definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription"
  )
}

let token = csrfToken()

const httpLink = new HttpLink({
  credentials: "same-origin",
  headers: {
    ...(token && { "X-CSRF-Token": token }),
  },
})

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({ cable }),
  httpLink
)

const apolloClient = new ApolloClient({
  uri: "/graphql",
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          sponsors: relayStylePagination(["filters", "first"]),
          users: relayStylePagination(["query", "organizationId", "first"]),
          sponsorships: relayStylePagination(["filters", "first"]),
          topics: relayStylePagination(),
          newsletters: relayStylePagination(["filters", "first"]),
          bounceReports: relayStylePagination(["status"]),
          organizations: relayStylePagination([]),
          sponsorSearches: relayStylePagination(),
        },
      },
      Topic: {
        fields: {
          newsletters: relayStylePagination([]),
        },
      },
      Industry: {
        fields: {
          sponsors: relayStylePagination([]),
        },
      },
      Sponsor: {
        fields: {
          sponsorships: relayStylePagination([
            "newsletterIds",
            "first",
            "last",
          ]),
          relatedSponsors: relayStylePagination(),
          bounceReports: relayStylePagination(),
        },
      },
      Newsletter: {
        fields: {
          sponsorships: relayStylePagination(["first", "last"]),
        },
      },
    },
  }),
})

export const App = () => {
  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <Toaster />
        <RouterProvider router={router} />
      </ApolloProvider>
    </React.StrictMode>
  )
}
