import { parseISO } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { formatDate, formatDateTime } from "~/common/dates"
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover-card"

export const DateTime: React.FC<{ input: string }> = ({ input }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>{formatDateTime(input)}</HoverCardTrigger>
      <HoverCardContent>
        <div className="flex flex-col">
          <div>
            {formatInTimeZone(
              parseISO(input),
              "America/New_York",
              "M/d/yyyy h:mm a zzz"
            )}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}

export const FormatDateWithHover: React.FC<{ input: string }> = ({ input }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>{formatDate(input)}</HoverCardTrigger>
      <HoverCardContent>
        <div className="flex flex-col">
          <div>
            {formatInTimeZone(
              parseISO(input),
              "America/New_York",
              "M/d/yyyy h:mm a zzz"
            )}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
