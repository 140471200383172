import { useQuery } from "@apollo/client"
import { useId } from "@reach/auto-id"
import { useField, useFormikContext } from "formik"
import { gql } from "~/__generated__"
import { LabelWithClear } from "../ui/label-with-clear"
import { MultiSelect } from "../ui/multi-select"

const industryFieldQuery = gql(/* GraphQL */ `
  query IndustryField {
    industries(first: 200) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export const IndustryField = ({
  label,
  disabled,
  ...props
}: {
  name: string
  label?: string
  disabled?: boolean
  id?: string
}) => {
  const [field] = useField(props)
  const formik = useFormikContext()

  const result = useQuery(industryFieldQuery, {})

  const industries =
    result.data?.industries.edges.map((edge) => edge.node) ?? []

  const industryOptions =
    industries.map((industry) => ({
      label: industry.name,
      value: industry.id,
    })) ?? []

  const autoId = useId()
  const id = props.id ?? autoId

  return (
    <div>
      <LabelWithClear
        id={id}
        label={label}
        clearable={field.value.length > 0}
        onClear={() => formik.setFieldValue(field.name, [])}
        tooltip="Filter sponsors by industry"
      />
      <div>
        <MultiSelect
          id={id}
          name={field.name}
          options={industryOptions}
          selected={field.value}
          onValuesChange={(ids) => {
            formik.setFieldValue(field.name, ids)
          }}
          shouldFilter={true}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
