import { useMutation, useQuery } from "@apollo/client"
import toast from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import { adminNewsletterIndexPath, newsletterDetailPath } from "~/common/paths"
import { GraphqlError } from "~/components/errors/graph-error"
import {
  DeleteNewsletterButton,
  NewsletterForm,
  newsletterFormFragment,
} from "~/components/forms/newsletter-form"
import {
  FormPage,
  FormPageBody,
  FormPageTitle,
} from "~/components/ui/form-page"
import { LinkButton } from "~/components/ui/link"
import { LoadingIndicatorCentered } from "~/components/ui/loading-indicator"

export const adminNewsletterEditQuery = gql(/* GraphQL */ `
  query AdminNewsletterEditScreenQuery($id: ID!) {
    newsletter: node(id: $id) {
      ... on Newsletter {
        id
        ...NewsletterFormFields
      }
    }
  }
`)

export const adminNewsletterEditMutation = gql(/* GraphQL */ `
  mutation UpdateNewsletterMutation($input: NewsletterUpdateInput!) {
    newsletterUpdate(input: $input) {
      newsletter {
        id
        ...NewsletterFormFields
      }
    }
  }
`)

export const AdminNewsletterEditScreen = () => {
  const params = useParams()
  invariant(params.newsletterId)

  const result = useQuery(adminNewsletterEditQuery, {
    variables: { id: params.newsletterId },
  })

  const navigate = useNavigate()

  const [mutate, mutationResult] = useMutation(adminNewsletterEditMutation, {
    onCompleted: (data) => {
      const newsletter = getFragmentData(
        newsletterFormFragment,
        data.newsletterUpdate.newsletter
      )
      toast.success(`${newsletter.name} updated`)
      navigate(adminNewsletterIndexPath({}))
    },
  })

  if (result.error) return <GraphqlError error={result.error} />
  if (result.loading) return <LoadingIndicatorCentered />

  invariant(result.data)
  invariant(result.data.newsletter)
  invariant(
    result.data.newsletter.__typename === "Newsletter",
    "expected a newsletter"
  )

  const id = result.data.newsletter.id
  const newsletter = getFragmentData(
    newsletterFormFragment,
    result.data.newsletter
  )

  return (
    <FormPage>
      <FormPageTitle>
        <div className="font-bold">Edit Newsletter</div>
        <div className="ms-auto flex gap-2">
          <LinkButton
            size="sm"
            to={newsletterDetailPath({ newsletterId: id })}
            target="_blank"
          >
            View
          </LinkButton>
          <DeleteNewsletterButton
            id={id}
            onDeleteComplete={() => navigate(adminNewsletterIndexPath({}))}
          />
        </div>
      </FormPageTitle>
      <FormPageBody>
        <NewsletterForm
          errors={mutationResult.error?.graphQLErrors}
          isLoading={mutationResult.loading}
          newsletter={newsletter}
          onSubmit={(newsletterInput) => {
            invariant(result.data)
            mutate({
              variables: {
                input: {
                  id,
                  newsletterInput: newsletterInput,
                },
              },
            })
          }}
        />
      </FormPageBody>
    </FormPage>
  )
}
