import { useEffect } from "react"

export const useDocumentTitle = (
  title?: string | null,
  suffix: string = " · Who Sponsors Stuff"
) => {
  useEffect(() => {
    if (title != null) {
      let originalTitle = document.title
      document.title = `${title}${suffix}`
      return () => {
        document.title = originalTitle
      }
    }
  }, [suffix, title])
}

export const useAdminDocumentTitle = (title?: string) => {
  useDocumentTitle(title, " · Admin · Who Sponsors Stuff")
}
