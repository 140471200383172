import { ExternalLinkIcon } from "lucide-react"
import { Anchor } from "./link"

export const OutboundLink = ({ href }: { href: string }) => {
  const isValid = isValidUrl(href)

  if (isValid) {
    return (
      <Anchor href={href} target="_blank" rel="noopener noreferrer">
        <ExternalLinkIcon />
      </Anchor>
    )
  } else {
    return (
      <span className="opacity-60">
        <ExternalLinkIcon />
      </span>
    )
  }
}

const isValidUrl = (input: string) => {
  try {
    let url = new URL(input)
    let isValidProtocol = url.protocol === "http:" || url.protocol === "https:"
    let isValidHostname =
      url.hostname.split(".").filter((x) => x.length > 0).length > 1
    return isValidProtocol && isValidHostname
  } catch (e) {
    return false
  }
}
