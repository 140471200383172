import { Link } from "react-router-dom"
import { salesProUrl } from "~/common/paths"
import { Card, CardContent } from "~/components/ui/card"
import userBoyeFajinmi from "~/images/user-boye-fajinmi.png"
import userDanOshinsky from "~/images/user-dan-oshinsky.png"
import userKatyHuff from "~/images/user-katy-huff.png"

export const TestimonialsSection = () => {
  return (
    <>
      <h2 className="text-2xl font-bold">What Sales Pro users are saying...</h2>
      <div className="flex flex-col md:flex-row gap-6">
        <Testimonial
          name="Dan Oshinsky"
          quote={`"Several of my clients are using Sales Pro and love it... Sell a single sponsorship thanks to Sales Pro, and it pays for itself. Sell more than that and it's a steal."`}
          company="Inbox Collective / Not a Newsletter"
          image={userDanOshinsky}
        />
        <Testimonial
          name="Katy Huff"
          company="Co-Founder, MadRev"
          quote={`"If you run a newsletter or sell ads for a newsletter and ever wonder 'how can I find new brands to pitch?' this is the answer. Sales Pro is super sick and a massive time saver for seeing who’s got fat pockets. This is not an ad, just an ode to an amazing product that I'm thankful someone built."`}
          image={userKatyHuff}
        />
        <Testimonial
          name="Boye Fajinmi"
          company="Co-Founder & President, The Future Party"
          quote={`"We love Who Sponsors Stuff. We’ve had a great and seamless experience, and the leads we’re getting from this stellar product more than pays for itself!"`}
          image={userBoyeFajinmi}
        />
      </div>
      <Link
        to={salesProUrl}
        className="underline font-bold mx-auto"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More about Sales Pro
      </Link>
    </>
  )
}

const Testimonial = ({
  name,
  company,
  quote,
  quote2,
  image,
}: {
  name: string
  company: string
  quote: string
  quote2?: string
  image: string
}) => {
  return (
    <Card className="flex flex-1 border-green shadow-md">
      <CardContent className="flex flex-col gap-4 pt-6 flex-grow text-center">
        <img
          src={image}
          alt={name}
          className="aspect-square h-[100px] w-[100px] mx-auto"
        />
        <div className="flex flex-col gap-2">
          <p>{quote}</p>
          {quote2 && <p>{quote2}</p>}
        </div>
        <div>
          <div>{name}</div>
          <div className="font-semibold">{company}</div>
        </div>
      </CardContent>
    </Card>
  )
}
