import { Link } from "react-router-dom"
import { Card, CardContent, CardTitle } from "~/components/ui/card"
import articleHowToColdEmailASponsor from "~/images/article-how-to-cold-email-a-sponsor.jpeg"
import articleHowToProspoect from "~/images/article-how-to-prospect.jpeg"
import articleWhatShouldYouBeCharging from "~/images/article-what-should-you-be-charging.jpeg"

export const ArticlesSection = () => {
  return (
    <>
      <h2 className="text-2xl font-bold">Articles</h2>
      <div className="flex flex-col md:flex-row gap-6">
        <Article
          image={articleHowToProspoect}
          title="How To Prospect for Newsletter Sponsors"
          description="When you’re selling newsletter ads, prospecting is a way of life. From morning until night, you need to have your antennae up:"
          description2="Who might want to sponsor my newsletter? Some ideas may be obvious. Some will be less so.
          But to fill your inventory, you will need a process to find new prospective buyers for your ad space."
          href="https://site.whosponsorsstuff.com/how-to-prospect-for-newsletter-sponsors"
        />
        <Article
          image={articleHowToColdEmailASponsor}
          title="How To Cold Email a Potential Sponsor"
          description="Selling advertising on your email newsletter means a lot of cold emailing to a lot of potential sponsors. Whether your newsletter is large or small, niche or general interest, new or long-established, you need to grab their interest quickly and set yourself up for a successful conversation."
          href="https://site.whosponsorsstuff.com/how-to-cold-email-a-newsletter-sponsor"
        />
        <Article
          image={articleWhatShouldYouBeCharging}
          title="Newsletter Calculator: What should you be charging?"
          description="Try our newsletter ad calculator to see what you should be charging for your sponsorships."
          href="https://site.whosponsorsstuff.com/newsletter-ad-calculator"
        />
      </div>
      <Link
        to="https://site.whosponsorsstuff.com/email-intelligence"
        className="underline font-bold mx-auto text-secondary"
        target="_blank"
        rel="noopener noreferrer"
      >
        SEE ALL ARTICLES
      </Link>
    </>
  )
}

const Article = ({
  image,
  title,
  description,
  description2,
  href,
}: {
  image: string
  title: string
  description: string
  description2?: string
  href: string
}) => {
  return (
    <Link to={href} className="flex flex-1 w-full" title={title}>
      <Card className="flex border-secondary shadow-md">
        <CardContent className="flex flex-col gap-4 pt-6 flex-grow">
          <img src={image} alt={title} className="h-[180px] object-cover" />
          <div className="flex flex-col flex-grow gap-4">
            <CardTitle className="text-center text-xl font-bold md:min-h-[56px]">
              {title}
            </CardTitle>
            <p className="text-center">{description}</p>
            {description2 && <p className="text-center">{description2}</p>}
          </div>
          <div className="text-secondary hover:text-secondary/80">
            READ MORE
          </div>
        </CardContent>
      </Card>
    </Link>
  )
}
